import React, { useEffect, useState } from 'react';

import PairQuizScreenContent from './PairQuizScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const PairQuizScreen = ({ active, index, data }) => {
    const { pairQuizScreen } = data;
    const { pairQuizAnswers: answers } = pairQuizScreen;

    const [leftSide, setLeftSide] = useState([]);
    const [rightSide, setRightSide] = useState([]);

    useEffect(() => {
        const leftAnswers = answers.map(el => ({
            id: el.id,
            text: el.left_text,
            src: el.leftGraphicPath,
            isPairable: el.points !== 0,
            points: el.points
        })).filter(obj => obj.src || obj.text);

        const rightAnswers = answers.map(el => ({
            id: el.id,
            text: el.right_text,
            src: el.rightGraphicPath,
            isPairable: el.points !== 0
        })).filter(obj => obj.src || obj.text);

        const shuffledLeftSide = shuffleArray(leftAnswers);
        const shuffledRightSide = shuffleArray(rightAnswers);

        setLeftSide(shuffledLeftSide);
        setRightSide(shuffledRightSide);

    }, [answers])
    return (
        <WithTimerScreen
            active={active}
            data={data}
            index={index}
            gameContent={endGame => (
                <PairQuizScreenContent
                    index={index}
                    data={data}
                    endGame={endGame}
                    leftAnswers={leftSide}
                    rightAnswers={rightSide}
                />
            )
            }
            timeOutContent={<PairQuizScreenContent
                index={index}
                data={data}
                isEnd={true}
                leftAnswers={leftSide}
                rightAnswers={rightSide}
            />
            }
            winContent={<PairQuizScreenContent
                index={index}
                data={data}
                isEnd={true}
                leftAnswers={leftSide}
                rightAnswers={rightSide}
            />
            }
        />
    );
};

export default PairQuizScreen;
