import React from 'react';

import './winLoseScreen.scss';
import { commonImages } from '../../config/images';
import { useSelector } from 'react-redux';
import { getLosePopupData } from '../../store/reducers/mainReducer';
import Text from '../TextContainer/Text';

const LoseScreen = () => {
    const losePopup = useSelector(getLosePopupData) || {};
    const { text_1: chatText, text_2: infoText } = losePopup;

    return <div className="winLoseScreen">
        <div className="seahorseContainer">
            <img
                src={commonImages.elementHorseLoser}
                alt=""
                className="seahorseImage seahorseImage--lose"
            />
            <img
                src={commonImages.elementChat}
                alt=""
                className="chatImage chatImage--lose"
            />
            <h2 className="chatText chatText--lose"><Text>{chatText}</Text></h2>
        </div>
        <div className="infoTextContainer">
            <p className="colorDark points"><Text>0</Text></p>
            <h3 className="colorDark infoText infoText--lose"><Text>{infoText}</Text></h3>
        </div>
    </div>
};

export default LoseScreen;
