export class StaticObject {
  constructor({
    image,
    position,
    size,
    touchSize,
    isVisible,
    onTouch,
    onTouchMove,
    onTouchEnd,
    collisionType,
  }) {
    this.image = image || null;
    this.position = position || { x: 0, y: 0 };
    this.size = size || { width: 0, height: 0 };
    this.touchSize = touchSize || size;
    this.midpoint = { x: 0, y: 0 };
    this.touchMidpoint = { x: 0, y: 0 };
    this.isVisible = isVisible;
    this.isClickable = onTouch || onTouchMove || onTouchEnd ? true : false;
    this.touchPoint = { x: 0, y: 0 };
    this.onTouch = onTouch;
    this.onTouchMove = onTouchMove;
    this.onTouchEnd = onTouchEnd;
    this.collisionType = collisionType || 'BLOCK';
    this.setMidpoint(position);
    this.setTouchMidpoint(position);
  }
  setMidpoint = position => {
    this.midpoint = {
      x: position.x + this.size.width / 2,
      y: position.y + this.size.height / 2,
    };
  };
  setTouchMidpoint = position => {
    this.touchMidpoint = {
      x: position.x + this.touchSize.width / 2,
      y: position.y + this.touchSize.height / 2,
    };
  };
  setPositionUsingCoordinates = newPosition => {
    this.position = newPosition;
    this.midpoint = this.setMidpoint(newPosition);
  };
  setPositionUsingMidpoint = newMidpoint => {
    this.position.x = newMidpoint.x - this.size.width / 2;
    this.position.y = newMidpoint.y - this.size.height / 2;
    this.midpoint = newMidpoint;
    this.setTouchMidpoint({ x: this.position.x, y: this.position.y });
  };
  isPointInsideObjectFrame = point => {
    return point.x >= this.position.x &&
      point.x <= this.position.x + this.touchSize.width &&
      point.y >= this.position.y &&
      point.y <= this.position.y + this.touchSize.height
      ? true
      : false;
  };
  getTouchDistanceFromMidpoint = () => {
    return {
      x: this.touchPoint.x - this.midpoint.x,
      y: this.touchPoint.y - this.midpoint.y,
    };
  };
  getTouchDistanceFromTouchMidpoint = () => {
    return {
      x: this.touchPoint.x - this.touchMidpoint.x,
      y: this.touchPoint.y - this.touchMidpoint.y,
    };
  };
  handleTouch = point => {
    if (
      this.isClickable &&
      this.onTouch &&
      this.isPointInsideObjectFrame(point)
    ) {
      this.touchPoint = point;
      this.onTouch();
    } else {
      return null;
    }
  };
  handleTouchMove = point => {
    if (
      this.isClickable &&
      this.onTouchMove &&
      this.isPointInsideObjectFrame(point)
    ) {
      this.touchPoint = point;
      this.onTouchMove();
    } else {
      return null;
    }
  };
  handleTouchEnd = point => {
    if (
      this.isClickable &&
      this.onTouchEnd //&&
      //this.isPointInsideObjectFrame(point)
    ) {
      this.touchPoint = point;
      this.onTouchEnd();
    } else {
      return null;
    }
  };
}

export class MovingObject extends StaticObject {
  constructor({
    image,
    position,
    size,
    touchSize,
    isVisible,
    onTouch,
    onTouchMove,
    onTouchEnd,
    velocity,
    acceleration,
    collisionType,
    blockingObstacle,
  }) {
    super({
      image,
      position,
      size,
      touchSize,
      isVisible,
      onTouch,
      onTouchMove,
      onTouchEnd,
      collisionType,
    });
    this.velocity = velocity || { x: 0, y: 0 };
    this.acceleration = acceleration || { x: 0, y: 0 };
    this.blockingObstacle = blockingObstacle || {
      top: null,
      bottom: null,
      left: null,
      right: null,
    };
    this.direction = 0;
    this.setDirectionUsingVelocity();
  }
  setDirectionUsingVelocity = () => {
    let quarter =
      // This has to work differently than real trigonometric functions, because for canvas positive y means going "down"
      this.velocity.x > 0 && this.velocity.y <= 0
        ? 1
        : this.velocity.x > 0 && this.velocity.y > 0
        ? 2
        : this.velocity.x <= 0 && this.velocity.y > 0
        ? 3
        : 4;
    switch (quarter) {
      case 1:
        return Math.abs(
          Math.atan(this.velocity.x / this.velocity.y) * (180 / Math.PI),
        );
      case 2:
        return (
          Math.abs(
            Math.atan(this.velocity.y / this.velocity.x) * (180 / Math.PI),
          ) + 90
        );
      case 3:
        return (
          Math.abs(
            Math.atan(this.velocity.x / this.velocity.y) * (180 / Math.PI),
          ) + 180
        );
      case 4:
        return (
          Math.abs(
            Math.atan(this.velocity.y / this.velocity.x) * (180 / Math.PI),
          ) + 270
        );
      default:
        return null;
    }
  };
  resetBlockingObstacle = () => {
    this.blockingObstacle = {
      top: null,
      bottom: null,
      left: null,
      right: null,
    };
  };
  setVelocityDirectly = newVelocity => {
    if (this.blockingObstacle) {
      newVelocity.x =
        this.blockingObstacle.right && newVelocity.x > 0
          ? (newVelocity.x = 0)
          : newVelocity.x;
      newVelocity.x =
        this.blockingObstacle.left && newVelocity.x < 0
          ? (newVelocity.x = 0)
          : newVelocity.x;
      newVelocity.y =
        this.blockingObstacle.top && newVelocity.y > 0
          ? (newVelocity.y = 0)
          : newVelocity.y;
      newVelocity.y =
        this.blockingObstacle.bottom && newVelocity.y < 0
          ? (newVelocity.y = 0)
          : newVelocity.y;
    }
    this.velocity = newVelocity;
    this.direction = this.setDirectionUsingVelocity();
  };
  calculateNewPosition = () => {
    this.position.x += this.velocity.x;
    this.position.y += this.velocity.y;
    this.setMidpoint({ x: this.position.x, y: this.position.y });
  };
  moveWithTouch = (lockX = false, lockY = false) => {
    let moveDirection = this.getTouchDistanceFromMidpoint();
    this.setVelocityDirectly({
      x: lockX ? 0 : moveDirection.x,
      y: lockY ? 0 : moveDirection.y,
    });
    this.calculateNewPosition();
  };
}
