import React from 'react';
import { useSelector } from "react-redux";

import '../main.scss';
import { getGlobalConfig, getNumberOfScreens, getCurrentScreenIndex } from '../../../store/reducers/mainReducer';
import Text from '../../TextContainer/Text';

const ProgressBar = ({ data }) => {
    const currentScreenIndex = useSelector(getCurrentScreenIndex);
    const numberOfScreens = useSelector(getNumberOfScreens);
    const globalConfig = useSelector(getGlobalConfig);

    const { progress_bar_text: text } = globalConfig || {};
    const { screenCategory = {} } = data;
    const { title = "" } = screenCategory;
    const progress = currentScreenIndex / numberOfScreens;

    return (
        <div className="sectionElement sectionElement--center">
            <p className="label sectionElement__text">
                <Text>{text}</Text>
            </p>
            <div className="progressBar">
                <div
                    className="progressBar__bar"
                    style={{ width: `${progress * 100}%` }} />
            </div>
            <p className="label sectionElement__text">
                <Text>{title}</Text>
            </p>
        </div>
    );
};

export default ProgressBar;
