import React, { useEffect, useRef } from 'react'
import "./MapPopup.scss";

import { useSelector } from 'react-redux';
import { getGlobalConfig } from '../../../store/reducers/mainReducer';
import { commonImages } from "../../../config/images";

import Text from "../../TextContainer/Text.js";

function OfflineOverlay({ active }) {
    const ref = useRef(null);
    const globalConfig = useSelector(getGlobalConfig) || {};

    const { offline_warning_text } = globalConfig;

    useEffect(() => {
        const div = ref.current;
        if (div) {
            if (div.classList.length === 0) {
                div.classList.add("current-screen-exit-done")
            }
        }
        return () => {
            if (div) {
                div.classList.remove("current-screen-exit-done")
            }
        };
    });

    return (
        <div ref={ref}>
            <div className="offlineOverlay">
                <img className="offlineImage" src={commonImages.elementOffline} alt="offline" />
                <h2 className="offlineText colorDark"><Text>{offline_warning_text}</Text></h2>
                <div />
            </div>
        </div>
    )
}

export default OfflineOverlay
