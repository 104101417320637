import React, { useState } from 'react';
import '../../config/globalStyles.scss';
import './withInstitutionsListScreen.scss';
import Text from '../TextContainer/Text';
import ExitFullscreenPopup from './ExitFullscreenPopup';

const WithInstitutionsListScreen = ({ data }) => {
    const { withInstitutionsListScreen } = data;
    const { text_before_list, text_1, link = "www.balticmuseums.info", link_text } = withInstitutionsListScreen;

    const [exitFullscreenPopupVisible, setExitFullscreenPopupVisible] = useState(true);

    return (
            <div className="screenContentContainer withInstitutionsListScreen">

                <div className="withInstitutionsTop">
                    <h2 className="colorDark"><Text>{text_before_list}</Text></h2>
                </div>
                <div className="withInstitutionsContent">
                    <p className="colorDark withInstitutionsText"><Text>{text_1}</Text></p>
                </div>
                <div className="withInstitutionsBottom">
                    <a href={"http://" + link} target="_blank" rel="noopener noreferrer" className="colorDark withInstitutionsLink"><Text>{link_text}</Text></a>
                </div>
            {exitFullscreenPopupVisible && <ExitFullscreenPopup setVisible={setExitFullscreenPopupVisible} />}
            </div>
    );
};

export default WithInstitutionsListScreen;
