import React from 'react'
import { getImagePath } from "../../config/helpers"
import audio from '../../config/audio';

function GraphicAnswer({ answer, index, finished, handleTouch, selectedAnswers }) {
    let tileStyle = "quiz__answers__answer"

    const { points, name, graphicPath } = answer;

    if (finished && points) {
        tileStyle += " quiz__answers__answer--correct";
    }
    else if (!finished && selectedAnswers.includes(index)) {
        tileStyle += " quiz__answers__answer--selected";
    }
    else if (finished && selectedAnswers.includes(index)) {
        tileStyle += " quiz__answers__answer--wrong"
    }

    return (
        <div
            onClick={() => {
                if (!finished) {
                    handleTouch(index, points);
                    audio.play("click");
                }
            }}
            className={tileStyle}>
            <img
                key={name}
                className="quiz__answers__answer--image"
                src={getImagePath(graphicPath)}
                alt={name} />
        </div>
    )
}

export default GraphicAnswer
