import React from 'react';
import { useDispatch } from "react-redux";

import '../main.scss';
import { commonImages, exampleImages } from '../../../config/images';
import audio from '../../../config/audio';
import { setChangeLanguagePopup } from '../../../store/actions/mainActions';

const LanguageButton = () => {
    const dispatch = useDispatch();

    const openPopup = () => {
        dispatch(setChangeLanguagePopup(true));
    };

    return (
        <div
            className="sectionElement sectionElement--left-bottom"
            style={{
                backgroundImage: `url(${commonImages.backgroundLeftBottomCorner})`,
            }}>
            <img
                src={exampleImages.exampleLanguageIcon}
                alt="Language"
                className="sectionElement__image sectionElement__image--left-bottom sectionElement__image--language"
                onClick={() => {
                    audio.play("click");
                    openPopup();
                }}
            />
        </div>
    );
};

export default LanguageButton;
