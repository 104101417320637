import React from 'react'

function Ring() {
    return (
        <div className="ring" onClick={e => {
            const link = document.createElement("a");
            const canvas = document.querySelector(".facefilterCanvas");
            const data = canvas.toDataURL();

            const offscreen = document.createElement("canvas");
            offscreen.height = canvas.height;
            offscreen.width = canvas.width;
            const ctx = offscreen.getContext("2d");

            const image = new Image();
            image.onload = () => {
                ctx.scale(-1, 1);
                ctx.drawImage(image, 0, 0, -offscreen.width, offscreen.height);

                const url = offscreen.toDataURL("image/jpeg");
                document.body.appendChild(link);
                link.download = `akwarium-${new Date().toISOString().substring(11, 23).replace(".", "_")}.jpg`;
                link.href = url;
                link.click();
                document.body.removeChild(link);

            }
            image.src = data;
            e.preventDefault();
        }} />
    )
}
export default Ring
