import React from 'react';
import { useSelector } from "react-redux";

import '../../config/globalStyles.scss';
import './tutorialScreen.scss';
import { getLanguage } from '../../store/reducers/mainReducer';

const TutorialScreenContent = ({ active, endGame, data }) => {
    const language = useSelector(getLanguage);

    const {
        defaultVideoPath,
        videoPathLocalized,
    } = data.tutorialScreen;

    let videoPath = defaultVideoPath;

    if(videoPathLocalized[language]&&videoPathLocalized[language].length>0){
        videoPath = videoPathLocalized[language]
    }

    //endGame();
    //const endHandler = e => {
    //  endGame();
    //  dispatch(changeScreen);
    //}

    const content = <div className="tutorialMovieContainer">
        <video className="tutorialMovie"
               autoPlay
               //muted
               src={videoPath}
               controls
               //onEnded={endHandler}
        />
    </div>;

    return <div className="screenContentContainer tutorialScreen">{content}</div>;
};

export default TutorialScreenContent;
