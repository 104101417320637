import React from 'react';

import '../main.scss';
import { commonImages } from '../../../config/images';
import Text from "../../TextContainer/Text";

const PointsCounter = ({points}) => {
    
    const pointsDigits = points.toString().length;

    const bgStyle = () => {
        switch (pointsDigits) {
            case 3:
                return {
                    backgroundPosition: "calc(calc(var(--vh, 1vh) * 6) * (-8 + 1) + 6em) 0%",
                };
            case 2:
                return {
                    backgroundPosition: "calc(calc(var(--vh, 1vh) * 6) * (-8 + 1) + 5em) 0%",
                };
            case 1:
            default:
                return {};
        }
    };

    return (
        <div
            className="sectionElement sectionElement--left-top topBackground"
            style={bgStyle()}>
            <img
                src={commonImages.iconCoin}
                alt="Points"
                className="sectionElement__image sectionElement__image--left-top"
            />
            <div className="pointsCounter">
                <p className="colorDark pointsMultiplier"><Text>x</Text></p>
                <h3 className="colorSecondary pointsCount"><Text>{parseInt(points)}</Text></h3>
            </div>
        </div>
    );
};

export default PointsCounter;
