import React, { useState, useMemo } from 'react'
import "./ChangeLanguagePopup.scss";

import { useDispatch, useSelector } from 'react-redux';
import { setChangeLanguagePopup, getScreensData } from '../../../store/actions/mainActions';
import { getGlobalConfig, getLanguage, getAvailableLanguages } from '../../../store/reducers/mainReducer';
import Text from '../../TextContainer/Text';

function ChangeLanguagePopup() {
    const dispatch = useDispatch();

    const {
        change_language_header: headerText,
        change_language_cancel_button: cancelText,
        change_language_confirm_button: confirmText
    } = useSelector(getGlobalConfig);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const screenId = params.has('screenId');

    const initial = useSelector(getLanguage);
    const languageList = useSelector(getAvailableLanguages);

    const filteredList = useMemo(() => [{language_id: "pl-PL", name: "Polski", status: 1}, ...languageList.filter(el => el.status === 1 || (screenId && el.status === 2))], [screenId, languageList]);

    const [selected, setSelected] = useState(filteredList.findIndex(el => el.language_id === initial));

    const closePopup = data => {
        if (data !== null) {
            dispatch(getScreensData(filteredList[data].language_id));
        }

        dispatch(setChangeLanguagePopup(false));
    }
    return (
        <div onClick={e => closePopup(null)} className="changeLanguage__overlay">
            <div onClick={e => {
                e.stopPropagation();
            }} className="changeLanguage__prompt">
                <div className="changeLanguage__prompt__header">
                    <h2 className="colorDark"><Text>{headerText}</Text></h2>
                </div>
                <div className="changeLanguage__prompt__list">
                    {filteredList.map((el, it) => (
                        <div
                            className={"changeLanguage__prompt__list__element" + (selected === it ? " changeLanguage__prompt__list__element--selected" : "")}
                            key={it}
                            onClick={e => {
                                setSelected(it);
                            }}
                        >
                            <h4 className={selected !== it ? "colorDark" : "changeLanguage__prompt__list__element__text--selected"}><Text>{el.name}</Text></h4>
                            <svg className={
                                "changeLanguage__prompt__list__element__check" + 
                                (filteredList[it].language_id === initial ?
                                    (selected === it ?  
                                    " changeLanguage__prompt__list__element__check--selected" : 
                                    " changeLanguage__prompt__list__element__check--unselected") : "") }  height="32" id="check" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="M1 14 L5 10 L13 18 L27 4 L31 8 L13 26 z"/></svg>
                        </div>)
                    )}
                </div>
                <div className="changeLanguage__prompt__footer">
                    <div onClick={e => closePopup(null)} className="changeLanguage__prompt__footer__button changeLanguage__prompt__footer__button--cancel"><h3><Text>{cancelText}</Text></h3></div>
                    <div onClick={e => closePopup(selected)} className="changeLanguage__prompt__footer__button changeLanguage__prompt__footer__button--confirm"><h3><Text>{confirmText}</Text></h3></div>
                </div>
            </div>
        </div>
    )
}

export default ChangeLanguagePopup
