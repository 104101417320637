import React, { useState, useRef, useEffect } from 'react'
import Text from '../TextContainer/Text';

function Gap({ id, isSelected, isFinishing, isFinished, correct, current, initial, changeSelected, setDifferences, setNext, isVisible }) {
    const [content, setContent] = useState(initial);

    const isCorrect = current === correct;
    const isEditable = initial === "_";

    const ref = useRef(null);

    const style = {};

    if (isFinishing && !isFinished && isVisible && !isCorrect) {
        style.color = "white"
    }
    else if (isSelected) {
        style.backgroundColor = "#003a7f"
        style.color = "white";
    } else {
        style.backgroundColor = "white";

        if (isEditable) {
            style.color = "gray";
            if (isFinishing) {
                if (isCorrect) {
                    style.color = "#90d63f";
                } else {
                    style.color = "#ff3e45";
                }
            }
        }
        else {
            style.color = null;
        }
    }

    useEffect(() => {
        if (isSelected && !isFinishing) {
            ref.current.focus();
        } else {
            ref.current.blur();
        }
    }, [isSelected, isFinishing])

    return (
        <div
            className="completeWordGap"
            style={style}
            onClick={e => {
                if (!isFinishing && !isSelected && initial === "_") {
                    changeSelected({ value: id });
                    ref.current.focus();
                } else {
                    changeSelected({ value: null })
                }
            }
            }>
            <Text>{(isFinished && !isCorrect) ? correct : content !== "_" ? content : ""}</Text>
            <input ref={ref}
                style={{ position: "absolute", right: 1000000, height: "40px", width: "40px" }}
                onInput={e => {
                    if (e.target.value.length > 1) {
                        e.target.value = e.target.value[e.target.value.length - 1];
                    } else if (e.target.value.length === 0) {
                        e.target.value = "_";
                    }
                    e.target.value = e.target.value.toUpperCase();
                    setContent(e.target.value);
                    setDifferences({
                        payload: {
                            letter: e.target.value,
                            position: id
                        }
                    })
                    if (e.target.value !== "_") {
                        setNext();
                    }
                }}

                onFocus={e => {
                    window.scrollTo(0, document.body.scrollHeight)
                }}


                onBlur={e => {
                    if (isSelected) {
                        changeSelected({ value: null })
                    }
                }}
            />

        </div>
    )
}

export default Gap
