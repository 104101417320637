import React from 'react'

import { useSelector } from 'react-redux';
import { getGlobalConfig } from '../../store/reducers/mainReducer';
import Text from '../TextContainer/Text';

function ExitFullscreenPopup({ setVisible }) {
    const { exit_fullscreen_mode_text , change_language_confirm_button } = useSelector(getGlobalConfig);

    return (
        <div onClick={e => setVisible(false)} className="popup__overlay">
            <div onClick={e => {
                e.stopPropagation();
            }} className="popup__prompt">
                <div className="popup__prompt__text">
                    <h4 className="colorDark"><Text>{exit_fullscreen_mode_text}</Text></h4>
                </div>
                <div className="popup__prompt__footer">
                    <div onClick={e => setVisible(false)} className="popup__prompt__footer__button popup__prompt__footer__button--confirm"><h3><Text>{change_language_confirm_button}</Text></h3></div>
                </div>
            </div>
        </div>
    )
}

export default ExitFullscreenPopup
