import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getGlobalConfig } from '../../store/reducers/mainReducer';
import { commonImages } from '../../config/images';
import { getImagePath } from '../../config/helpers';
import './curiosityScreen.scss';
import Text from '../TextContainer/Text';

const CuriosityScreen = ({
    data,
    videoFullscreen,
    setVideoFullscreen,
    hidden
}) => {
    const globalConfig = useSelector(getGlobalConfig);

    const { curiosity_header_text } = globalConfig;
    const { curiosityPopup: curiosity = {} } = data;

    const { title, text, graphicPath, youtube_link, videoFilePath } = curiosity;
    const videoRef = useRef(null)

    useEffect(() => {
        const videoElement = videoRef.current;

        const fullscreenEvent = () => {
            const element = document.fullscreenElement;
            if (element && (element.nodeName === "IFRAME" || element.nodeName === "VIDEO")) {
                unlockOrientation(true);
            } else {
                unlockOrientation(false);
            }
        };

        if (videoElement !== null) {
            videoElement.addEventListener('fullscreenchange', fullscreenEvent);
            videoElement.addEventListener('msfullscreenchange', fullscreenEvent);
            videoElement.addEventListener('mozfullscreenchange', fullscreenEvent);
            videoElement.addEventListener('webkitfullscreenchange', fullscreenEvent);
        }

        return () => {
            if (videoElement !== null) {
                videoElement.removeEventListener('fullscreenchange', fullscreenEvent);
                videoElement.removeEventListener('msfullscreenchange', fullscreenEvent);
                videoElement.removeEventListener('mozfullscreenchange', fullscreenEvent);
                videoElement.removeEventListener('webkitfullscreenchange', fullscreenEvent);
            }
        }
        // eslint-disable-next-line
    }, [videoFullscreen]);

    const unlockOrientation = value => {
        setVideoFullscreen(value);
    };

    const getId = url => {
        if (url) {
            // eslint-disable-next-line no-useless-escape
            const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            const match = youtube_link.match(regExp);
            if (match && match[2].length === 11) {
                return match[2];
            }
        }
    }
    return (
        <div className="screenContentContainer curiosityScreen" hidden={hidden}>
            <div className="curiosityHeader">
                <img
                    className="curiosityIcon"
                    src={commonImages.elementCuriosity}
                    alt="Curiosity"
                />
                <h2 className="curiosityHeaderText"><Text>{curiosity_header_text}</Text></h2>
            </div>

            <div className="curiosityMultimedia" style={{ display: !videoFilePath && !youtube_link && !graphicPath && "none" }}>
                {
                    videoFilePath ? (
                        <video
                            ref={videoRef}
                            src={getImagePath(videoFilePath)}
                            className="curiosityMultimediaVideo"
                            controls={true}
                            muted={true}
                            playsInline={true}
                        />
                    ) :
                        youtube_link ? (
                            <iframe
                                ref={videoRef}
                                className="curiosityMultimediaIframe"
                                title={title}
                                src={`https://youtube.com/embed/${getId(youtube_link)}?autoplay=1&rel=0&amp;mute=1`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        ) :
                            graphicPath ? (
                                <img className="curiosityMultimediaImage" src={getImagePath(graphicPath)} alt="" />
                            ) :
                                null}
            </div>
            <div className="curiosityText">
                {title && <h3 className="curiosityTextHeader"><Text>{title}</Text></h3>}
                <p><Text>{text}</Text></p>
            </div>
        </div>
    );
};

export default CuriosityScreen;
