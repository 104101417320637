import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import { changeScreen } from '../../../store/actions/mainActions';

function SwipeContainer({ enabled }) {
    const dispatch = useDispatch();

    const startPoint = useRef(0);
    const [left, setLeft] = useState(null);

    const handleTouchStart = event => {
        startPoint.current = event.targetTouches[0].clientX;
        setLeft(event.changedTouches[0].clientX);

    };

    const handleTouchMove = event => {
        setLeft(event.changedTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        setLeft(null);
        if (left < (startPoint.current / 3) * 2) {
            dispatch(changeScreen)
        }
    };

    return (
        <div
            hidden={!enabled}
            className="swipeContainer"
            style={{
                transform: left !== null && `translateX(calc(${left}px - 50%))`,
                backgroundColor: left !== null && "rgba(255,255,255,0.5)",
            }}
            onTouchStart={event => {
                if (enabled)
                    handleTouchStart(event)
            }}
            onTouchMove={event => {
                if (enabled)
                    handleTouchMove(event)
            }}
            onTouchEnd={event => {
                if (enabled)
                    handleTouchEnd(event)
            }}
        />
    );
};

export default SwipeContainer;
