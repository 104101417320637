import React from "react";

import { getImagePath } from "../../config/helpers";
import Text from "../TextContainer/Text";

const stateEnum = {
    unselected: 0,
    selected: 1,
    correct: 2,
    wrong: 3
};

function AnswerTile({ answer, index, state = stateEnum.unselected, wrongVisible, clickHandler, isRight, large }) {
    let className = "answer";
    let color = "colorWhite";

    if(large) {
        className += " answer--large"        
    }

    const { src, text } = answer;

    // Set answer tile class
    if (state === stateEnum.correct && wrongVisible) {
        className += " answer--correct"
    }
    else if (state === stateEnum.wrong && wrongVisible) {
        className += " answer--wrong"
    }
    else if (state === stateEnum.selected) {
        className += " answer--selected"
    }
    else if (!src) {
        color = "colorDark"
    }


    let content = null;

    // Set component structure based on if it's text or/and answer
    if (text && src) {
        content = <>
            <div className="pairQuizOverlay">
                <img src={getImagePath(src)} className="image" alt="" />
            </div>
            <h3 className={color}><Text>{text}</Text></h3>
        </>
    }
    else if (text) {
        content = <h3 className={color}><Text>{text}</Text></h3>
    }
    else if (src) {
        content = <img src={getImagePath(src)} className="image" alt="" />
    }

    return (
        <div className={className} onClick={clickHandler(index, isRight)}>
            {content}
        </div>
    )
}

export default AnswerTile;