import React, { useRef, useEffect } from 'react'

import Header from './Header'
import Content from './Content'
import Footer from './Footer'
import { getViewportSize, getImagePath } from '../../../config/helpers';
import { exampleImages } from '../../../config/images';
import { useSelector } from 'react-redux';
import { getWinPopupData, getLosePopupData } from '../../../store/reducers/mainReducer';

function Screen({ active, index, data = {}, points, timeStartPopup, winLosePopup, curiosityPopup }) {
    const ref = useRef(null);
    const winPopup = useSelector(getWinPopupData);
    const losePopup = useSelector(getLosePopupData);
    const chooseBackgroundStyle = () => {
        const viewportSize = getViewportSize();
        const viewportAspectRatio = viewportSize.width / viewportSize.height;
        if (winLosePopup === "win") {
            return {
                backgroundImage: `url(${getImagePath(
                    winPopup.graphicPath,
                )})`,
            };
        }
        else if (winLosePopup === "lose") {
            return {
                backgroundImage: `url(${getImagePath(
                    losePopup.graphicPath,
                )})`,
            };
        }
        else if (curiosityPopup) {
            let backgroundImage = exampleImages.exampleCuriosityBackground;
            if (data.screenCategory) {
                if (data.screen_type !== "navigation") {
                    backgroundImage = data.screenCategory.bgGraphicPath;
                }
            }
            return {
                backgroundImage: `url(${getImagePath(backgroundImage)})`,
            };
        }

        else if (timeStartPopup) {
            const backgroundImage = (data.screenCategory && data.screenCategory.bgGraphicPath) || exampleImages.exampleStartTimeScreenBackground;

            return {
                backgroundImage: `url(${getImagePath(backgroundImage)})`,
            };
        } else {
            return data.infoScreen &&
                data.infoScreen.type === 'start'
                ? viewportAspectRatio >= 2 / 3
                    ? {
                        backgroundImage: `url(${getImagePath(
                            data.screenCategory ? (data.screenCategory.bgTabletGraphicPath || data.screenCategory.bgGraphicPath) : data.bgGraphicPath,
                        )})`,
                        backgroundPosition: 'center top',
                    }
                    : {
                        backgroundImage: `url(${getImagePath(
                            data.screenCategory ? data.screenCategory.bgGraphicPath : data.bgGraphicPath,
                        )})`,
                        backgroundPosition: 'center top',
                    }
                : {
                    backgroundImage: `url(${getImagePath(
                        data.screenCategory ? data.screenCategory.bgGraphicPath : data.bgGraphicPath,
                    )})`,
                };
        }
    };
    useEffect(() => {
        const div = ref.current;
        if (div) {
            if (div.classList.length === 0) {
                div.classList.add("current-screen-exit-done")
            }
        }
        return () => {
            if (div) {
                div.classList.remove("current-screen-exit-done")
            }
        };
    })

    return (
        <div ref={ref}>
            <div className="screenContainer" style={chooseBackgroundStyle()}>
                {active && (
                    <>
                        <Header active={active} index={index} data={data} points={points} curiosityPopup={curiosityPopup} winLosePopup={winLosePopup} />
                        <Content active={active} index={index} data={data} timeStartPopup={timeStartPopup} curiosityPopup={curiosityPopup} winLosePopup={winLosePopup} />
                        <Footer active={active} data={data} timeStartPopup={timeStartPopup} curiosityPopup={curiosityPopup} winLosePopup={winLosePopup} />
                    </>
                )}
            </div>
        </div>
    )
}

export default Screen;
