// EXAMPLE ONLY

//const path = 'https://apps.netigen.pl/api/v2';
//export const mainServerPath = 'http://localhost/balticaGame/backend/yii-app/web';
//export const mainServerPath = 'https://akwarium-gdynskie-game.netigen.pl';
export const mainServerPath = 'https://gra.akwarium.gdynia.pl';

const API = {
    //ForGetScreensData: `${path}/ `,
    // test data:
    globalConfig: {
        play_button_text: 'Graj',
        progress_bar_text: 'Realizacja trasy',
        points_1: 'punkt',
        points_2_4: 'punkty',
        points_5: 'punktów',
        limit_time_start_text_1: 'Uwaga!',
        limit_time_start_text_2: 'Zadanie na czas!',
        limit_time_start_text_3: 'Skoncentruj się. Kiedy będziesz gotowy, kliknij',
        curiosity_header_text: 'Ciekawostka',
        rotation_overlay_text: "Proszę obrócić ekran",
        idle_text: "swipe",
        change_language_header: "Zmień język",
        change_language_cancel_button: "ANULUJ",
        change_language_confirm_button: "OK",
        exit_fullscreen_mode_text: "",
        offline_warning_text: "",
        headphones_info_text: "",
        desktop_info_text: ""
    },

    testScreenData: {
        "winPopup": {
            "id": 1,
            "name": "Okienko Wygranej",
            "text_1": "Brawo! ",
            "text_after_counter_1": "punkt dla Ciebie!",
            "text_after_counter_2_4": "punkty dla Ciebie!",
            "text_after_counter_5": "punktów dla Ciebie!",
            "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
        },
        "losePopup": {
            "id": 1,
            "name": "Okienko Przegranej",
            "text_1": "Kontynuuj przygodę!",
            "text_2": "punktów",
            "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
        },
        "screensData": [
            { 
                "id":95,
                "limit_time":0,
                "text_to_display_on_start_time_screen":"",
                "points_counter_visible":1,
                "progress_bar_visible":0,
                "map_btn_visible":0,
                "language_btn_visible":0,
                "screen_type":"swipe_quiz",
                "screenCategory":{ 
                    "id":17,
                    "title":"Gra z krokodylem",
                    "bgGraphicPath":"uploads/screen-category-bg-graphic/5e2eaf5b0a1a9.jpeg"
                },
                "bgGraphicPath":"uploads/screen-category-bg-graphic/5e2eaf5b0a1a9.jpeg",
                "swipeQuizScreen":{ 
                    "id":4,
                    "title":"",
                    "text":"",
                    "top_text":"",
                    "bottom_text":"",
                    "is_crocodile_game": true,
                    "topGraphicPath":"uploads/swipe-quiz-graphic/5e2ec84a03d71.png",
                    "bottomGraphicPath":"uploads/swipe-quiz-graphic/5e2ec42917dcf.png",
                    "swipeQuizAnswers":[ 
                        { 
                            "id":21,
                            "text":"",
                            "good_direction":0,
                            "points":1,
                            "graphicPath":"uploads/swipe-quiz-graphic/5e2eb96b9697a.png"
                        },
                        { 
                            "id":22,
                            "text":"",
                            "good_direction":1,
                            "points":2,
                            "graphicPath":"uploads/swipe-quiz-graphic/5e2ebb7b0da76.png"
                        },
                        { 
                            "id":23,
                            "text":"",
                            "good_direction":0,
                            "points":1,
                            "graphicPath":"uploads/swipe-quiz-graphic/5e2eb96bba293.png"
                        },
                        { 
                            "id":24,
                            "text":"",
                            "good_direction":0,
                            "points":1,
                            "graphicPath":"uploads/swipe-quiz-graphic/5e300d832f0dd.png"
                        }
                    ]
                }
            },
            { 
                "id":51,
                "limit_time":0,
                "text_to_display_on_start_time_screen":"",
                "points_counter_visible":0,
                "progress_bar_visible":0,
                "map_btn_visible":1,
                "language_btn_visible":0,
                "screen_type":"navigation",
                "screenCategory":{ 
                    "id":3,
                    "title":"",
                    "bgGraphicPath":"uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath":"uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen":{ 
                    "id":10,
                    "display_graphic_in_round_frame":0,
                    "start_animate_position":0,
                    "end_animate_position":0,
                    "points":null,
                    "text_1":"",
                    "current_room":"Wyjście z budynku ",
                    "text_2":"",
                    "next_room":"",
                    "animation_position":null,
                    "bgGraphicPath":"uploads/navigation-screen-bg-graphic/5ddcfaec4c72a.jpeg"
                }
            },
            { 
                "id":49,
                "limit_time":0,
                "text_to_display_on_start_time_screen":"",
                "points_counter_visible":1,
                "progress_bar_visible":0,
                "map_btn_visible":0,
                "language_btn_visible":0,
                "screen_type":"with_institutions_list",
                "screenCategory":{ 
                    "id":14,
                    "title":"",
                    "bgGraphicPath":"uploads/screen-category-bg-graphic/5de90d5015853.jpeg"
                },
                "bgGraphicPath":"uploads/screen-category-bg-graphic/5de90d5015853.jpeg",
                "withInstitutionsListScreen":{ 
                    "id":1,
                    "name":"Standardowy ekran z listą instytucji",
                    "text_before_list":"Współautorzy aplikacji:",
                    "text_1":"\r\n\r\nInstytucja ABCD\r\n\r\nInstytucja ZXCV\r\n\r\nInstytucja MNBV\r\n\r\nInstytucja MXBV\r\n\r\n\r\n\r\n\r\nTa gra jest produktem współpracy instytucji bałtyckich.",
                    "link":"www.balticmuseums.info",
                    "link_text":"www.balticmuseums.info"
                }
            },
            { 
                "id":89,
                "limit_time":0,
                "text_to_display_on_start_time_screen":"",
                "points_counter_visible":1,
                "progress_bar_visible":0,
                "map_btn_visible":0,
                "language_btn_visible":0,
                "screen_type":"quiz",
                "screenCategory":{ 
                    "id":7,
                    "title":"Rafa Koralowa",
                    "bgGraphicPath":"uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg"
                },
                "bgGraphicPath":"uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg",
                "quizScreen":{ 
                    "id":34,
                    "type":"graphic_answers-select_area",
                    "title":"Wskaż obszar",
                    "question":"wskaż niebezpieczny obszar",
                    "start_btn_text":null,
                    "confirm_btn_text":null,
                    "quizAnswers":[ 
                        { 
                            "id":119,
                            "points":1,
                            "graphicPath":"uploads/quiz-answer-graphic/5e2aa1ce186e6.jpeg"
                        },
                        { 
                            "id":120,
                            "points":null,
                            "graphicPath":"uploads/quiz-answer-graphic/5e2aa1ce1a6f0.jpeg"
                        },
                        { 
                            "id":121,
                            "points":null,
                            "graphicPath":"uploads/quiz-answer-graphic/5e2aa1ce1c51d.jpeg"
                        },
                        { 
                            "id":122,
                            "points":1,
                            "graphicPath":"uploads/quiz-answer-graphic/5e2aa1ce20b69.jpeg"
                        },
                        { 
                            "id":123,
                            "points":null,
                            "graphicPath":"uploads/quiz-answer-graphic/5e2aa1ce22f90.jpeg"
                        },
                        { 
                            "id":124,
                            "points":null,
                            "graphicPath":"uploads/quiz-answer-graphic/5e2aa1ce25059.jpeg"
                        }
                    ]
                }
            },
            {
                "id": 45,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "to jest <i>pochylone</i>",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 10,
                    "title": "Wodne Zwierzęta Świata",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 23,
                    "title": "",
                    "text": "60-centymetrowy rekin marmurkowy to jeden z mniejszych rekinów świata.  Pochodzi z Australii, gdzie żyje w płytkich wodach przybrzeżnych.",
                    "youtube_link": "",
                    "videoFilePath": "uploads/curiosity-video-file/050-ciekawostka-ryby-z-rafy.mp4"
                },
                "quizScreen": {
                    "id": 18,
                    "type": "graphic_answers-select_area",
                    "title": "",
                    "question": "Zaznacz wszystkie gatunki rekinów, które mieszkają w tej Sali",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 75,
                            "points": 1,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f0d032b78.jpeg"
                        },
                        {
                            "id": 75,
                            "points": 1,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f0d032b78.jpeg"
                        },
                        {
                            "id": 75,
                            "points": 1,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f0d032b78.jpeg"
                        },
                        {
                            "id": 75,
                            "points": 0,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f0d032b78.jpeg"
                        },
                        {
                            "id": 75,
                            "points": 1,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f0d032b78.jpeg"
                        },
                        {
                            "id": 75,
                            "points": 0,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f0d032b78.jpeg"
                        }
                    ]
                }
            },
            {
                id: 1,
               // limit_time: 1500,
                points_counter_visible: 1,
                progress_bar_visible: 1,
                map_btn_visible: 1,
                language_btn_visible: 0,
                screen_type: 'game',
                screenCategory: {

                    bgGraphicPath: 'uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg',
                },
                gameScreen: {
                    partsCount: 5,
                    graphicPath: 'uploads/lose-popup-graphic/5dde74f13f3b4.jpeg',
                    //bgGraphicPath: 'uploads/info-screen-bg-graphic/btn-play.png',
                    points: 1,
                    title: 'Hejka',
                    description: 'Sklejka',
                    type: 'puzzle',
                },
                winPopup: {
                    id: 1,
                    name: 'Standardowy popup wygranej',
                    text_1: 'Brawo',
                    text_after_counter_1: 'punkt dla Ciebie.',
                    text_after_counter_2_4: 'punkty dla Ciebie!',
                    text_after_counter_5: 'punktów dla Ciebie!!!!',
                    graphicPath: 'uploads/win-popup-graphic/bg-winner-screen.png',
                },
                losePopup: {
                    id: 1,
                    name: 'Standardowy popup przegranej',
                    text_1: 'Kontynuuj przygodę',
                    text_2: 'punktów',
                    graphicPath: 'uploads/lose-popup-graphic/bg-loser-screen.png',
                },
            },
            
            {
                "id": 49,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 0,
                "language_btn_visible": 0,
                "screen_type": "with_institutions_list",
                "screenCategory": {
                    "id": 14,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5de90d5015853.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5de90d5015853.jpeg",
                "withInstitutionsListScreen": {
                    "id": 1,
                    "name": "Standardowy ekran z listą instytucji",
                    "text_before_list": "Współtwórcy gry",
                    "text_1": "Ja\n\nTy\n\nOn\n\nOna\n\nOno\n\nJa\n\nAkwarium Gdyńskie\n\nOn\n\nOna\n\nOno\n\nJa\n\nTy\n\nOn\n\nOna\n\nOno\n\n",
                    "link": "www.balticmuseums.info",
                    "link_text": "Strona baltica museums"
                }
            },
            {
                "id": 48,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "with_newsletter_end",
                "screenCategory": {
                    "id": 12,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5ddbbef711453.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5ddbbef711453.jpeg",
                "withNewsletterEndScreen": {
                    "id": 1,
                    "name": "Pożegnanie z użytkownikiem",
                    "text_1": "Jeśli któryś z naszych zwierzaków szczególnie Cię zainteresował, możesz wrócić na ekspozycję.",
                    "text_2": "Dziękujemy za wspólną zabawę!",
                    "graphic_text": "Do zobaczenia!",
                    "newsletter_link": "https://akwarium.gdynia.pl/",
                    "newsletter_btn_text": "Zapisz się do newslettera"
                }
            },
            
            {
                "id": 1,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 1,
                "screen_type": "info",
                "screenCategory": {
                    "id": 4,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dcead41eadb8.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dcead41eadb8.jpeg",
                "screenLogotypesData": [
                    {
                        "name": "Akwarium Gdyńskie",
                        "graphicPath": "uploads/logotype-graphic/img-logo-ag.png"
                    },
                    {
                        "name": "MIR",
                        "graphicPath": "uploads/logotype-graphic/img-logo-mir.png"
                    }
                ],
                "infoScreen": {
                    "id": 1,
                    "type": "start",
                    "title": "Akwarium Gdyńskie",
                    "text_1": "Witamy!",
                    "text_2": "Akwarium Gdyńskim",
                    "required_checkbox_text": ""
                }
            },
            {
                "id": 50,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 0,
                "language_btn_visible": 0,
                "screen_type": "points_summary",
                "screenCategory": {
                    "id": 13,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5ddbcafe789bc.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5ddbcafe789bc.jpeg",
                "pointsSummaryScreen": {
                    "id": 1,
                    "text_1": "BRAWO!",
                    "text_2": "Zdobyłeś: ",
                    "check_animal_text": "Jeśli chcesz zakończyć grę i sprawdzić jakie zwierzę z podwodnego świata Cię utożsamia",
                    "check_animal_btn_text": "ZAKOŃCZ GRĘ",
                    "last_game_text": "Jeśli chcesz zwiększyć liczbę zdobytych punktów, zagraj w grę ostatniej szansy! ",
                    "last_game_btn_text": "GRAJ"
                }
            },
            {
                "id": 18,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 0,
                "language_btn_visible": 0,
                "screen_type": "animal_profile",
                "screenCategory": {
                    "id": 1,
                    "title": "Amazonia",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656d1794ba.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656d1794ba.jpeg",
                "animalProfileScreen": {
                    "id": 1,
                    "name": "Standardowy Ekran z Profilem Zwierzęcym",
                    "text_1": "Brawo! Zdobyłeś ",
                    "text_2": "Twój profil to:",
                    "display_selfie_with_animal_profile_as_next_screen": 1,
                    "animalProfiles": [
                        {
                            "id": 1,
                            "title": "Rekin1",
                            "text_under_profile": "Jesteś rekinem, bo zdobyłeś dużo punktów "
                        },
                        {
                            "id": 2,
                            "title": "Rekin Wielorybi",
                            "text_under_profile": "Jesteś rekinem wielorybim"
                        },
                        {
                            "id": 3,
                            "title": "profil 3",
                            "text_under_profile": "Jesteś rekinem ostronosem"
                        }
                    ]
                }
            },

            {
                "id": 3,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "info",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "screenLogotypesData": [
                    {
                        "name": "Akwarium Gdyńskie",
                        "graphicPath": "uploads/logotype-graphic/img-logo-ag.png"
                    },
                    {
                        "name": "MIR",
                        "graphicPath": "uploads/logotype-graphic/img-logo-mir.png"
                    }
                ],
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "infoScreen": {
                    "id": 2,
                    "type": "withCheckbox",
                    "title": "Czy akceptujesz <i>regulamin</i> zwiedzania?",
                    "text_1": "Projekt finansowany ze środków Unii Europejskiej z Europejskiego Funduszu Rozwoju Regionalnego",
                    "text_2": "",
                    "required_checkbox_text": "Tak",
                    //logotypes: [
                    //    {
                    //        "name": "Akwarium Gdyńskie",
                    //        "graphicPath": "uploads/logotype-graphic/img-logo-ag.png"
                    //    },
                    //    {
                    //        "name": "MIR",
                    //        "graphicPath": "uploads/logotype-graphic/img-logo-mir.png"
                    //    }
                    //]
                }
            },



            {
                "id": 58,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "Znajdź salę Zostera Marina",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 0,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen": {
                    "id": 11,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": 1,
                    "text_1": "Znajdź salę ",
                    "current_room": "Zostera Marina",
                    "text_2": "",
                    "next_room": "",
                    "animation_position": 2,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5e0f1af85bdde.jpeg"
                }
            },
            {
                "id": 4,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 2,
                    "title": "Zostera Marina",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656ef39488.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656ef39488.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "quizScreen": {
                    "id": 2,
                    "type": "graphic_answers",
                    "title": "",
                    "question": "Które z przedstawionych zwierząt widzisz w zbiorniku nr 101?",
                    "start_btn_text": "",
                    "confirm_btn_text": "",
                    "quizAnswers": [
                        {
                            "id": 66,
                            "points": 0,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7e6e56bf95.jpeg"
                        },
                        {
                            "id": 67,
                            "points": 0,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7e70e09532.jpeg"
                        },
                        {
                            "id": 68,
                            "points": null,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7e72cca45a.jpeg"
                        },
                        {
                            "id": 69,
                            "points": 2,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7e75f65c99.jpeg"
                        }
                    ]
                }
            },
            {
                "id": 6,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 2,
                    "title": "Zostera Marina",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656ef39488.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656ef39488.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 2,
                    "title": "",
                    "text": "Okonie są postrzegane jako ryby słodkowodne, jednak żyją również w wodach słonawych np. w Zatoce Puckiej. Ryby te zapadają w stan głębokiego snu. Odpoczywając przy dnie, prawie nie reagują na bodźce środowiska. Rozbudzają się dopiero po kilku chwilach.",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 1,
                    "type": "text_answers",
                    "title": "",
                    "question": "W naszym Akwarium zatonęła łódź. Znajduje się w zbiorniku, w którym mieszkają drapieżne ryby z Zatoki Puckiej. Jaki jest jego numer?",
                    "start_btn_text": "",
                    "confirm_btn_text": "",
                    "quizAnswers": [
                        {
                            "id": 1,
                            "points": null,
                            "text": "102"
                        },
                        {
                            "id": 4,
                            "points": 1,
                            "text": "202"
                        },
                        {
                            "id": 5,
                            "points": 0,
                            "text": "304"
                        },
                        {
                            "id": 11,
                            "points": null,
                            "text": "404"
                        }
                    ]
                }
            },
            {
                "id": 5,
                "limit_time": 10,
                "text_to_display_on_start_time_screen": "Przygotuj się do zadania quizowego na czas",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 2,
                    "title": "Zostera Marina",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656ef39488.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656ef39488.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 3,
                    "title": "",
                    "text": "U wężynek samica przykleja ikrę do brzucha samca. W podwójnym rzędzie nosi on nawet do 200 jaj, które w trakcie dalszego rozwoju delikatnie zagłębiają się w jego skórze. Oznacza to, że przez około trzy tygodnie tata wężynki całą przyszłą rodzinę zabiera wszędzie ze sobą. ",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 3,
                    "type": "text_answers",
                    "title": "",
                    "question": "Gdzie kryją się iglicznie i wężynki?",
                    "start_btn_text": "",
                    "confirm_btn_text": "",
                    "graphicPath": "uploads/quiz-screen-graphic/5dd7e7c9e8590.jpeg",
                    "quizAnswers": [
                        {
                            "id": 18,
                            "points": 3,
                            "text": "Wśród trawy morskiej"
                        },
                        {
                            "id": 19,
                            "points": null,
                            "text": "Na dnie mulistym"
                        },
                        {
                            "id": 20,
                            "points": null,
                            "text": "Na dnie piaszczyste"
                        },
                        {
                            "id": 21,
                            "points": null,
                            "text": "Na dnie kamienistym"
                        }
                    ]
                }
            },
            {
                "id": 16,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 0,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen": {
                    "id": 3,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": null,
                    "text_1": "",
                    "current_room": "",
                    "text_2": "Przejdź piętro wyżej, ",
                    "next_room": "do Sali Dydaktycznej",
                    "animation_position": 5,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcf9a9cacd6.jpeg"
                }
            },
            {
                "id": 17,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 0,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "curiosityPopup": {
                    "id": 4,
                    "title": "",
                    "text": "Foki po urodzeniu karmione są przez matkę mlekiem przez około 3 tygodnie. Pokarm zawiera aż do 50% tłuszczu, dlatego przez ten czas maluchy mocno przybierają na wadze. Szczenięta pokryte są gęstym, białym futrem zwanym lanugo.",
                    "youtube_link": ""
                },
                "navigationScreen": {
                    "id": 4,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": 1,
                    "text_1": "",
                    "current_room": "",
                    "text_2": "Podejdź do eksponatu, który widzisz na ekranie i kliknij w zdjęcie",
                    "next_room": "",
                    "animation_position": null,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcf9c9dbd66.jpeg"
                }
            },
            {
                "id": 19,
                "limit_time": 20,
                "text_to_display_on_start_time_screen": "Wieloryby fiszbinowe, mimo, że olbrzymie, odżywiają się drobnymi skorupiakami - krylem. Muszą go zjeść bardzo dużo, żeby się najeść.\r\nNakarm wieloryba co najmniej dziesięcioma skorupiakami, aby zdobyć 1 punkt.",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "game",
                "screenCategory": {
                    "id": 5,
                    "title": "Sala Dydaktyczna",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 5,
                    "title": "",
                    "text": "Wieloryb grenlandzki jest najdłużej żyjącym ssakiem. Spotykano osobniki mające ponad 200 lat! ",
                    "youtube_link": ""
                },
                "gameScreen": {
                    "id": 1,
                    "name": "Wieloryb zjadający kryl",
                    "type": "whale_game",
                    "points": 1,
                    "title": "",
                    "description": ""
                }
            },
            {
                "id": 21,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 5,
                    "title": "Sala Dydaktyczna",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 6,
                    "title": "",
                    "text": "Podobnie jak ludzie, pingwiny reagują na łaskotki i przywiązują się do swoich opiekunów. Za to znacznie lepiej widzą pod wodą niż na lądzie. \r\n",
                    "youtube_link": "",
                    "graphicPath": "uploads/lose-popup-graphic/5dde6dd1190ac.jpeg"
                },
                "quizScreen": {
                    "id": 4,
                    "type": "text_answers",
                    "title": "",
                    "question": "Antarktyda to królestwo pingwinów. Zmierz się z pingwinem cesarskim, aby sprawdzić kto jest wyższy. Ile cm wzrostu ma największy gatunek?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "graphicPath": "uploads/quiz-screen-graphic/5dd7e8087bc86.jpeg",
                    "quizAnswers": [
                        {
                            "id": 22,
                            "points": 0,
                            "text": "70"
                        },
                        {
                            "id": 23,
                            "points": 0,
                            "text": "40"
                        },
                        {
                            "id": 24,
                            "points": 2,
                            "text": "120"
                        },
                        {
                            "id": 25,
                            "points": 0,
                            "text": "150"
                        }
                    ]
                }
            },
            {
                "id": 22,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 5,
                    "title": "Sala Dydaktyczna",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 7,
                    "title": "",
                    "text": "Węgorz europejski w okresie wędrówek (rzeka — morze — rzeka) widywany jest także na lądzie. Jego larwy opanowały świetnie sztukę znikania. Ich ciało jest przezroczyste, a narządy wewnętrzne mikroskopijnej wielkości. Dodatkowo ich krew nie ma ani krwinek czerwonych, ani hemoglobiny.",
                    "youtube_link": "",
                    "graphicPath": "uploads/lose-popup-graphic/5dde74f13f3b4.jpeg"
                },
                "quizScreen": {
                    "id": 5,
                    "type": "text_answers",
                    "title": "",
                    "question": "Węgorz europejski to podróżnik, który w ciągu życia przebywa tysiące kilometrów, z miejsca urodzenia do miejsca zamieszkania. Czy ten gatunek przychodzi na świat w rzekach?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 26,
                            "points": 0,
                            "text": "TAK"
                        },
                        {
                            "id": 27,
                            "points": 2,
                            "text": "NIE"
                        }
                    ]
                }
            },
            {
                "id": 53,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 5,
                    "title": "Sala Dydaktyczna",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 8,
                    "title": "",
                    "text": "Ryby piły przychodzą na świat, mając długość około 50 cm. Kolce na bokach rostrum są wówczas miękkie i elastyczne, co zapobiega poranieniu dróg rodnych samic.",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 21,
                    "type": "text_answers",
                    "title": "",
                    "question": "Ryba piła używa rostrum do:",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 84,
                            "points": 2,
                            "text": "Rozgrzebywania i przeczesywania"
                        },
                        {
                            "id": 85,
                            "points": 0,
                            "text": "Piłowania"
                        },
                        {
                            "id": 86,
                            "points": 0,
                            "text": "Wachlowania"
                        },
                        {
                            "id": 87,
                            "points": 0,
                            "text": "Rozmnażania"
                        }
                    ]
                }
            },
            {
                "id": 43,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "pair_quiz",
                "screenCategory": {
                    "id": 5,
                    "title": "Sala Dydaktyczna",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd675c085b02.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 9,
                    "title": "",
                    "text": "Czy żółwie mogą wyjść ze skorupy? Nie, ponieważ skorupa to tak naprawdę część jego szkieletu i rośnie razem z nim. Pancerz składa się z kości, które narastają wokół żeber i kręgosłupa.",
                    "youtube_link": ""
                },
                "pairQuizScreen": {
                    "id": 1,
                    "title": "",
                    "text": "Wyróżniamy 7 gatunków żółwi morskich. Można je rozpoznać po budowie górnej (karapaksu) i dolnej (plastronu) części skorupy. Połącz karapaks z odpowiednim gatunkiem żółwia.",
                    "pairQuizAnswers": [
                        {
                            "id": 3,
                            "left_text": "",
                            "right_text": "",
                            "points": 1,
                            "leftGraphicPath": "uploads/pair-quiz-graphic/5dd7e8db5da6d.jpeg",
                            "rightGraphicPath": "uploads/pair-quiz-graphic/5df1f3181fdf4.jpeg"
                        },
                        {
                            "id": 4,
                            "left_text": "",
                            "right_text": "",
                            "points": 0,
                            "rightGraphicPath": "uploads/pair-quiz-graphic/5df1f2db7d16d.jpeg"
                        },
                        {
                            "id": 5,
                            "left_text": "",
                            "right_text": "",
                            "points": null,
                            "rightGraphicPath": "uploads/pair-quiz-graphic/5df1f33d871cd.jpeg"
                        }
                    ]
                }
            },
            {
                "id": 23,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen": {
                    "id": 5,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": null,
                    "text_1": "Przejdź piętro wyżej, ",
                    "current_room": "do Sali Bałtyckiej",
                    "text_2": "",
                    "next_room": "",
                    "animation_position": null,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcf9f4beb38.jpeg"
                }
            },
            {
                "id": 24,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 8,
                    "title": "Środowisko Morza Bałtyckiego",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6604437111.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6604437111.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 10,
                    "title": "",
                    "text": "Bałtyk nazywany jest największym cmentarzyskiem Europy. Podczas II Wojny Światowej zostały tutaj zatopione niemieckie statki: „Wilhelm Gustloff”, „Goya” i „Steuben”. Były to największe tragedie w historii katastrof morskich wszechczasów. Wraki te, jako miejsca spoczynku zmarłych są otoczone specjalną ochroną. Nurkowanie w tych lokalizacjach wymaga specjalnych pozwoleń.\r\n",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 6,
                    "type": "text_answers",
                    "title": "",
                    "question": "Podejdź do mapy Morza Bałtyckiego. Największa głębia to:",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 28,
                            "points": 0,
                            "text": "Głębia Gdańska"
                        },
                        {
                            "id": 29,
                            "points": 2,
                            "text": "Głębia Landsort"
                        },
                        {
                            "id": 30,
                            "points": 0,
                            "text": "Głębia Botnicka"
                        },
                        {
                            "id": 31,
                            "points": 0,
                            "text": "Głębia Alandzka"
                        }
                    ]
                }
            },
            {
                "id": 25,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 8,
                    "title": "Środowisko Morza Bałtyckiego",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6604437111.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6604437111.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 11,
                    "title": "",
                    "text": "Zawartość chlorofilu w sinicach spowodowała, że przez długi czas były opisywane jako glony. Obecnie klasyfikuje się je jako cyjanobakterie. To one powodują zakwit wód i uniemożliwiają letnie kąpiele turystom.\r\n",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 7,
                    "type": "text_answers",
                    "title": "",
                    "question": "Wytęż wzrok! Zajrzyj przez lupy. Jakie organizmy widzisz na fotografii?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 32,
                            "points": 0,
                            "text": "Bruzdnice"
                        },
                        {
                            "id": 33,
                            "points": 0,
                            "text": "Okrzemki"
                        },
                        {
                            "id": 34,
                            "points": 2,
                            "text": "Sinice"
                        },
                        {
                            "id": 35,
                            "points": 0,
                            "text": "Zielenice"
                        }
                    ]
                }
            },
            {
                "id": 26,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 8,
                    "title": "Środowisko Morza Bałtyckiego",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6604437111.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6604437111.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "quizScreen": {
                    "id": 8,
                    "type": "text_answers",
                    "title": "",
                    "question": "W której części Bałtyku występują rekiny?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 36,
                            "points": 0,
                            "text": "Wschodniej"
                        },
                        {
                            "id": 37,
                            "points": 2,
                            "text": "Zachodniej"
                        },
                        {
                            "id": 38,
                            "points": 0,
                            "text": "Północnej"
                        },
                        {
                            "id": 39,
                            "points": 0,
                            "text": "Południowej"
                        }
                    ]
                }
            },
            {
                "id": 27,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen": {
                    "id": 6,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": null,
                    "text_1": "Przejdź piętro niżej, ",
                    "current_room": "do Sali Na Styku Wody i Lądu",
                    "text_2": "",
                    "next_room": "",
                    "animation_position": null,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcfa2d820a1.jpeg"
                }
            },
            {
                "id": 20,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 0,
                "language_btn_visible": 0,
                "screen_type": "game",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "curiosityPopup": {
                    "id": 17,
                    "title": "",
                    "text": "Jaskinia Jaskółek (Sótano de las Golondrinas) znajduje się w Meksyku. Jej głębokość sięga 333 m, a wlot ma średnicę 60 m. To jedna z największych jaskiń studniowych na świecie. Jest tak ogromna, że nawet średniej wielkości balon mógłby swobodnie wlecieć do środka i osiąść na dnie. ",
                    "youtube_link": ""
                },
                "gameScreen": {
                    "id": 2,
                    "name": "Jaskinia jaskółek",
                    "type": "scroll_game",
                    "points": 1,
                    "title": "Jaskinia jaskółek",
                    "description": "Doleć na dno jaskini, w której żyją ślepczyki.",
                    "graphicPath": "uploads/game-screen-graphic/5dcd325aa164b.jpeg"
                }
            },
            {
                "id": 28,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen": {
                    "id": 7,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": null,
                    "text_1": "Przejdź piętro niżej, ",
                    "current_room": "do Sali Wodne Zwierzęta Świata",
                    "text_2": "",
                    "next_room": "",
                    "animation_position": null,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcfa56ad147.jpeg"
                }
            },
            {
                "id": 29,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 10,
                    "title": "Wodne Zwierzęta Świata",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 19,
                    "title": "",
                    "text": "Ryby pojawiające się na rafie nocą wyróżniają się zwykle większymi oczami i czerwonym ubarwieniem. Taki wygląd przybrały hajduki, nazywane „rybami-żołnierzami\".",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 9,
                    "type": "text_answers",
                    "title": "",
                    "question": "Znajdź rybę ze zdjęcia. Dlaczego  ma duże oczy?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "graphicPath": "uploads/quiz-screen-graphic/5dd7ef9ec0b5b.jpeg",
                    "quizAnswers": [
                        {
                            "id": 40,
                            "points": 0,
                            "text": "Przestraszyła się"
                        },
                        {
                            "id": 41,
                            "points": 2,
                            "text": "Poluje nocą"
                        },
                        {
                            "id": 42,
                            "points": 0,
                            "text": "Żyje na dużych głębokościach"
                        },
                        {
                            "id": 43,
                            "points": 0,
                            "text": "Żeby Cię lepiej widzieć"
                        }
                    ]
                }
            },
            {
                "id": 30,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 10,
                    "title": "Wodne Zwierzęta Świata",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 20,
                    "title": "",
                    "text": "Rekin bambusowy jest bardzo wytrzymały, ponieważ może przetrwać nawet 12 godzin poza wodą.",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 10,
                    "type": "text_answers",
                    "title": "",
                    "question": "Czy w zbiorniku nr (…) mieszka rekin?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 44,
                            "points": 1,
                            "text": "TAK"
                        },
                        {
                            "id": 45,
                            "points": 0,
                            "text": "NIE"
                        }
                    ]
                }
            },
            {
                "id": 31,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 10,
                    "title": "Wodne Zwierzęta Świata",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd6614a45fba.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 22,
                    "title": "",
                    "text": "Czy zwykła woda może być antidotum na jad najgroźniejszej ryby na świecie? Tak! Jeśli jest gorąca. Zanim przybędzie lekarz z odtrutką, neurotoksynę wstrzykniętą przez szkaradnicę, można zneutralizować, zanurzając ranę w wodzie o temperaturze powyżej 45°C. ",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 12,
                    "type": "text_answers",
                    "title": "",
                    "question": "Dlaczego ryby kamień są groźne dla człowieka?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 47,
                            "points": 2,
                            "text": "Mają kolce jadowe"
                        },
                        {
                            "id": 48,
                            "points": 0,
                            "text": "Są ciężkie jak kamień"
                        },
                        {
                            "id": 49,
                            "points": 0,
                            "text": "Mają ostre zęby"
                        },
                        {
                            "id": 50,
                            "points": 0,
                            "text": "Są pokryte trującym śluzem"
                        }
                    ]
                }
            },

            {
                "id": 32,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen": {
                    "id": 8,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": null,
                    "text_1": "Przejdź do ",
                    "current_room": "Sali Rafa Koralowa.",
                    "text_2": "",
                    "next_room": "",
                    "animation_position": null,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcfa90cadd3.jpeg"
                }
            },
            {
                "id": 34,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 7,
                    "title": "Rafa Koralowa",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 25,
                    "title": "",
                    "text": "Około 60 gatunków wężowideł jest bioluminescencyjnych, co oznacza, że wytwarzają światło. Służy im ono prawdopodobnie do odstraszania drapieżników. ",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 13,
                    "type": "text_answers",
                    "title": "",
                    "question": "Ile ramion ma wężowidło?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 51,
                            "points": 0,
                            "text": "2"
                        },
                        {
                            "id": 52,
                            "points": 2,
                            "text": "5"
                        },
                        {
                            "id": 53,
                            "points": 0,
                            "text": "7"
                        },
                        {
                            "id": 54,
                            "points": 0,
                            "text": "10"
                        }
                    ]
                }
            },
            {
                "id": 35,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 7,
                    "title": "Rafa Koralowa",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 26,
                    "title": "",
                    "text": "Oczy koników morskich działają podobnie jak u kameleona. Każde z nich porusza się niezależnie, co oznacza, że ryba ta jednocześnie może patrzeć w przód i w tył.",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 14,
                    "type": "text_answers",
                    "title": "",
                    "question": "Który opis konika morskiego jest prawdziwy?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 55,
                            "points": 3,
                            "text": "słabo pływa, ma chwytny ogonek, samiec ma torbę lęgową"
                        },
                        {
                            "id": 56,
                            "points": 0,
                            "text": "świetnie pływa, ma chwytny ogonek, samica ma torbę lęgową"
                        },
                        {
                            "id": 57,
                            "points": 0,
                            "text": "słabo pływa, ma chwytny ogonek, jaja składa w trawie morskiej"
                        }
                    ]
                }
            },
            {
                "id": 36,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 7,
                    "title": "Rafa Koralowa",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 27,
                    "title": "",
                    "text": "Operacja zmiany płci bez skalpela i to w trzy dni-czy to możliwe? Tak, jeśli jesteś papużakiem! Kiedy w haremie ginie samiec, jego rolę przejmuje najstarsza samica. W kilka dni przechodzi niezwykłą metamorfozę, zmieniając swój wygląd, jak i orientację seksualną.",
                    "youtube_link": "",
                    "graphicPath": "uploads/lose-popup-graphic/5dde76805ded2.jpeg"
                },
                "quizScreen": {
                    "id": 15,
                    "type": "text_answers",
                    "title": "",
                    "question": "Papużaki żyją w haremach. Co się dzieje, gdy zabraknie samca - opiekuna grupy?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 58,
                            "points": 0,
                            "text": "nic"
                        },
                        {
                            "id": 59,
                            "points": 0,
                            "text": "samice dołączają do innych haremów"
                        },
                        {
                            "id": 60,
                            "points": 0,
                            "text": "pozostałe osobniki umierają"
                        },
                        {
                            "id": 61,
                            "points": 2,
                            "text": "najsilniejsza z samic zmienia płeć"
                        }
                    ]
                }
            },
            {
                "id": 46,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 7,
                    "title": "Rafa Koralowa",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 28,
                    "title": "",
                    "text": "Czy można widzieć, nie mając oczu? Tak i to całym ciałem. Igielnik, jak inne jeżowce, posiada fotoreceptory, za pomocą których reaguje na światło.\r\nNa spodniej stronie ciała ma pięć zębów, którymi zeskrobuje drobne glony.",
                    "youtube_link": "",
                    "graphicPath": "uploads/lose-popup-graphic/5dde76acabf49.jpeg"
                },
                "quizScreen": {
                    "id": 19,
                    "type": "text_answers",
                    "title": "",
                    "question": "Co pokazuje strzałka?",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "graphicPath": "uploads/quiz-screen-graphic/5dd7f1c3e4e13.jpeg",
                    "quizAnswers": [
                        {
                            "id": 76,
                            "points": 0,
                            "text": "Oko"
                        },
                        {
                            "id": 77,
                            "points": 0,
                            "text": "Otwór gębowy"
                        },
                        {
                            "id": 78,
                            "points": 2,
                            "text": "Otwór odbytowy"
                        },
                        {
                            "id": 79,
                            "points": 0,
                            "text": "Otwór oddechowy"
                        }
                    ]
                }
            },
            {
                "id": 42,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 7,
                    "title": "Rafa Koralowa",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd65fcc1b209.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 30,
                    "title": "",
                    "text": "Ciała błazenków wytwarzają odporność na parzące właściwości ukwiałów, które są ich domem. W zamian za schronienie ryby wabią ofiary swoim gospodarzom.\r\n Dory, podobnie jak jej filmowy przyjaciel Nemo, ma swój pierwowzór w naturze, a jest nim pokolec królewski. ",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 16,
                    "type": "text_answers",
                    "title": "",
                    "question": "Gdzie jest Nemo? Wskaż numer zbiornika, w którym mieszka błazenek.",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 62,
                            "points": 0,
                            "text": "602"
                        },
                        {
                            "id": 63,
                            "points": 0,
                            "text": "609"
                        },
                        {
                            "id": 64,
                            "points": 0,
                            "text": "610"
                        },
                        {
                            "id": 65,
                            "points": 2,
                            "text": "611"
                        }
                    ]
                }
            },
            {
                "id": 38,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "curiosityPopup": {
                    "id": 31,
                    "title": "",
                    "text": "Amazonka przepływa przez 3 państwa w Ameryce Południowej: \r\nPeru, Brazylię i Kolumbię. Jest główną drogą komunikacyjną w tej części kontynentu.\r\n",
                    "youtube_link": "",
                    "graphicPath": "uploads/lose-popup-graphic/5dde76d791210.jpeg"
                },
                "navigationScreen": {
                    "id": 9,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": null,
                    "text_1": "Przejdź piętro niżej, ",
                    "current_room": "do Sali Amazonii.",
                    "text_2": "",
                    "next_room": "",
                    "animation_position": null,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcfaba3ad4c.jpeg"
                }
            },
            {
                "id": 47,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 1,
                "progress_bar_visible": 1,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "quiz",
                "screenCategory": {
                    "id": 1,
                    "title": "Amazonia",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656d1794ba.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dd656d1794ba.jpeg",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 32,
                    "title": "",
                    "text": "Latem 2014 roku w Akwarium Gdyńskim urodziło się 16 płaszczek rzecznych. Część z nich zamieszkuje ten zbiornik. Pozostałe nowy dom znalazły w polskich ogrodach zoologicznych.",
                    "youtube_link": ""
                },
                "quizScreen": {
                    "id": 20,
                    "type": "graphic_answers",
                    "title": "",
                    "question": "Wybierz zwierzę spokrewnione z rekinem.",
                    "start_btn_text": null,
                    "confirm_btn_text": null,
                    "quizAnswers": [
                        {
                            "id": 80,
                            "points": 0,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f2a84ba48.jpeg"
                        },
                        {
                            "id": 81,
                            "points": 0,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f2c567998.jpeg"
                        },
                        {
                            "id": 82,
                            "points": 1,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f2d83290f.jpeg"
                        },
                        {
                            "id": 83,
                            "points": 0,
                            "graphicPath": "uploads/quiz-answer-graphic/5dd7f2fc320f1.jpeg"
                        }
                    ]
                }
            },
            {
                "id": 39,
                "limit_time": 90,
                "text_to_display_on_start_time_screen": "Wciel się w anakondę i pokonaj labirynt. Przesuwaj palcem w odpowiednim kierunku, aby wąż się przemieścił.",
                "points_counter_visible": 1,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "game",
                "winPopup": {
                    "id": 1,
                    "name": "Okienko Wygranej",
                    "text_1": "Brawo! ",
                    "text_after_counter_1": "punkt dla Ciebie!",
                    "text_after_counter_2_4": "punkty dla Ciebie!",
                    "text_after_counter_5": "punktów dla Ciebie!",
                    "graphicPath": "uploads/win-popup-graphic/5dcd3335f1614.jpeg"
                },
                "losePopup": {
                    "id": 1,
                    "name": "Okienko Przegranej",
                    "text_1": "Kontynuuj przygodę!",
                    "text_2": "punktów",
                    "graphicPath": "uploads/lose-popup-graphic/5dcd33e99ff1a.jpeg"
                },
                "curiosityPopup": {
                    "id": 34,
                    "title": "",
                    "text": "Anakondy zazwyczaj polują w nocy. Przytrzymują swoją ofiarę pyskiem i owijają się wokół niej, zaciskając splot przy każdym wydechu pochwyconego zwierzęcia. Podstawowym pokarmem anakondy są kapibary – największe gryzonie świata.",
                    "youtube_link": "",
                    "graphicPath": "uploads/lose-popup-graphic/5dde771e7b156.jpeg"
                },
                "gameScreen": {
                    "id": 4,
                    "name": "Labirynt na czas",
                    "type": "labyrinth_game",
                    "points": 2,
                    "title": "",
                    "description": ""
                }
            },



            {
                "id": 51,
                "limit_time": 0,
                "text_to_display_on_start_time_screen": "",
                "points_counter_visible": 0,
                "progress_bar_visible": 0,
                "map_btn_visible": 1,
                "language_btn_visible": 0,
                "screen_type": "navigation",
                "screenCategory": {
                    "id": 3,
                    "title": "",
                    "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg"
                },
                "bgGraphicPath": "uploads/screen-category-bg-graphic/5dc58826bc8ff.jpeg",
                "navigationScreen": {
                    "id": 10,
                    "display_graphic_in_round_frame": 0,
                    "start_animate_position": 0,
                    "end_animate_position": 0,
                    "points": null,
                    "text_1": "",
                    "current_room": "Wyjście z budynku ",
                    "text_2": "",
                    "next_room": "",
                    "animation_position": null,
                    "bgGraphicPath": "uploads/navigation-screen-bg-graphic/5ddcfaec4c72a.jpeg"
                }
            },

        ]
    }
};

export default API;
