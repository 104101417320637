import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../config/globalStyles.scss';
import './pointsSummaryScreen.scss';
import { getPluralForm } from '../../config/helpers';
import { commonImages } from '../../config/images';
import { changeScreen } from '../../store/actions';
import { getGlobalConfig, selectTotalEarnedPoints } from '../../store/reducers/mainReducer';
import Text from "../TextContainer/Text.js";
import audio from '../../config/audio';

const PointsSummaryScreen = ({ data }) => {
    const dispatch = useDispatch();

    const { pointsSummaryScreen } = data;
    const globalConfig = useSelector(getGlobalConfig);
    const totalEarnedPoints = useSelector(selectTotalEarnedPoints);

    const {
        text_1,
        text_2,
        check_animal_text,
        check_animal_btn_text,
        last_game_text,
        last_game_btn_text,
    } = pointsSummaryScreen;

    const { points_1, points_2_4, points_5 } = globalConfig;

    const {
        elementHorseWinner,
        elementChestSummary
    } = commonImages;

    return (
        <div className="screenContentContainer pointsSummaryScreen">
            <h2 className="colorDark summaryHeader"><Text>{text_1}</Text></h2>
            <div className="seahorseChestContainer">
                <p className="colorDark chestInfoText"><Text>{text_2}</Text></p>
                <div className="seahorseChestImageContainer">
                    <img
                        className="seahorseImage"
                        src={elementHorseWinner}
                        alt=""
                    />
                    <img
                        className="chestImage"
                        src={elementChestSummary}
                        alt=""
                    />

                </div>
                <h3 className="pointsCount"><Text>{totalEarnedPoints}</Text></h3>
                <h3 className="pointsText">
                    <Text>{getPluralForm(
                        totalEarnedPoints,
                        'pl',
                        points_1,
                        points_2_4,
                        points_5,
                    )}</Text>
                </h3>
            </div>
            <div className="buttonsSection">
                <div className="pointsSummaryEnd">
                    <p className="colorDark"><Text>{check_animal_text}</Text></p>
                    <div
                        className="button checkAnimalButton"
                        onClick={() => {
                            audio.play("click");
                            dispatch(changeScreen);
                            dispatch(changeScreen);
                        }}
                    >
                        <h3><Text>{check_animal_btn_text}</Text></h3>
                    </div>
                </div>
                <div className="pointsSummaryPlay">
                    <p className="colorDark"><Text>{last_game_text}</Text></p>
                    <div
                        className="button continueButton"
                        onClick={() => {
                            audio.play("click");
                            dispatch(changeScreen)
                        }
                        }>
                        <h3 className="colorDark"><Text>{last_game_btn_text}</Text></h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PointsSummaryScreen;
