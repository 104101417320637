import React from 'react';
import { useSelector } from 'react-redux';

import '../../config/globalStyles.scss';
import './startTimeScreen.scss';
import { getGlobalConfig } from '../../store/reducers/mainReducer';
import { commonImages } from '../../config/images';
import PlayButton from '../InfoScreen/elements/PlayButton';
import Text from '../TextContainer/Text';

const StartTimeScreen = ({ data }) => {
    const { text_to_display_on_start_time_screen } = data;
    const globalConfig = useSelector(getGlobalConfig);

    const {
        limit_time_start_text_1,
        limit_time_start_text_2,
        limit_time_start_text_3,
    } = globalConfig;

    const description = text_to_display_on_start_time_screen || '';

    return (
        <div className="screenContentContainer startGameContainer">
            <div>
                <img src={commonImages.elementStopwatch} alt="" className="stopwatch" />
            </div>
            <div className="text">
                <h2><Text>{limit_time_start_text_1}</Text></h2>
                <h2><Text>{limit_time_start_text_2}</Text></h2>
            </div>
            <div>
                {description.length <= 60 ? (
                    <h4><Text>{description}</Text></h4>
                ) : (
                        <p><Text>{description}</Text></p>
                    )}
            </div>
            <div>
                <p className="startGameInfo"><Text>{limit_time_start_text_3}</Text></p>
            </div>
            <PlayButton
                backgroundStyle={{
                    height: 'calc(var(--vh, 1vh) * 25)',
                    width: 'calc(var(--vh, 1vh) * 25)',
                }}
                textStyle={{
                    fontSize: '3.2em',
                }}
            />
        </div>
    );
};

export default StartTimeScreen;
