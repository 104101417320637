import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import '../../config/globalStyles.scss';
import './navigationScreen.scss';
import { navigationImages, commonImages } from "../../config/images"
import { getImagePath, select } from "../../config/helpers";
import { addPointsForScreen, changeScreen } from "../../store/actions";
import { getCurrentScreenIndex } from '../../store/reducers/mainReducer';
import Text from '../TextContainer/Text';

const NavigationScreenContent = ({ active, endGame, data }) => {
    const dispatch = useDispatch();

    const currentScreenIndex = useSelector(getCurrentScreenIndex);

    const [animationPosition, setAnimationPosition] = useState({
        left: '50%',
        top: '50%',
    });

    const {
        animation_position,
        text_1,
        current_room,
        text_2,
        next_room,
        points,
        bgGraphicPath,
        display_graphic_in_round_frame
    } = data.navigationScreen;

    useEffect(() => {
        if (active && points > 0) {
            dispatch(addPointsForScreen(points, currentScreenIndex));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreenIndex]);

    useEffect(() => {
        setAnimationPosition(animation_position !== null ? select(animation_position - 1, [
            {left: 0, top: 0},
            {left: 50, top: 0},
            {left: 0, top: 33},
            {left: 50, top: 33},
            {left: 0, top: 67},
            {left: 50, top: 67},
        ]) : {left: 1000, top: 1000});
    }, [animation_position]);


    const clickHandler = e => {
        endGame();
        dispatch(changeScreen);
    }
    let image;

    if (display_graphic_in_round_frame) {
        image = <div className="roundGraphicContainer" onClick={clickHandler}>
            <img src={navigationImages.porthole} className="roundGraphicBorder" alt="" />
            <img src={getImagePath(bgGraphicPath)} className="roundGraphicImage" alt="" />
        </div>
    } else {
        image = <div className="graphicContainer" onClick={clickHandler}>
            <img src={getImagePath(bgGraphicPath)} alt={''} className="graphicContainer__image" />
            <div
                className="animationsContainer"
                style={{
                    left: `${animationPosition.left}%`,
                    top: `${animationPosition.top}%`,
                }}>
                <img
                    src={commonImages.elementHorseWinner}
                    alt={''}
                    className="animationsContainer__image"
                />
            </div>
        </div>
    }

    const textColor = !display_graphic_in_round_frame ? "colorDark" : null;

    const text = <div className="textContainer">
        <p className={textColor}><Text>{text_1}</Text></p>
        <h3 className={textColor}><Text>{current_room}</Text></h3>
        <p className={textColor}><Text>{text_2}</Text></p>
        <h3 className={textColor}><Text>{next_room}</Text></h3>
    </div>;



    let content = display_graphic_in_round_frame ? <>{image}{text}</> : <>{text}{image}</>;

    return <div className="screenContentContainer navigationScreen">{content}</div>;
};

export default NavigationScreenContent;
