import React from 'react';

import '../../config/globalStyles.scss';
import './swipeQuizScreen.scss';
import { SwipeQuizScreenContent } from './SwipeQuizScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

const SwipeQuizScreen = ({ active, data, index }) => <WithTimerScreen
    active={active}
    data={data}
    index={index}
    gameContent={endGame => <SwipeQuizScreenContent data={data} index={index} endGame={endGame} />}
/>


export default SwipeQuizScreen;