import React from 'react';

import CanvasEnvironment from '../commonElements/CanvasEnvironment';
import WhaleGameEngine from './WhaleGameEngine';
import WithTimerScreen from '../../WithTimerScreen/WithTimerScreen';

const WhaleGameScreen = ({ active, data }) => <WithTimerScreen
    active={active}
    data={data}
    gameContent={endGame => (
        <CanvasEnvironment
            active={active}
            data={data}
            Engine={WhaleGameEngine}
            canvasWidthModifier={1}
            canvasHeightModifier={1}
            endGame={endGame}
            isTimeout={false}
        />
    )}
    timeOutContent={<CanvasEnvironment
        active={active}
        data={data}
        Engine={WhaleGameEngine}
        canvasWidthModifier={1}
        canvasHeightModifier={1}
        isTimeout={true}
    />}
    winContent={<CanvasEnvironment
        active={active}
        data={data}
        Engine={WhaleGameEngine}
        canvasWidthModifier={1}
        canvasHeightModifier={1}
        isTimeout={true}
    />}
/>



export default WhaleGameScreen;
