import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch } from "react-redux";

import { getImagePath } from '../../../config/helpers';
import { changeScreen, addPointsForScreen } from '../../../store/actions';

import "./scrollGame.scss";
import Text from '../../TextContainer/Text';

function Game({ active, endGame, isWon, data, index }) {
    const dispatch = useDispatch();

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const { gameScreen } = data
    const { graphicPath, flightGraphicPath = "uploads/img_balloon.png", title, description, points } = gameScreen;

    const [initial, setInitial] = useState(null);

    const requestRef = useRef(null);
    const balloonRef = useRef(null);
    const differenceRef = useRef(null);
    const heightRef = useRef(0);
    const balloonHeightRef = useRef(0);
    const viewportHeightRef = useRef(window.innerHeight);
    const imageHeight = window.innerWidth * 15;
    const caveRef = useRef(null);

    const modifier = x => x < 80 ? 1.5 : -0.09 * x + 8.5

    const animate = useCallback(() => {
        let isFinished = false;
        const balloonStyle = window.getComputedStyle(balloonRef.current);

        const balloonSize = parseInt(balloonStyle.height);

        const lakePosition = imageHeight * 0.996;
        if (differenceRef.current !== null) {
            const percentage = (heightRef.current + balloonHeightRef.current) / imageHeight * -100;
            let difference = (differenceRef.current) / 50 * modifier(percentage);

            if (difference < 0) {
                difference = 0;
            }

            if (difference - heightRef.current <= imageHeight - viewportHeightRef.current) {
                heightRef.current -= difference;
            }
            else {
                const absoluteHeight = heightRef.current;

                if (-balloonHeightRef.current + balloonSize - absoluteHeight + viewportHeightRef.current / 2 < lakePosition) {
                    balloonHeightRef.current -= difference
                }
                else {
                    isFinished = true;
                    dispatch(addPointsForScreen(points, index));
                    endGame();
                }
            }
        }
        else {
            if (balloonHeightRef.current + heightRef.current >= viewportHeightRef.current - imageHeight) {
                heightRef.current += heightRef.current < 0 ? 1 : 0;

            }
            else {
                balloonHeightRef.current += balloonHeightRef.current < 0 ? 1 : 0;
            }
        }

        forceUpdate();
        if (!isFinished) {
            requestRef.current = requestAnimationFrame(animate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endGame]);

    useEffect(() => {
        if (active) {
            if (!isWon) {
                if (requestRef.current === null) {
                    requestRef.current = requestAnimationFrame(animate);
                    return () => {
                        cancelAnimationFrame(requestRef.current);
                        requestRef.current = null;
                    }
                }
            } else {
                const id = setTimeout(() => {
                    dispatch(changeScreen);
                }, 2000)
                return () => clearTimeout(id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWon, active]);

    useEffect(() => {
        const eventHandler = e => {
            viewportHeightRef.current = window.innerHeight;
        };

        window.oncontextmenu = function(event) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        };

        window.addEventListener("resize", eventHandler);
        return () => {
            window.removeEventListener("resize", eventHandler);
            window.oncontextmenu = ()=>{}
        }
    }, []);

    return (
        <div className="scrollGameScreen">
            <div className="scrollGameText" style={{ transform: `translateY(${heightRef.current * 2}px)` }}>
                <h2><Text>{title}</Text></h2>
                <h4><Text>{description}</Text></h4>
            </div>
            <img className="scrollGameBalloon" ref={balloonRef} src={getImagePath(flightGraphicPath)} style={{ transform: `translateY(calc(${-balloonHeightRef.current}px - 50%))` }} alt=""
                onTouchStart={e => {
                    setInitial(e.touches[0].clientY);
                }}
                onTouchMove={e => {
                    differenceRef.current = e.touches[0].clientY - initial;
                }}
                onTouchEnd={e => {
                    differenceRef.current = null;
                    setInitial(null)
                }} />
            <img className="scrollGameCave" ref={caveRef} src={getImagePath(graphicPath)} style={{ transform: `translateY(${heightRef.current}px)` }} alt="" />
        </div>
    )
}

export default Game
