export const tutorialScreenData = {
    "id": 999,
    "screen_type": "tutorial",
    "screenCategory": {
        "id": 19,
        //"bgGraphicPath": "uploads/screen-category-bg-graphic/5e4a5a0423270.png"
        "bgGraphicPath": "static/media/bg_curiosity_screen.21fb8781.png"
    },
    "tutorialScreen": {
        "id": 999,
        "defaultVideoPath":"/movies/tutorialMovie.webm",
        "videoPathLocalized":{"pl-PL":"/movies/tutorialMovie.webm","en-US":"/movies/tutorialMovieAng.webm"},
    }
};
