import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import '../../../config/globalStyles.scss';
import './checkboxScreen.scss';

import CustomCheckbox from '../elements/CustomCheckbox.js';
import { getImagePath } from '../../../config/helpers';
import { changeScreen } from '../../../store/actions';
import { setSwipeAvailable } from '../../../store/actions/mainActions';
import Text from "../../TextContainer/Text";

const CheckboxScreen = ({ active, data }) => {
    const dispatch = useDispatch();

    const { infoScreen, screenLogotypesData = [] } = data;
    const { title, text_1, text_2, required_checkbox_text, pdfFilePath } = infoScreen;

    const [checked, setChecked] = useState(false);

    const logotypes = screenLogotypesData.slice();

    const firstLogotype = logotypes.shift();

    useEffect(() => {
        if (active) {
            if (checked) {
                dispatch(changeScreen);
                dispatch(setSwipeAvailable(true));
            } else {
                dispatch(setSwipeAvailable(false));
            }
        }
    }, [active, checked, dispatch]);

    return (
        <div className="screenContentContainer checkboxScreen">
            {firstLogotype && <img
                src={getImagePath(firstLogotype.graphicPath)}
                alt={firstLogotype.name}
                className="logotype"
            />}
            <div className="headerContainer">
                <h2 className="colorDark"><Text>{title}</Text></h2>
            </div>
            <div
                className="checkboxContainer"
                onClick={() => setChecked(true)}>
                <CustomCheckbox isChecked={checked} />
                <p className="colorDark"><Text>{required_checkbox_text}</Text></p>
            </div>
            <div className="regulationContainer">
                <p className="colorDark">
                    <a className="regulationLink colorDark" href={getImagePath(pdfFilePath)}><Text>{text_2}</Text></a>
                </p>
            </div>
            <div className="logotypesContainer">
                {logotypes.map(logotype => <img
                    src={getImagePath(logotype.graphicPath)}
                    alt={logotype.name}
                    className="logotype"
                    key={logotype.name}
                />
                )}
            </div>
            <div className="footerContainer">
                <p className="label colorDark"><Text>{text_1}</Text></p>
            </div>
        </div>
    );
};

export default CheckboxScreen;
