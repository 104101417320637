import React, { useEffect, useState } from 'react';

import './winLoseScreen.scss';
import { commonImages } from '../../config/images';
import { useSelector } from 'react-redux';
import { selectCurrentScreenData, getWinPopupData } from '../../store/reducers/mainReducer';
import Text from '../TextContainer/Text';
import audio from '../../config/audio';

const WinScreen = ({ active }) => {
    const currentScreenData = useSelector(selectCurrentScreenData) || {};
    const winPopup = useSelector(getWinPopupData) || {};
    const { earnedPoints } = currentScreenData;

    const [points, setPoints] = useState(earnedPoints);

    useEffect(() => {
        if (earnedPoints) {
            setPoints(earnedPoints);
            if (earnedPoints < 1) {
                return () => setTimeout(() => setPoints(earnedPoints), 200)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [earnedPoints])

    const {
        text_1: chatText,
        text_after_counter_1: infoText_1,
        text_after_counter_2_4: infoText_2_4,
        text_after_counter_5: infoText_5
    } = winPopup;

    const chooseInfoText = points => {
        if (points >= 5) {
            return infoText_5 || infoText_2_4 || infoText_1;
        } else if (points >= 2) {
            return infoText_2_4 || infoText_1;
        } else {
            return infoText_1;
        }
    };

    useEffect(() => {
        if(active) {
            const id = setTimeout(() => {
                audio.play("coins");
            }, 2000 / 3);
            return () => clearTimeout(id);
        }
    }, [active])

    return <div className="winLoseScreen">
        <div className="seahorseContainer">
            <img
                src={commonImages.elementHorseWinner}
                alt=""
                className="seahorseImage"
            />
            <img src={commonImages.elementChat} alt="" className="chatImage" />
            <h2 className="chatText"><Text>{chatText}</Text></h2>
        </div>
        <div className="coinsContainer">
            <img src={commonImages.elementChest} alt="" className="chestImage" />
            {active ? <img src={commonImages.iconCoin} alt="" className="coinImage" /> : null}
        </div>
        <div className="infoTextContainer">
            <p className="colorDark points">{points && <Text>{parseInt(points)}</Text>}</p>
            <h3 className="infoText"><Text>{chooseInfoText(points)}</Text></h3>
        </div>
    </div>
};

export default WinScreen;
