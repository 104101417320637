import { store } from '../../../store/store'
import InputHandler from '../commonElements/InputHandler';
import { setBoundaries, detectCollisions } from '../commonElements/CollisionDetector';
import { MovingObject, StaticObject } from '../commonElements/BasicObjectClasses';
import { commonImages } from '../../../config/images';
import audio from "../../../config/audio";


function WhaleGameEngine({
    canvas,
    setEnd,
    setScore,
    timeLimit,
    maxPoints,
    addPoints
}) {
    const ctx = canvas.getContext('2d', { alpha: false });

    const initialShrimpSpeed = Math.floor(Math.max(canvas.width / 100, 4));
    const objectInitialSize = Math.floor(Math.max(canvas.width / 5, 100));

    let finished = false;
    let counter = 0;
    let shrimpsCount = 0;
    let shrimps = [];
    let touchPointY = canvas.height / 2
    let timeStamp = 0;

    let escaped = 0;
    let eaten = 0;

    const interactiveObjectsArray = [];

    const backgroundImage = new Image();
    backgroundImage.src = commonImages.backgroundWhaleGame;

    const whaleImage = new Image();
    whaleImage.src = commonImages.elementWhale;

    const shrimpImage = new Image();
    shrimpImage.src = commonImages.elementShrimp;

    //Fix blur
    const dpi = window.devicePixelRatio;
    const style_height = parseInt(getComputedStyle(canvas).getPropertyValue('height'));
    const style_width = parseInt(getComputedStyle(canvas).getPropertyValue('width'));

    const fixBlur = () => {
        canvas.setAttribute('height', style_height * dpi);
        canvas.setAttribute('width', style_width * dpi);
    };

    const endgame = () => {
        const ratio = eaten / (eaten + escaped);
        const points = Math.round(maxPoints * ratio);
        addPoints(points);
        setEnd(true);
        setTimeout(() => {
            finished = true;
        }, 2000);
    }

    const timeCheck = () => {
        if (timeLimit > 1) {
            setTimeout(timeCheck, 1000)
            if (!store.getState().main.pause) {
                timeLimit--;
            }
        }
        else {
            endgame();
        }
    }
    this.startGame = () => {
        setTimeout(timeCheck, 1000);
        requestAnimationFrame(gameLoop);
    }
    // Create interactive objects and add them to array
    let whale = new MovingObject({
        //image: whaleImage,
        position: {
            x: canvas.width - objectInitialSize * 0.8,
            y: canvas.height / 2,
        },
        size: {
            width: objectInitialSize * 0.8,
            height: objectInitialSize,
        },
        isVisible: true,
        velocity: { x: 0, y: 0 },
        acceleration: { x: 0, y: 0 },
    });

    const tapableArea = new StaticObject({
        position: {
            x: 0,
            y: 0
        },
        size: {
            width: canvas.width,
            height: canvas.height
        },
        isVisible: false,
        onTouch() {
            touchPointY = this.touchPoint.y;
        },
        onTouchMove() {
            touchPointY = this.touchPoint.y;
        }
    });

    interactiveObjectsArray.push(tapableArea);

    // Handle press input
    InputHandler(canvas, interactiveObjectsArray);

    // Draw images

    let drawBackgroundImage = () => { };
    backgroundImage.addEventListener(
        'load',
        () => {
            drawBackgroundImage = () => {
                ctx.drawImage(
                    backgroundImage,
                    0,
                    0,
                    canvas.width / dpi,
                    canvas.height / dpi,
                );
            };
            drawBackgroundImage();
        },
        false,
    );

    let drawWhaleImage = () => { };
    whaleImage.addEventListener('load', () => {
        drawWhaleImage = (positionX, positionY, width, height) => {
            ctx.drawImage(
                whaleImage,
                positionX - objectInitialSize * 0.3,
                positionY,
                width + objectInitialSize * 0.3,
                height,
            );
        };
    });

    let drawShrimpImage = () => { };
    shrimpImage.addEventListener('load', () => {
        drawShrimpImage = (positionX, positionY, width, height) => {
            ctx.drawImage(shrimpImage, positionX, positionY, width, height);
        };
    });

    // === GAME LOOP ===


    const gameLoop = () => {
        if (!store.getState().main.pause) {
            // Clearing phase
            fixBlur();
            ctx.scale(dpi, dpi);
            ctx.clearRect(0, 0, canvas.width / dpi, canvas.height / dpi);

            whale.velocity.y = (touchPointY - whale.position.y - objectInitialSize / 2) * 0.8;
            whale.calculateNewPosition();


            // Game logic phase
            counter++;

            if (Date.now() - 500 > timeStamp) {
                shrimps[shrimpsCount] = new MovingObject({
                    position: {
                        x: objectInitialSize * -0.5,
                        y:
                            Math.random() *
                            (canvas.height / dpi - (canvas.height * 0.4) / dpi) +
                            (canvas.height * 0.2) / dpi,
                    },
                    size: {
                        width: objectInitialSize * 0.9,
                        height: objectInitialSize * 0.3,
                    },
                    isVisible: true,
                    collisionType: 'DISAPPEAR',
                    velocity: { x: initialShrimpSpeed, y: 0 },
                    acceleration: { x: 0, y: 0 },
                });
                shrimpsCount++;
                timeStamp = Date.now();
            }


            if (counter % 20 === 0) {
                shrimps.forEach(shrimp => {
                    const actionChance = Math.random() * 100;
                    if (actionChance > 80) {
                        if (shrimp.velocity.y > 0) {
                            shrimp.setVelocityDirectly({
                                x: initialShrimpSpeed,
                                y: -initialShrimpSpeed / 2,
                            });
                        } else if (shrimp.velocity.y < 0) {
                            shrimp.setVelocityDirectly({
                                x: initialShrimpSpeed,
                                y: initialShrimpSpeed / 2,
                            });
                        } else if (actionChance > 90) {
                            shrimp.setVelocityDirectly({
                                x: initialShrimpSpeed,
                                y: initialShrimpSpeed / 2,
                            });
                        } else {
                            shrimp.setVelocityDirectly({
                                x: initialShrimpSpeed,
                                y: -initialShrimpSpeed / 2,
                            });
                        }
                    } else if (actionChance > 70) {
                        shrimp.setVelocityDirectly({ x: 2 * initialShrimpSpeed, y: 0 });
                    } else {
                        shrimp.setVelocityDirectly({ x: initialShrimpSpeed, y: 0 });
                    }
                });
            }

            const shrimpsToDelete = [];

            // Physics phase
            shrimps.forEach(shrimp => shrimp.calculateNewPosition());
            shrimps.forEach((shrimp, index) => {
                const playerBoundaries = setBoundaries(whale);
                const shrimpBoundaries = setBoundaries(shrimp);

                if (detectCollisions(playerBoundaries, shrimpBoundaries)) {
                    eaten++;
                    audio.play("eating-krill");
                    shrimpsToDelete.push(index);
                    setScore(eaten);
                }
                else if (shrimp.position.x > canvas.width / dpi) {
                    escaped++;
                    shrimpsToDelete.push(index);
                }
            });

            shrimpsToDelete.forEach(index => {
                shrimps.splice(index, 1);
            });

            // Drawing phase
            drawBackgroundImage();

            shrimps.forEach(shrimp => {
                drawShrimpImage(
                    shrimp.position.x,
                    shrimp.position.y,
                    shrimp.size.width,
                    shrimp.size.height,
                );
            });

            drawWhaleImage(
                whale.position.x,
                whale.position.y,
                whale.size.width,
                whale.size.height,
            );

            // Run next frame
        }
        if (finished === false) {
            requestAnimationFrame(gameLoop);
        }
    };

};

export default WhaleGameEngine;
