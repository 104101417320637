const InputHandler = (CANVAS, interactiveObjectsArray) => {

  // Get size of canvas element (it DOES NOT consider page offset!)
  const canvasPosition = CANVAS.getBoundingClientRect();

  // Calculate press x,y position relative to canvas element
  const setTouchPosition = (x, y) => ({
      x: Math.floor(x - canvasPosition.x),
      y: Math.floor(y - canvasPosition.y)
  });

  // Handle touch events
  const handleTouchStart = event => {
    event.preventDefault();
    const touchPosition = setTouchPosition(
      event.targetTouches[0].clientX,
      event.targetTouches[0].clientY,
    );

    // Run touch event for each object in array
    interactiveObjectsArray.forEach(object => {
      object.handleTouch && object.handleTouch(touchPosition);
    });
  };

  const handleTouchMove = event => {
    event.preventDefault();
    const touchPosition = setTouchPosition(
      event.targetTouches[0].clientX,
      event.targetTouches[0].clientY,
    );

    // Run touch move event for each object in array
    interactiveObjectsArray.forEach(object => {
      object.handleTouchMove && object.handleTouchMove(touchPosition);
    });
  };

  const handleTouchEnd = event => {
    event.preventDefault();
    const touchPosition = setTouchPosition(
      event.changedTouches[0].clientX,
      event.changedTouches[0].clientY,
    );

    // Run touch end event for each interactive object in array
    interactiveObjectsArray.forEach(object => {
      object.handleTouchEnd && object.handleTouchEnd(touchPosition);
    });
  };

  // Add touch event listeners
  CANVAS.addEventListener('touchstart', handleTouchStart);
  CANVAS.addEventListener('touchmove', handleTouchMove);
  CANVAS.addEventListener('touchend', handleTouchEnd);
};

export default InputHandler;
