import { PlayerCollisionHandler } from './CollisionHandler';

export const setBoundaries = object => {
  const top = object.position.y;
  const bottom = object.position.y + object.size.height;
  const left = object.position.x;
  const right = object.position.x + object.size.width;
  return { top, bottom, left, right };
};

export const detectCollisions = (A, B) => {
  if (
    (A.bottom > B.top &&
      A.top < B.bottom &&
      ((A.left < B.right && A.right > B.left) ||
        (A.left > B.right && A.right < B.left))) ||
    (A.top > B.bottom &&
      A.bottom < B.top &&
      ((A.left < B.right && A.right > B.left) ||
        (A.left > B.right && A.right < B.left)))
  ) {
    return true;
  }
  return false;
};

export const PlayerCollisionDetector = (player, objects) => {
  const playerBoundaries = setBoundaries(player);
  let result = false;
  for (let i = 0; i < objects.length; i++) {
    const objectBoundaries = setBoundaries(objects[i]);
    if (detectCollisions(playerBoundaries, objectBoundaries)) {
      PlayerCollisionHandler(
        player,
        objects[i],
        playerBoundaries,
        objectBoundaries,
      );
      result = true;
    } else {
      player.resetBlockingObstacle();
    }
  }
  return result;
};

// Tests only, there is no need for that now
export const CollisionDetector = objects => {
  for (let i = 0; i < objects.length; i++) {
    for (let j = i + 1; j < objects.length; j++) {
      const objA = setBoundaries(objects[i]);
      const objB = setBoundaries(objects[j]);
      if (detectCollisions(objA, objB)) {
        //CollisionHandler(objects[i], objects[j], objA, objB);
      }
    }
  }
};
