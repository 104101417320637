import React, { useRef } from "react";
import clamp from "lodash-es/clamp";
import { isEqual } from "lodash-es";
import swap from "lodash-move";
import { useGesture } from "react-use-gesture";
import { useSprings, animated, interpolate } from "react-spring";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { addPointsForScreen } from "../../store/actions";


// Returns fitting styles for dragged/idle items
const fn = (order, down, originalIndex, curIndex, y, x) => index =>
    down && index === originalIndex
        ? {
            y: curIndex * (window.innerWidth * 0.9) / 5 + y,
            x: curIndex * (window.innerWidth * 0.9) / 5 + x,
            scale: 1.1,
            zIndex: "1",
            shadow: 15,
            immediate: n => n === "y" || n === "zIndex"
        }
        : {
            y: order.indexOf(index) * (window.innerWidth * 0.9) / 5,
            x: order.indexOf(index) * (window.innerWidth * 0.9) / 5,
            scale: 1,
            zIndex: "0",
            shadow: 1,
            immediate: false
        };


export const DraggableList = ({ items, winGame, img, partsCount, points, index }) => {
    const dispatch = useDispatch();

    const temp = (window.innerWidth * 0.9) / 5

    const order = useRef(items.map((_, index) => index)); // Store indicies as a local ref, this represents the item order
    const [springs, setSprings] = useSprings(items.length, fn(order.current)); // Create springs, each corresponds to an item, controlling its transform, scale, etc.
    const bind = useGesture(({ args: [originalIndex], down, delta: [x, y], event }) => {
        const curIndex = order.current.indexOf(originalIndex);
        const curRow = clamp(
            Math.round((curIndex * temp + x) / temp),
            0,
            items.length - 1
        );
        const newOrder = swap(order.current, curIndex, curRow);
        setSprings(fn(newOrder, down, originalIndex, curIndex, y, x)); // Feed springs new style data, they'll animate the view without causing a single render
        if (!down) order.current = newOrder;

        if (event.type === "mouseup" || event.type === "touchend") {
            if (isEqual(newOrder, [4, 1, 0, 3, 2])) {
                dispatch(addPointsForScreen(points, index));
                winGame();
            }
        }
    });
    return (

        <Content img={img} partsCount={partsCount} temp={temp}>
            {springs.map(({ zIndex, shadow, x, y, scale }, i) => (
                <animated.div
                    {...bind(i)}
                    key={i}
                    className="draggableListElement"
                    style={{
                        zIndex,
                        boxShadow: shadow.interpolate(
                            s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
                        ),
                        transform: interpolate(
                            [x, y, scale],
                            (x, y, s) => `translate3d(${x}px,0,0) scale(${s})`
                        )
                    }}
                />
            ))}
        </Content>
    );
};
const Content = styled.div`
  position: relative;
  width: 100%;
  height: calc(${({ partsCount }) => (100 / partsCount - 1) / 100} * 250vw);
  .draggableListElement {
    position: absolute;
    width: ${({ partsCount }) => 100 / partsCount - 1}%;
    height: calc(${({ partsCount }) => (100 / partsCount - 1) / 100} * 250vw);
    transform-origin: 50% 50% 0px;
    border-radius: 1vh;
    color: white;
    font-size: 1.6rem;
    background: transparent;
    border: 0.5vh #fff solid;
    background-image: url(${({ img }) => img});
    background-size: ${({ width, partsCount }) => width * partsCount}px 100%;
    background-size: ${({ partsCount }) => 100 * partsCount}% 100%;
    background-repeat: no-repeat;

    :nth-child(5) {
        background-position: 0 0;
    }
    :nth-child(2) {
      background-position: 25% 0;
    }
    :nth-child(1) {
      background-position: 50% 0;
    }
    :nth-child(4) {
      background-position: 75% 0;
    }
    :nth-child(3) {
      background-position: 100% 0;
    }
  }
`;