import React from 'react';

import '../../config/globalStyles.scss';
import './puzzleScreen.scss';
import { getImagePath } from '../../config/helpers';
import { DraggableList } from './DraggableList';
import styled from 'styled-components';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';
import Text from '../TextContainer/Text';

const PuzzleScreen = ({ active, data, index }) => {
    const { gameScreen } = data;
    const { graphicPath, partsCount = 5, points, title, description } = gameScreen;

    return (
        <div className="screenContentContainer puzzleScreen">
            <div className="puzzleTextContainer">
                {!!title && <h2 className="puzzleScreenTitle"><Text>{title}</Text></h2>}
                <h4 className="puzzleScreenDescription"><Text>{description}</Text></h4>
            </div>
            <WithTimerScreen
                active={active}
                data={data}
                index={index}
                gameContent={winHandler => (
                    <DraggableList
                        items={Array.from(Array(partsCount).keys())}
                        winGame={winHandler}
                        img={getImagePath(graphicPath)}
                        partsCount={partsCount}
                        points={points}
                        index={index}
                    />
                )}
                timeOutContent={
                    <WinContainer borderColor="red">
                        <img src={getImagePath(graphicPath)} alt="puzzle" />
                    </WinContainer>
                }
                winContent={
                    <WinContainer borderColor="rgb(145,214,74)">
                        <img src={getImagePath(graphicPath)} alt="puzzle" />
                    </WinContainer>
                }
            />
        </div>
    );
};

export default PuzzleScreen;

const WinContainer = styled.div`
  margin: 0 auto;
    width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 1vh;
  background: ${({ borderColor }) => borderColor};
  > img {
    width: 100%;
    // height: 100%;
    background: lightblue;
    border-radius: 10px;
  }
  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -150%;
    width: 200%;
    height: 400%;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
`;
