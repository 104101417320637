import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../main.scss';
import { getCurrentScreenIndex, selectCurrentScreenData, getPause } from '../../../store/reducers/mainReducer';
import { setSwipeAvailable, setCompletedStatus, setTimerStatus } from '../../../store/actions/mainActions';
import Text from '../../TextContainer/Text';
//import audio from '../../../config/audio';

const Timer = ({ index }) => {
    const dispatch = useDispatch();
    const currentScreenIndex = useSelector(getCurrentScreenIndex);
    const currentScreenData = useSelector(selectCurrentScreenData);
    const pause = useSelector(getPause);
    const { limit_time, timerStatus } = currentScreenData;

    const [time, setTime] = useState(limit_time);
    const [timerStarted, setTimerStarted] = useState(false);
    const intervalID = useRef(null);
    const pauseRef = useRef(pause);

    const counter = () => {
        setTime(seconds => pauseRef.current ? seconds : seconds - 1)
        intervalID.current = setTimeout(counter, 1000);
    }

    useEffect(() => {
        pauseRef.current = pause;
    }, [pause]);

    useEffect(() => {
        setTime(limit_time);
        setTimerStarted(false);

        return () => { clearInterval(intervalID.current) };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index])

    useEffect(() => {
        if (timerStatus === 'started' && !timerStarted) {
            intervalID.current = setTimeout(counter, 1000);
            setTimerStarted(true);
        } else if (timerStatus === 'stop' || timerStatus === 'end') {
            clearInterval(intervalID.current);
            dispatch(setTimerStatus("stopped", currentScreenIndex));
        }
        if (time <= 0) {
            clearInterval(intervalID.current);
            dispatch(setTimerStatus("end", currentScreenIndex));
            dispatch(setCompletedStatus(true, currentScreenIndex));
            dispatch(setSwipeAvailable(true));
        }
        // eslint-disable-next-line
    }, [timerStarted, time, timerStatus]);

    //useEffect(() => {
    //    if (time === 5 && !audio.isPlaying("time-ending")) {
    //        audio.play("time-ending");
    //    }
    //    else if(time < 5 && timerStatus === "stop") {
    //        audio.stop("time-ending");
    //    }
    //}, [time, timerStatus])

    return (
        <div className="sectionElement sectionElement--right-top">
            <div className="timer">
                <h3 className={"timerText " + ((time <= 5 && timerStatus === "started") ? 'timerRed' : 'colorDark')}>
                    <Text>{time.toString().toMMSS()}</Text>
                </h3>
            </div>
        </div>
    );
};

export default Timer;
