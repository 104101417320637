import React from 'react';

import './DesktopMainContainer.scss';

const DesktopMainContainer = () => {
    return (
        <div className="container">
            <div className="contentContainer">
                <img className="img_phone" src="/images/desktop/webimg_phone.png" alt="Akwarium Gdyńskie" />
                <img className="img_qr_code" src="/images/desktop/webqr_code.png" alt="https://gra.akwarium.gdynia.pl" />
            </div>
            <div className="desktopLogotypesContainer">
                <img className="img_logotypes" src="/images/desktop/pasek-logo.jpg" alt="" />
            </div>
        </div>

    );
};

export default DesktopMainContainer;
