
import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { addPointsForScreen } from "../../store/actions/";

import { sliderImages } from "../../config/images";
import Text from '../TextContainer/Text';

const ListElement = ({ content, index, position, countAnswers, isFinished }) => {
    const dispatch = useDispatch();

    const {id, left_text, right_text, good_direction, points} = content;

    const [shown, setShown] = useState(1);
    //Touch handler
    const handleTouch = e => {
      if (!isFinished) {
        const touch = e.targetTouches[0];
        const distanceFromCenter = Math.round(touch.clientX - window.innerWidth / 2);
        const boundary = parseInt(window.getComputedStyle(window.document.querySelector(".placeholder")).getPropertyValue("width")) / 4;
        if (distanceFromCenter < -boundary) {
          setShown(0);
        }
        else if (distanceFromCenter > boundary) {
          setShown(2);
        }
        else {
          setShown(1);
        }
      }
    }
  
    // Pass if it's selected
    useEffect(() => {
      countAnswers(shown !== 1, position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shown])
  
    // Add points at the end of the game
    useEffect(() => {
      if (isFinished && ((shown === 0 && good_direction === 0) || (shown === 2 && good_direction === 1))) {
        dispatch(addPointsForScreen(points, index))
    } else {
        dispatch(addPointsForScreen(0, index))
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished])
  
    const {correctAnswerGlow, correctAnswer, neutralAnswer, wrongAnswer, wrongAnswerGlow} = sliderImages;    
    let leftAnswerImage = neutralAnswer;
    let middleAnswerImage = neutralAnswer;
    let rightAnswerImage = neutralAnswer;
  
    let leftGlowImage = correctAnswerGlow;
    let rightGlowImage = correctAnswerGlow;
  
    if (isFinished) {
      if (good_direction === 0) {
        leftAnswerImage = correctAnswer;
        rightAnswerImage = wrongAnswer;
  
        leftGlowImage = correctAnswerGlow;
        rightGlowImage = wrongAnswerGlow;
      } else {
        leftAnswerImage = wrongAnswer;
        rightAnswerImage = correctAnswer;
      
        leftGlowImage = wrongAnswerGlow;
        rightGlowImage = correctAnswerGlow;
      }
      middleAnswerImage = wrongAnswer;
    }
  
    //Check if circles and glows are visible
    const isVisible = num =>  {
      if(shown === num) {
        return true;
      } else if (isFinished){
        if(good_direction === 0) {
          return num === 0;
        } else {
          return num === 2;
        }
      }
  
    };
  
    
    const visibleProperty = isVisible => isVisible ? "visible" : "hidden";
  
    return (
      <div className="row" key={id}>
        <div className="leftSide">
          <Text>{left_text}</Text>
        </div>
        <div className="center" onTouchStart={handleTouch} onTouchMove={handleTouch}>
          <img src={sliderImages.placeholder} alt="" className="placeholder" />
          <div className="answers">
            <div className="answer" style={{ background: `url(${leftAnswerImage}`, visibility: visibleProperty(isVisible(0)) }}>
              <img src={leftGlowImage} alt="" className="glow" style={{ visibility: visibleProperty(isFinished && isVisible(0)) }} />
            </div>
            <div className="answer" style={{ background: `url(${middleAnswerImage}`, visibility: visibleProperty(isVisible(1)) }}>
              <img src={wrongAnswerGlow} alt="" className="glow" style={{ visibility: visibleProperty(isFinished && isVisible(1)) }} />
            </div>
            <div className="answer" style={{ background: `url(${rightAnswerImage}`, visibility: visibleProperty(isVisible(2)) }}>
              <img src={rightGlowImage} alt="" className="glow" style={{ visibility: visibleProperty(isFinished && isVisible(2)) }} />
            </div>
          </div>
        </div>
        <div className="rightSide">
          <Text>{right_text}</Text>
        </div>
      </div>
    );
  }
  

  export default ListElement