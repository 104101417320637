import React from 'react';

import TutorialScreenContent from './TutorialScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

const TutorialScreen = ({active, data}) => <WithTimerScreen
    active={active}
    data={data}
    canSwipe={false}
    gameContent={endHandler => <TutorialScreenContent active={active} endGame={endHandler} data={data}/>}
/>


export default TutorialScreen;
