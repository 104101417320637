import React, { useState, useRef } from "react";

function SwipeContainer({ setFacefilter }) {
    const startPoint = useRef(0);
    const [left, setLeft] = useState(null);

    const handleTouchStart = event => {
        startPoint.current = event.targetTouches[0].clientX;
        setLeft(event.changedTouches[0].clientX);
    };

    const handleTouchMove = event => {
        setLeft(event.changedTouches[0].clientX);
    };

    const handleTouchEnd = () => {
        setLeft(null);
        if (left < (startPoint.current / 3) * 2) {
            setFacefilter(true);
        }
    };

    return (
        <div
            className="swipeContainer"
            style={{
                height: "100%",
                transform: left !== null && `translateX(calc(${left}px - 80%))`,
                backgroundColor: left !== null && "rgba(255,255,255,0.5)",
            }}
            onTouchStart={event => {
                handleTouchStart(event)
            }}
            onTouchMove={event => {
                handleTouchMove(event)
            }}
            onTouchEnd={event => {
                handleTouchEnd(event)
            }}
        />
    );
};

export default SwipeContainer;