import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setScoreForScreen, addPointsForScreen } from '../../../store/actions/mainActions';
import { getCurrentScreenIndex } from '../../../store/reducers/mainReducer';
import StatisticsPopup from '../StatisticsPopup/StatisticsPopup';

function CanvasEnvironment({
    active,
    data,
    Engine,
    canvasWidthModifier,
    canvasHeightModifier,
    endGame,
    isTimeout
}) {
    const dispatch = useDispatch();

    const currentScreenIndex = useSelector(getCurrentScreenIndex);

    const { gameScreen, limit_time: timeLimit } = data;
    const { points: maxPoints, type } = gameScreen;

    const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
    const [started, setStarted] = useState(false);
    const [end, setEnd] = useState(false);
    const [score, setScore] = useState(0);
    const [pointsToAdd, addPoints] = useState(0);

    const [game, setGame] = useState(null);

    const canvasRef = useRef(null);
    // On mount turn off swipe
    useEffect(() => {
        setStarted(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isTimeout) {
            dispatch(setScoreForScreen(score, currentScreenIndex));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [score]);

    useEffect(() => {
        if(active) {
            dispatch(addPointsForScreen(pointsToAdd, currentScreenIndex));
            addPoints(0);
        }
    }, [active, pointsToAdd, dispatch, currentScreenIndex]);

    // On mount calculate canvas size and save it in state
    useEffect(() => {
        let size = calculateCanvasSize();
        setCanvasSize({ width: size.width, height: size.height });
        // eslint-disable-next-line
    }, [setCanvasSize]);

    // Run game loop
    useEffect(() => {
        if (started) {
            const canvas = canvasRef.current;
            setGame(new Engine({
                canvas,
                setEnd,
                setScore,
                timeLimit,
                maxPoints,
                addPoints
            }));
        }
        // eslint-disable-next-line
    }, [started]);

    useEffect(() => {
        if (active && game) {
            game.startGame();
        }
    }, [active, game])

    // Perform actions after win
    useEffect(() => {
        if (end && endGame) {
            endGame();
        }
        // eslint-disable-next-line
    }, [end]);


    // Calculate size of canvas based on vievport size and given modifiers
    const calculateCanvasSize = () => {
        const viewportWidth = Math.max(
            document.documentElement.clientWidth,
            window.innerWidth || 0,
        );
        const canvasWidth = Math.floor(canvasWidthModifier * viewportWidth);
        const viewportHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0,
        );
        const canvasHeight = Math.floor(canvasHeightModifier * viewportHeight);
        return { width: canvasWidth, height: canvasHeight };
    };

    return (
        <div
            style={{
                width: canvasSize.width,
                height: canvasSize.height,
                margin: 'auto',
                // For fullscreen view:
                position: 'absolute',
                top: 0,
                left: 0,
            }}>
            <canvas
                id="canvas"
                width={canvasSize.width}
                height={canvasSize.height}
                ref={canvasRef}
                style={{
                    backgroundColor: 'lightgrey',
                    width: canvasSize.width,
                    height: canvasSize.height,
                }}
            />
            <StatisticsPopup gameType={type} score={score} visible={isTimeout} />
        </div>
    );
};

export default CanvasEnvironment;
