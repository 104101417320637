import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './PlayButton.scss';
import { getGlobalConfig } from '../../../store/reducers/mainReducer';
import { commonImages } from '../../../config/images';
import { changeScreen } from '../../../store/actions';
import Text from '../../TextContainer/Text';
import audio from '../../../config/audio';

const PlayButton = () => {
    const dispatch = useDispatch();

    const globalConfig = useSelector(getGlobalConfig);
    const { play_button_text } = globalConfig;

    return (
        <div
            className="playButtonContainer"
            style={{
                backgroundImage: `url(${commonImages.backgroundButtonPlay})`
            }}
            onClick={() => {
                audio.play("click");
                dispatch(changeScreen);
            }}>
            <h2 className="buttonText">
                <Text>{play_button_text}</Text>
            </h2>
        </div>
    );
};

export default PlayButton;
