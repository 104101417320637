export default {
    global: [{
        code: "bubbles",
        parts: [{
            ctx: require.context("../assets/images/filters/bubbles", false, /\.png/),
            position: (side, width, height) => [side / 2 - width * (2 / 5), (side / 2 - height * (2 / 5))],
            isStatic: true,
            expression: [0.5, 1]
        }]
    }],
    list: [{
        code: "medusa",
        icon: require("../assets/images/filters/icons/ic_meduza.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/medusa/Left fin", false, /\.png/),
            position: (side, width, height) => [-width + side / 10, -side * (3 / 10)],
        }, {
            ctx: require.context("../assets/images/filters/medusa/Right fin", false, /\.png/),
            position: (side, width, height) => [side - side / 10, -side * (3 / 10)],
        }, {
            ctx: require.context("../assets/images/filters/medusa/Head", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, -height],
        }]
    }, {
        code: "anemone",
        icon: require("../assets/images/filters/icons/ic_ukwial.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/anemone/Head", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, -height]
        }, {
            ctx: require.context("../assets/images/filters/anemone/Neck", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, 2 * side - height],
            isStatic: true
        }],
    }, {
        code: "seaurchin",
        icon: require("../assets/images/filters/icons/ic_jezowiec.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/seaurchin", false, /\.png/),
            position: (side, width, height) => [-width * (20 / 63), -height * (23 / 63)],
        }]
    }, {
        code: "starfish",
        icon: require("../assets/images/filters/icons/ic_rozgwiazda.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/starfish", false, /\.png/),
            position: (side, width, height) => [-width * (280 / 900), -height * (300 / 900)],
        }]
    }, {
        code: "crawfish",
        icon: require("../assets/images/filters/icons/ic_langusta.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/crawfish/Left antennal", false, /\.png/),
            position: (side, width, height) => [-width + side * (9 / 20), -height]
        }, {
            ctx: require.context("../assets/images/filters/crawfish/Left arm", false, /\.png/),
            position: (side, width, height) => [-width + side * (3 / 20), side / 2]
        }, {
            ctx: require.context("../assets/images/filters/crawfish/Right antennal", false, /\.png/),
            position: (side, width, height) => [side * (11 / 20), -height]
        }, {
            ctx: require.context("../assets/images/filters/crawfish/Right arm", false, /\.png/),
            position: (side, width, height) => [side * (17 / 20), side / 2]
        }, {
            ctx: require.context("../assets/images/filters/crawfish/Tail", false, /\.png/),
            position: (side, width, height) => [side - width * (4 / 30), -height / 2]
        }]
    }, {
        code: "crab",
        icon: require("../assets/images/filters/icons/ic_krab.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/crab/Left arm/", false, /\.png/),
            position: (side, width, height) => [-width, -height],
        },
        {
            ctx: require.context("../assets/images/filters/crab/Right arm/", false, /\.png/),
            position: (side, width, height) => [side, -height],
        },
        {
            ctx: require.context("../assets/images/filters/crab/Left legs/", false, /\.png/),
            position: (side, width, height) => [-width, side / 2 - height / 2],
        },
        {
            ctx: require.context("../assets/images/filters/crab/Right legs/", false, /\.png/),
            position: (side, width, height) => [side, side / 2 - height / 2],
        }],
    }, {
        code: "stringray",
        icon: require("../assets/images/filters/icons/ic_plaszczka.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/stingray/Body", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, -height - side * 0.4]
        }]
    }, {
        code: "clownfish",
        icon: require("../assets/images/filters/icons/ic_blazenek.png"),
        parts: [
            {
                ctx: require.context("../assets/images/filters/clownfish/Head", false, /\.png/),
                position: (side, width, height) => [(side - width) / 2, -height - side / 10],
            }, {
                ctx: require.context("../assets/images/filters/clownfish/Left fin", false, /\.png/),
                position: (side, width, height) => [-width + width * (4 / 63), side / 2 - height * (2 / 3)],
            },
            {
                ctx: require.context("../assets/images/filters/clownfish/Right fin", false, /\.png/),
                position: (side, width, height) => [side - width * (8 / 63), side / 2 - height * (2 / 3)],
            }]
    }, {
        code: "shark",
        icon: require("../assets/images/filters/icons/ic_rekin.png"),
        parts: [{
            ctx: require.context("../assets/images/filters/shark/Top fin", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, -height - side / 2],
        },
        {
            ctx: require.context("../assets/images/filters/shark/Tail", false, /\.png/),
            position: (side, width, height) => [side - width * (1 / 10), -height / 2],
        },
        {
            ctx: require.context("../assets/images/filters/shark/Mouth/0", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, side - height * (8 / 10)],
            expression: [0 / 7, 1 / 7]
        },
        {
            ctx: require.context("../assets/images/filters/shark/Mouth/1", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, side - height * (8 / 10)],
            expression: [1 / 7, 2 / 7]
        },
        {
            ctx: require.context("../assets/images/filters/shark/Mouth/2", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, side - height * (8 / 10)],
            expression: [2 / 7, 3 / 7]
        },
        {
            ctx: require.context("../assets/images/filters/shark/Mouth/3", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, side - height * (8 / 10)],
            expression: [3 / 7, 4 / 7]
        },
        {
            ctx: require.context("../assets/images/filters/shark/Mouth/4", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, side - height * (8 / 10)],
            expression: [4 / 7, 5 / 7]
        },
        {
            ctx: require.context("../assets/images/filters/shark/Mouth/5", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, side - height * (8 / 10)],
            expression: [5 / 7, 6 / 7]
        },
        {
            ctx: require.context("../assets/images/filters/shark/Mouth/6", false, /\.png/),
            position: (side, width, height) => [(side - width) / 2, side - height * (8 / 10)],
            expression: [6 / 7, 7 / 7]
        }]
    },
    {
        code: "turtle",
        icon: require("../assets/images/filters/icons/ic_zolw.png"),
        parts: [
            {
                ctx: require.context("../assets/images/filters/turtle/Shell", false, /\.png/),
                position: (side, width, height) => [(side - width) / 2, -height - side * (2 / 10)],
            }, {
                ctx: require.context("../assets/images/filters/turtle/Left leg", false, /\.png/),
                position: (side, width, height) => [-width + width * (75 / 400), side / 2 - height * (3 / 4)],
            },
            {
                ctx: require.context("../assets/images/filters/turtle/Right leg", false, /\.png/),
                position: (side, width, height) => [side - width * (75 / 400), side / 2 - height * (3 / 4)],
            }]
    }]
}