import React, { useState, useEffect } from 'react'
import FacefilterScreen from './FacefilterScreen/FacefilterScreen';
import AnimalSummaryScreen from './AnimalSummaryScreen/AnimalSummaryScreen';
import { useSelector, useDispatch } from 'react-redux';
import { selectTotalEarnedPoints, selectTotalAvailablePoints } from '../../store/reducers/mainReducer';
import { setSwipeAvailable } from '../../store/actions/mainActions';

function AnimalProfileScreen({ active, data = {} }) {
    const dispatch = useDispatch();

    const [isFacefilter, setFacefilter] = useState(false);
    
    const totalEarnedPoints = useSelector(selectTotalEarnedPoints);
    const totalAvailablePoints = useSelector(selectTotalAvailablePoints);
    
    const { animalProfileScreen = {} } = data;
    
    const { animalProfiles = [] } = animalProfileScreen;
    
    let animalIndex = Math.trunc(totalEarnedPoints / totalAvailablePoints * animalProfiles.length);//magic

    if (animalIndex > animalProfiles.length - 1 || isNaN(animalIndex)) {
        animalIndex = animalProfiles.length - 1;
    }

    useEffect(() => {
        if (active) {
            dispatch(setSwipeAvailable(false));
        }
    }, [active, dispatch]);


    const content = isFacefilter ? <FacefilterScreen initial={animalIndex} /> : <AnimalSummaryScreen animalIndex={animalIndex}  data={data} setFacefilter={setFacefilter}/>;
    return content;
}

export default AnimalProfileScreen
