import InputHandler from '../commonElements/InputHandler';
import { StaticObject } from '../commonElements/BasicObjectClasses';
import { labyrinthImages } from '../../../config/images';

function LabyrinthGameEngine ({
    canvas, 
    setEnd, 
    setScore, 
    timeLimit,
    maxPoints, 
    addPoints
}) {
    const ctx = canvas.getContext('2d', { alpha: false });
    const dpi = window.devicePixelRatio;

    const style_height = parseInt(getComputedStyle(canvas).getPropertyValue('height'));
    const style_width = parseInt(getComputedStyle(canvas).getPropertyValue('width'));

    const fixBlur = () => {
        canvas.setAttribute('height', style_height * dpi);
        canvas.setAttribute('width', style_width * dpi);
    };

    this.startGame = () => {
        setTimeout(() => {
            setEnd(true);
            isFinished = true;
        }, timeLimit * 1000);

        requestAnimationFrame(gameLoop);
    }

    fixBlur();
    ctx.scale(dpi, dpi)


    const UP = 0b0001;
    const RIGHT = 0b0010;
    const DOWN = 0b0100;
    const LEFT = 0b1000;

    const map = new Uint8Array([14, 10, 10, 10, 10, 12, 4, 6, 10, 12, 4, 5, 6, 12, 4, 6, 11, 9, 7, 8, 3, 13, 7, 9, 3, 13, 5, 6, 8, 5, 6, 10, 9, 5, 6, 8, 5, 5, 3, 10, 9, 7, 10, 8, 7, 9, 2, 13, 3, 10, 10, 12, 5, 6, 12, 5, 6, 10, 9, 6, 14, 12, 5, 3, 13, 1, 1, 3, 10, 14, 13, 5, 5, 3, 10, 13, 4, 6, 10, 10, 9, 5, 1, 3, 8, 4, 3, 13, 7, 8, 6, 12, 1, 6, 10, 10, 13, 4, 5, 7, 12, 1, 3, 14, 9, 2, 12, 7, 13, 5, 5, 3, 10, 14, 9, 2, 12, 5, 5, 3, 13, 7, 10, 12, 1, 6, 12, 5, 5, 1, 6, 13, 5, 4, 3, 14, 13, 5, 3, 11, 10, 13, 5, 5, 3, 12, 1, 5, 3, 10, 12, 6, 13, 1, 5, 4, 5, 4, 5, 6, 12, 7, 9, 5, 4, 7, 9, 7, 11, 9, 5, 3, 11, 8, 5, 5, 7, 8, 1, 6, 12, 3, 8, 6, 10, 9, 5, 3, 10, 10, 9, 3, 14, 8, 3, 10, 10, 9]);

    // Scale function returns scaled size of the object based on device pixel ratio
    const scale = size => size / dpi;

    let isFinished = false;
    let lifes = 4;
    setScore(lifes);

    const borderSize = {
        x: (scale(canvas.width) * 80 / 1080),
        y: (scale(canvas.height) * 90 / 1920)
    }

    const padding = {
        x: 0,
        y: 0
    };

    const aspectRatio = canvas.width / canvas.height;
    const mazeAspectRatio = 914 / 1486;

    if (aspectRatio >= mazeAspectRatio) {
        padding.y = 0.05 * window.innerHeight;
        padding.x = (scale(canvas.width) - 2 * borderSize.x + mazeAspectRatio * (2 * borderSize.y + 2 * padding.y - scale(canvas.height))) / 2;
    }
    else {
        padding.x = 10;
        padding.y = (scale(canvas.height) - 2 * borderSize.y - (scale(canvas.width) - 2 * borderSize.x - 2 * padding.x) / mazeAspectRatio) / 2
    }


    const mazePoint = {
        x: borderSize.x + padding.x,
        y: borderSize.y + padding.y
    }

    const drawingArray = [];

    const backgroundImage = new Image();
    backgroundImage.src = labyrinthImages.background;

    const borderImage = new Image();
    borderImage.src = labyrinthImages.border;

    const circleImage = new Image();
    circleImage.src = labyrinthImages.circle;

    const gridImage = new Image();
    gridImage.src = labyrinthImages.grid;

    const capybaraImage = new Image();
    capybaraImage.src = labyrinthImages.capybara;

    const snakeImages = {
        head: {
            top: new Image(),
            right: new Image(),
            bottom: new Image(),
            left: new Image()
        },
        body: {
            horizontal: new Image(),
            vertical: new Image()
        },
        flex: {
            topRight: new Image(),
            bottomRight: new Image(),
            bottomLeft: new Image(),
            topLeft: new Image()
        },
        end: {
            top: new Image(),
            right: new Image(),
            bottom: new Image(),
            left: new Image()
        }
    }

    snakeImages.head.top.src = labyrinthImages.snakeHeadTop;
    snakeImages.head.right.src = labyrinthImages.snakeHeadRight;
    snakeImages.head.bottom.src = labyrinthImages.snakeHeadBottom;
    snakeImages.head.left.src = labyrinthImages.snakeHeadLeft;

    snakeImages.body.horizontal.src = labyrinthImages.snakeBodyHorizontal;
    snakeImages.body.vertical.src = labyrinthImages.snakeBodyVertical;

    snakeImages.flex.topRight.src = labyrinthImages.snakeFlexTopRight;
    snakeImages.flex.bottomRight.src = labyrinthImages.snakeFlexBottomRight;
    snakeImages.flex.bottomLeft.src = labyrinthImages.snakeFlexBottomLeft;
    snakeImages.flex.topLeft.src = labyrinthImages.snakeFlexTopLeft;

    snakeImages.end.top.src = labyrinthImages.snakeEndTop;
    snakeImages.end.right.src = labyrinthImages.snakeEndRight;
    snakeImages.end.bottom.src = labyrinthImages.snakeEndBottom;
    snakeImages.end.left.src = labyrinthImages.snakeEndLeft;

    const arrowImages = {
        top: new Image(),
        right: new Image(),
        bottom: new Image(),
        left: new Image()
    }

    arrowImages.top.src = labyrinthImages.arrowTop;
    arrowImages.right.src = labyrinthImages.arrowRight;
    arrowImages.bottom.src = labyrinthImages.arrowBottom;
    arrowImages.left.src = labyrinthImages.arrowLeft;

    const down = {
        x: 0,
        y: 0
    }

    const background = new StaticObject({
        image: backgroundImage,
        position: { x: 0, y: 0 },
        size: { width: scale(canvas.width), height: scale(canvas.height) },
        isVisible: true,
        onTouch() {
            down.x = this.touchPoint.x;
            down.y = this.touchPoint.y;
        },
        onTouchMove() {
            const up = {
                x: this.touchPoint.x,
                y: this.touchPoint.y
            }
            if(down.x !== null && Math.hypot(up.x - down.x, up.y - down.y) > tileSize) {
                const diff = {
                    x: down.x - up.x,
                    y: down.y - up.y
                }
                if (Math.abs(diff.x) > Math.abs(diff.y)) {
                    if (diff.x > 0) {
                        snake.setDirection("w")
                    } else {
                        snake.setDirection("e")
                    }
                } else {
                    if (diff.y > 0) {
                        snake.setDirection("n")
                    } else {
                        snake.setDirection("s")
                    }
                }
                down.x = this.touchPoint.x;
                down.y = this.touchPoint.y;
            }
        }
    });

    const border = new StaticObject({
        image: borderImage,
        position: { x: 0, y: 0 },
        size: Object.assign({}, background.size),
        isVisible: true,
    });




    InputHandler(canvas, [background])

    const grid = new StaticObject({
        image: gridImage,
        position: { x: mazePoint.x, y: mazePoint.y },
        size: { width: scale(canvas.width) - mazePoint.x * 2, height: scale(canvas.height) - mazePoint.y * 2 },
        isVisible: true,
    });
    const tileSize = 41 * grid.size.height / 745//(grid.size.height - grid.size.height / 1490 * 14) / 18;

    const radius = 284 / 82 * tileSize;

    const circle = new StaticObject({
        image: circleImage,
        position: { x: background.size.width / 2 - radius, y: background.size.height - borderSize.y - radius },
        size: { width: radius * 2, height: radius * 2 },
        isVisible: true,
    });


    const capybaraWidth = radius;

    const capybara = new StaticObject({
        image: capybaraImage,
        position: { x: background.size.width / 2 - capybaraWidth / 2, y: background.size.height - borderSize.y - capybaraWidth / 4 },
        size: { width: capybaraWidth, height: capybaraWidth / 2 },
        isVisible: true
    });

    drawingArray.push(background, circle, border, grid, capybara);

    const snake = {
        length: 10,
        speed: 100,
        isDying: false,
        deathCounter: 0,
        reset() {
            this.path = [-6, -5, -4, -3, -2, -1].map(num => ({
                x: num,
                y: 0,
                direction: "e"
            }))
            this.position = { x: 0, y: 0 };
            this.direction = "e";
            this.isDrawingHelpers = true;
            this.isDying = false;
            this.deathCounter = 0;
            if (lifes === 1) {
                isFinished = true;
                setEnd(true);
            } else {
                setScore(--lifes);
            }
        },
        autoGo() {
            if (this.position.x === 5 && this.position.y === 18) {
                addPoints(maxPoints);
                isFinished = true;
                setEnd(true);
            } else {
                const possibleDirections = this.detectCollisions();
                const count = possibleDirections.length;
                this.isDrawingHelpers = false;
                this.canChoose = false;
                this.timeoutId = setTimeout(this.autoGo.bind(this), this.speed)
                if (count === 0) {
                    // Kys
                    this.isDying = true;
                    //this.reset();
                //} else if (count === 1) {
                    // Go to only avaible option
                    //this.go(possibleDirections[0]);
                } else {
                    // Let player choose
                    this.canChoose = true;
                    this.isDrawingHelpers = true;
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
            }
        },
        go(direction) {
            let possibleDirections = this.detectCollisions();
            if (possibleDirections.includes(direction)) {
                this.direction = direction;
                this.path.push(Object.assign({ direction: this.direction }, this.position));
                switch (direction) {
                    case "n":
                        this.position.y--;
                        break;
                    case "e":
                        this.position.x++;
                        break;
                    case "s":
                        this.position.y++;
                        break;
                    case "w":
                        this.position.x--;
                        break;
                    default:
                        break;
                }
                if (this.canChoose) {
                    this.isDrawingHelpers = false;
                    this.canChoose = false;
                    this.timeoutId = setTimeout(this.autoGo.bind(this), this.speed)
                }
            }
        },
        oppositeDirection(direction) {
            switch (direction) {
                case "n":
                    return "s";
                case "e":
                    return "w";
                case "s":
                    return "n";
                case "w":
                    return "e";
                default:
                    break;
            }
        },
        canChoose: true,
        direction: 'e',
        inputDirection: null,
        setDirection(direction) {
            if (this.canChoose) {
                this.go(direction);
            }
        },
        detectCollisions() {
            const ways = map[this.position.y * 11 + this.position.x];

            const possibleDirections = [];

            if (ways & UP) {
                possibleDirections.push("n");
            }

            if (ways & RIGHT) {
                possibleDirections.push("e");
            }

            if (ways & DOWN) {
                possibleDirections.push("s");
            }

            if (ways & LEFT) {
                possibleDirections.push("w");
            }

            const directionBack = this.oppositeDirection(this.direction);

            if (possibleDirections.includes(directionBack)) {
                possibleDirections.splice(possibleDirections.indexOf(directionBack), 1);
            }

            return possibleDirections;
        },
        calculateDrawPosition(position) {
            return {
                x: mazePoint.x + tileSize * 7 / 82 + position.x * tileSize,
                y: mazePoint.y + tileSize * 7 / 82 + position.y * tileSize
            }
        },

        draw() {
            if (this.path.length > this.length) {
                this.path.shift();
            }

            if (this.isDying) {
                this.deathCounter++;
            }

            if (this.deathCounter === 50) {
                this.reset();
            }

            if (!this.isDying || Math.floor(this.deathCounter / 10) % 2) {
                const drawPosition = this.calculateDrawPosition(this.position);
                //head
                let head = null;
                switch (this.direction) {
                    case "n":
                        head = snakeImages.head.bottom;
                        break;
                    case "e":
                        head = snakeImages.head.left;
                        break;
                    case "s":
                        head = snakeImages.head.top;
                        break;
                    case "w":
                        head = snakeImages.head.right;
                        break;
                    default:
                        break;
                }
                ctx.drawImage(head, drawPosition.x, drawPosition.y, Math.ceil(tileSize), Math.ceil(tileSize));

                for (let i = 0; i < this.path.length; i++) {

                    const segment = this.path[i];
                    let img = null;

                    const drawPosition = this.calculateDrawPosition(segment);

                    if (i === 0) {
                        //tail
                        if (segment.direction === "n") {
                            img = snakeImages.end.top;
                        }
                        else if (segment.direction === "e") {
                            img = snakeImages.end.right;
                        }
                        else if (segment.direction === "s") {
                            img = snakeImages.end.bottom;
                        }
                        else if (segment.direction === "w") {
                            img = snakeImages.end.left;
                        }
                        ctx.drawImage(img, drawPosition.x, drawPosition.y, Math.ceil(tileSize), Math.ceil(tileSize));
                    }
                    else {
                        //flex

                        const previousSegment = this.path[i - 1];
                        let img = null;

                        if (previousSegment.direction !== segment.direction) {
                            if ((previousSegment.direction === "s" && segment.direction === "e") || (previousSegment.direction === "w" && segment.direction === "n")) {
                                img = snakeImages.flex.topRight;
                            }
                            else if ((previousSegment.direction === "n" && segment.direction === "e") || (previousSegment.direction === "w" && segment.direction === "s")) {
                                img = snakeImages.flex.bottomRight;
                            }
                            else if ((previousSegment.direction === "n" && segment.direction === "w") || (previousSegment.direction === "e" && segment.direction === "s")) {
                                img = snakeImages.flex.bottomLeft;
                            }
                            else if ((previousSegment.direction === "s" && segment.direction === "w") || (previousSegment.direction === "e" && segment.direction === "n")) {
                                img = snakeImages.flex.topLeft;
                            }
                        }
                        else {
                            //body
                            if (segment.direction === "n" || segment.direction === "s") {
                                img = snakeImages.body.vertical;
                            }
                            else if (segment.direction === "e" || segment.direction === "w") {
                                img = snakeImages.body.horizontal;
                            }
                        }
                        ctx.drawImage(img, drawPosition.x, drawPosition.y, Math.ceil(tileSize), Math.ceil(tileSize));
                    }
                }
            }


            if (this.isDrawingHelpers) {
                this.drawHelpers();
            }
        },
        drawHelpers() {
            const drawPosition = this.calculateDrawPosition(this.position);
            const raycasters = [{
                x: Math.round(drawPosition.x + tileSize / 2),
                y: Math.round(drawPosition.y),
            }, {
                x: Math.round(drawPosition.x + tileSize),
                y: Math.round(drawPosition.y + tileSize / 2),
            }, {
                x: Math.round(drawPosition.x + tileSize / 2),
                y: Math.round(drawPosition.y + tileSize),
            }, {
                x: Math.round(drawPosition.x),
                y: Math.round(drawPosition.y + tileSize / 2),
            }];

            const possibleDirections = this.detectCollisions();
            ctx.fillStyle = "rgb(0, 100, 0)"
            const size = tileSize / 3;

            if (possibleDirections.includes("n")) {
                ctx.drawImage(arrowImages.top, raycasters[0].x - size / 2, raycasters[0].y - size / 2, size, size);
            }
            if (possibleDirections.includes("e")) {
                ctx.drawImage(arrowImages.right, raycasters[1].x - size / 2, raycasters[1].y - size / 2, size, size);
            }
            if (possibleDirections.includes("s")) {
                ctx.drawImage(arrowImages.bottom, raycasters[2].x - size / 2, raycasters[2].y - size / 2, size, size);
            }
            if (possibleDirections.includes("w")) {
                ctx.drawImage(arrowImages.left, raycasters[3].x - size / 2, raycasters[3].y - size / 2, size, size);
            }
        }
    }
    snake.reset();

    function draw(object) {
        if (object.isVisible === true) {
            if (object.image) {
                ctx.drawImage(
                    object.image,
                    object.position.x,
                    object.position.y,
                    object.size.width,
                    object.size.height,
                );
            }

        }
    }

    // === GAME LOOP ===


    const gameLoop = () => {
        ctx.clearRect(0, 0, canvas.width / dpi, canvas.height / dpi);

        for (let el of drawingArray) {
            draw(el)
        }

        snake.draw();
        if(!isFinished) {
            requestAnimationFrame(gameLoop);
        }
    };
};

export default LabyrinthGameEngine;