import React from 'react';

import '../main.scss';
import { commonImages } from '../../../config/images';
import audio from '../../../config/audio';
import {useDispatch} from "react-redux";
import {setMapPopup} from "../../../store/actions/mainActions";

const MapButton = ({onClick, iconMapSrc}) => {
  const dispatch = useDispatch();
    const openMap = () => {
      dispatch(setMapPopup(true));
    };

    const clickMapHandler = () => {
      audio.play("click");
      if(onClick) onClick();
      else openMap();
    };

    return (
        <div
            className="sectionElement sectionElement--right-bottom"
            style={{
                backgroundImage: `url(${commonImages.backgroundRightBottomCorner})`,
            }}>
            <img
                src={iconMapSrc?iconMapSrc:commonImages.iconMap}
                alt="Map"
                className="sectionElement__image sectionElement__image--right-bottom"
                onClick={()=>clickMapHandler()}
            />
        </div>
    );
};

export default MapButton;
