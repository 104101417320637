import React, {useEffect} from 'react';

import '../../config/globalStyles.scss';
import './withNewsletterEndScreen.scss';
import { getImagePath } from '../../config/helpers';
import { commonImages } from '../../config/images';
import Text from '../TextContainer/Text';
import { useDispatch } from 'react-redux';
import { setSwipeAvailable } from '../../store/actions/mainActions';

const WithNewsletterEndScreen = ({ data, active }) => {
    const dispatch = useDispatch();

    const { withNewsletterEndScreen, screenLogotypesData: logotypes = [] } = data;

    const {
        text_1,
        text_2,
        graphic_text,
        newsletter_link,
        newsletter_btn_text,
    } = withNewsletterEndScreen;
    useEffect(() => {
        if (active) {
            dispatch(setSwipeAvailable(true));
        }
    }, [active, dispatch]);
    return (
        <div className="screenContentContainer withNewsletterEndScreen">
            <div className="logotypesContainer">
                {logotypes.map(logotype => <img
                    src={getImagePath(logotype.graphicPath)}
                    alt={logotype.name}
                    className="logotype"
                    key={logotype.name}
                />
                )}
            </div>
            <div className="textContainer">
                <h2 className="colorDark"><Text>{text_1}</Text></h2>
                <p className="colorDark"><Text>{text_2}</Text></p>
            </div>
            <div className="imageContainer">
                <img
                    className="seahorseImage"
                    src={commonImages.elementHorseWinner}
                    alt=""
                />
                <img
                    className="textBackground"
                    src={commonImages.elementChatTop}
                    alt=""
                />
                <h2 className="imageText"><Text>{graphic_text}</Text></h2>
            </div>
            <a href={newsletter_link} target="_blank" rel="noopener noreferrer">
                <div className="newsletterButton">
                    <h3 className="colorDark buttonText"><Text>{newsletter_btn_text}</Text></h3>
                </div>
            </a>
        </div>
    );
};

export default WithNewsletterEndScreen;
