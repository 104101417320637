import React, { useState } from 'react'
import "./FacefilterScreen.scss";
import Canvas from './elements/Canvas'
import ChoiceBar from './elements/ChoiceBar'

import filters from "../../../config/filters";
import HeaderBar from './elements/HeaderBar';

function FacefilterScreen({initial = 0}) {

    const icons = filters.list.map(el => el.icon);
    const [selected, setSelected] = useState(initial);

    return (
        <div className="facefilterScreen">
            <div className="facefilterOverlay" />
            <HeaderBar />
            <Canvas icons={icons} initial={initial} selected={selected}/>
            <ChoiceBar icons={icons} selected={selected} setSelected={setSelected} />
        </div>
    )
}

export default FacefilterScreen
