import React from 'react';

import '../main.scss';
import { commonImages } from '../../../config/images';
import { select } from '../../../config/helpers';
import Text from "../../TextContainer/Text";

const GameScore = ({data = {}}) => {
    const { gameScreen = {}, score = 0 } = data;
    const { type } = gameScreen;

    const numbers = score.toString().length

    const {elementShrimpIcon, elementFlyIcon, elementSnakeIcon, elementChest} = commonImages;

    const  scoreImage = select(type, {
        whale_game: elementShrimpIcon,
        archerfish_game: elementFlyIcon,
        labyrinth_game: elementSnakeIcon,
        default: elementChest
    });

    let style = null;

    const vh = height => `calc(var(--vh, 1vh) * ${height})`;

    switch (numbers) {
        case 3: {
            const padding = 24;

            style = {
                backgroundPosition: `${vh(-padding)} 0`,
                backgroundSize: `calc(100% + ${vh(padding)}) 100%`,
                width: `calc(${vh(10)} + 7em)`,
            }
            break;
        }
        case 2: {
            const padding = 20;

            style = {
                backgroundPosition: `${vh(-padding)} 0`,
                backgroundSize: `calc(100% + ${vh(padding)}) 100%`,
                width: `calc(${vh(10)} + 5.5em)`,
            }
            break;
        }
        case 1:
        default:
            const padding = 19;

            style = {
                backgroundPosition: `${vh(-padding)} 0`,
                backgroundSize: `calc(100% + ${vh(padding)}) 100%`,
                width: `calc(${vh(10)} + 4.5em)`,
            }
            break;
    };

    return (
        <div
            className="sectionElement sectionElement--left-bottom"
            style={{
                backgroundImage: `url(${commonImages.backgroundLeftBottomCornerWide})`,
                ...style
            }}
        >
            <img
                src={scoreImage}
                alt="Score"
                className="sectionElement__image sectionElement__image--left-bottom"
            />
            <h3 className="colorGold scoreText"><Text>{parseInt(score)}</Text></h3>
        </div>
    );
};

export default GameScore;
