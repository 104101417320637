import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store/store';
import MainContainer from './screens/MainContainer/MainContainer';
import { isMobileOrTablet } from './config/helpers';
import DesktopMainContainer from "./screens/DesktopMainContainer/DesktopMainContainer";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {
          isMobileOrTablet()?
            <MainContainer />:
            <DesktopMainContainer/>
        }
      </PersistGate>
    </Provider>
  );
};

export default App;
