import InputHandler from '../commonElements/InputHandler';
import { setBoundaries, detectCollisions } from '../commonElements/CollisionDetector';
import {
    StaticObject,
    MovingObject,
} from '../commonElements/BasicObjectClasses';
import { archerfishImages } from '../../../config/images';
import audio from "../../../config/audio";

class RotableObject extends MovingObject {
    constructor({
        image,
        position,
        size,
        isVisible,
        onTouch,
        onTouchMove,
        onTouchEnd,
        velocity,
        acceleration,
        collisionType,
        blockingObstacle,
        rotation
    }) {
        super({
            image,
            position,
            size,
            isVisible,
            onTouch,
            onTouchMove,
            onTouchEnd,
            velocity,
            acceleration,
            collisionType,
            blockingObstacle,
        })

        this.rotation = rotation;

    }

}

class AnimatableObject extends MovingObject {
    constructor({
        position,
        size,
        isVisible,
        onTouch,
        onTouchMove,
        onTouchEnd,
        velocity,
        acceleration,
        collisionType,
        blockingObstacle,
        sprite,
        framerate,
        boundaries,
        frames
    }) {
        super({
            position,
            size,
            isVisible,
            onTouch,
            onTouchMove,
            onTouchEnd,
            velocity,
            acceleration,
            collisionType,
            blockingObstacle,
        })

        this.sprite = sprite;
        this.framerate = framerate;
        this.frames = frames;
        this.boundaries = boundaries;
        this.frame = 0;
        this.canSwitchFrame = true;
    }

    draw(ctx, counter) {
        if (this.canSwitchFrame) {
            this.frame >= this.frames ? this.frame = 0 : this.frame++;
            this.canSwitchFrame = false;
        } else if (counter % 2 === 0) {
            this.canSwitchFrame = true;
        }


        ctx.drawImage(
            this.sprite,
            this.frame * this.boundaries.x,
            0,
            this.boundaries.x,
            this.boundaries.y,
            this.position.x,
            this.position.y,
            this.size.width,
            this.size.height
        );
    }
}


function ArcherfishGameEngine ({
    canvas,
    setScore,
    setEnd,
    timeLimit,
    maxPoints,
    addPoints
}) {
    const ctx = canvas.getContext('2d', { alpha: false });

    const dpi = 1;

    // Relative function returns relative size of the object based on canvas width
    const relative = size => canvas.width * size / 1080;

    // Scale function returns scaled size of the object based on device aspect ratio
    const scale = size => size / dpi;

    const style_height = parseInt(getComputedStyle(canvas).getPropertyValue('height'))
    const style_width = parseInt(getComputedStyle(canvas).getPropertyValue('width'))

    this.startGame = () => {
        setTimeout(() => {
            const points = killed > 10 ? maxPoints : 0;
            addPoints(points)
            setEnd(true);
            setTimeout(() => {
                finished = true;
            }, 2000);
        }, timeLimit * 1000);

        gameLoop();
    }

    const fixBlur = () => {
        canvas.setAttribute('height', style_height * dpi);
        canvas.setAttribute('width', style_width * dpi);
    };


    let killed = 0;
    const fliesAmount = 3
    let counter = 0;
    let shootTimestamp = null;
    let finished = false;

    let toDraw = {
        foreground: [],
        midground: [],
        background: []
    };

    // Images initialize

    const backgroundImage = new Image();
    backgroundImage.src = archerfishImages.sky;

    const flyImage = new Image();
    flyImage.src = archerfishImages.fly;

    const flySprite = new Image();
    flySprite.src = archerfishImages.flySprite;

    const waterImage = new Image();
    waterImage.src = archerfishImages.water;

    const waterTransparentImage = new Image();
    waterTransparentImage.src = archerfishImages.waterTransparent;

    const closerWaveImage = new Image();
    closerWaveImage.src = archerfishImages.closerWave;

    const furtherWaveImage = new Image();
    furtherWaveImage.src = archerfishImages.furtherWave;

    const streamImage = new Image();
    streamImage.src = archerfishImages.stream;

    const fishImage = new Image();
    fishImage.src = archerfishImages.fish;

    const crosshairImage = new Image();
    crosshairImage.src = archerfishImages.crosshair;

    const farCloudImage = new Image();
    farCloudImage.src = archerfishImages.farCloud;

    const middleCloudImage = new Image();
    middleCloudImage.src = archerfishImages.middleCloud;

    const closeCloudImage = new Image();
    closeCloudImage.src = archerfishImages.closeCloud;

    const dropletImage = new Image();
    dropletImage.src = archerfishImages.droplet;

    // Initializing game arrays

    const droplets = [];
    const flies = [];
    const clouds = [];
    const deadFlies = [];

    // Defining game object generators

    /// Fly
    function generateFly() {

        const height = canvas.height;

        const percentage = Math.random() * (0.385 - scale(190) / height) + 0.115;

        const fly = new AnimatableObject({
            image: flyImage,
            position: { x: scale(canvas.width), y: scale(height * percentage) },
            size: { width: relative(scale(165)), height: relative(scale(190)) },
            isVisible: true,
            velocity: { x: relative(-scale(2) * (Math.random() + 1)), y: 0 },
            boundaries: {
                x: 165,
                y: 200
            },
            sprite: flySprite,
            frames: 5
        });

        flies.push(fly);
        toDraw.midground.push(fly);
    }

    ///Cloud

    function generateCloud(isNew) {
        const sizeRandomSeed = Math.random() + 1 / 2;
        const imageRandomSeed = Math.random();

        let image = null;
        if (imageRandomSeed < 1 / 3) {
            image = closeCloudImage
        }
        else if (sizeRandomSeed < 2 / 3) {
            image = middleCloudImage
        }
        else {
            image = farCloudImage
        }

        let cloud = new MovingObject({
            image,
            position: { x: isNew ? relative(scale(canvas.width)) : relative(scale(Math.round(Math.random() * canvas.width))), y: relative(scale(Math.floor(Math.random() * (canvas.height / 2)))) },
            size: { width: sizeRandomSeed * relative(scale(342)), height: sizeRandomSeed * relative(scale(148)) },
            isVisible: true,
            velocity: { x: - 3 / 2 * Math.random() + 1 / 2, y: 0 }
        });

        clouds.push(cloud);
        toDraw.background.push(cloud);
    }

    /// Dead Fly

    function killFly(fly) {

        let deadFly = new RotableObject({
            image: flyImage,
            position: { x: fly.position.x, y: fly.position.y },
            size: { width: relative(scale(140)), height: relative(scale(154)) },
            isVisible: true,
            velocity: { x: 0, y: relative(scale(Math.random() + 10)) },
            rotation: 0
        });

        deadFlies.push(deadFly);
        toDraw.midground.push(deadFly);
    }

    /// Droplet

    function shootDroplet() {

        const diff = {
            x: Math.sin(stream.rotation + Math.PI / 2),
            y: -Math.cos(stream.rotation + Math.PI / 2)
        }

        const SPEED = relative(scale(30));

        let droplet = new RotableObject({
            image: dropletImage,
            position: { x: fishPosition.x, y: fishPosition.y },
            size: { width: relative(scale(23)), height: relative(scale(40)) },
            isVisible: true,
            velocity: { x: diff.x * SPEED, y: diff.y * SPEED },
            rotation: stream.rotation
        });

        droplets.push(droplet);
        toDraw.midground.push(droplet);
    }

    //Generating Clouds

    for (let i = 0; i < 6; i++) {
        generateCloud(false);
    }

    // Create a wave


    let background = new StaticObject({
        image: backgroundImage,
        position: { x: 0, y: 0 },
        size: { width: canvas.width, height: canvas.height },
        isVisible: true,
        onTouch() {
            crosshair.isVisible = true;
        },
        onTouchEnd() {
            crosshair.isVisible = false;
            if (counter - shootTimestamp > 30) {
                shootTimestamp = null;
            }

            if (shootTimestamp === null) {
                shootDroplet();
                shootTimestamp = counter;
            }
        },
        onTouchMove() { }
    })

    // Make fish look upwards
    background.touchPoint = { x: canvas.width, y: 0 };

    // Calculate position of fish
    const fishPosition = {
        x: relative(546),
        y: canvas.height - relative(455)
    };

    const water = new StaticObject({
        image: waterImage,
        position: { x: 0, y: canvas.height - relative(469) },
        size: { width: canvas.width, height: relative(469) },
        isVisible: true,
    });

    const waterTransparent = new StaticObject({
        image: waterTransparentImage,
        position: { x: 0, y: canvas.height - relative(469) },
        size: { width: canvas.width, height: relative(469) },
        isVisible: true,
    });

    const closerWave = new MovingObject({
        image: closerWaveImage,
        position: { x: 0, y: canvas.height - relative(490) },
        size: { width: canvas.width * 2, height: relative(469) },
        velocity: { x: scale(-1), y: 0 },
        isVisible: true,
    });

    closerWave.theta = 0;

    const furtherWave = new MovingObject({
        image: furtherWaveImage,
        position: { x: 0, y: canvas.height - relative(500) },
        size: { width: canvas.width * 2, height: relative(469) },
        velocity: { x: scale(-0.2), y: 0 },
        isVisible: true,
    });

    furtherWave.theta = 1;

    const stream = new RotableObject({
        image: streamImage,
        position: { x: fishPosition.x, y: fishPosition.y },
        size: { width: relative(160), height: relative(368) },
        isVisible: true,
        rotation: 1,
    });

    const fish = new RotableObject({
        image: fishImage,
        position: { x: fishPosition.x, y: fishPosition.y },
        size: { width: relative(208), height: relative(363) },
        isVisible: true,
    });

    const crosshair = new RotableObject({
        image: crosshairImage,
        position: { x: 0, y: 0 },
        size: { width: relative(160), height: relative(615) },
        isVisible: false,
    });

    toDraw = {
        foreground: [crosshair, stream, water, fish, waterTransparent],
        midground: [...flies, ...droplets],
        background: [...clouds, furtherWave, closerWave]
    }

    // Handle press input
    InputHandler(canvas, [background]);

    function draw(object) {
        if (object.isVisible === true) {
            if (object.sprite) {
                object.draw(ctx, counter)
            } else if (object.image) {
                if (object.rotation) {
                    ctx.save();
                    ctx.translate(object.position.x, object.position.y);
                    ctx.rotate(object.rotation + Math.PI / 2);
                    ctx.translate(-object.size.width / 2, -object.size.height);
                    ctx.drawImage(
                        object.image,
                        0,
                        0,
                        object.size.width,
                        object.size.height,
                    );
                    ctx.restore();
                } else {
                    ctx.drawImage(
                        object.image,
                        object.position.x,
                        object.position.y,
                        object.size.width,
                        object.size.height,
                    );
                }
            }
        }
    }

    let drawBackgroundImage = () => { };
    backgroundImage.addEventListener(
        'load',
        () => {
            drawBackgroundImage = () => {
                ctx.drawImage(
                    backgroundImage,
                    0,
                    0,
                    canvas.width / dpi,
                    canvas.height / dpi,
                );
            };
            drawBackgroundImage();
        },
        false,
    );

    // === GAME LOOP ===

    const gameLoop = () => {
        counter++;

        fixBlur();
        ctx.scale(dpi, dpi)
        // Clearing phase
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Game logic phase

        // Initialize arrays with deletable objects
        const cloudsToDelete = [];
        const fliesToDelete = [];
        const dropletsToDelete = [];
        const deadFliesToDelete = [];

        // Clouds phase
        for (const [i, cloud] of clouds.entries()) {
            if (cloud.position.x + cloud.size.width < 0) {
                cloudsToDelete.unshift(i);
                generateCloud(true);
            }
            else {
                cloud.calculateNewPosition();
            }
        }

        //closerWave.position.y += Math.sin(closerWave.theta+=0.05);

        if (closerWave.position.x + closerWave.size.width > scale(canvas.width)) {
            closerWave.calculateNewPosition();
        }
        else {
            closerWave.position.x = 0;
        }

        //furtherWave.position.y += Math.sin(furtherWave.theta+=0.05);

        if (furtherWave.position.x + furtherWave.size.width > scale(canvas.width)) {
            furtherWave.calculateNewPosition();
        }
        else {
            furtherWave.position.x = 0;
        }

        // Flies phase

        for (let i = flies.length; i < fliesAmount; i++) {
            generateFly()
        }

        for (const [i, fly] of flies.entries()) {
            // Check if fly is within the screen
            if (fly.position.x + fly.size.width < 0) {
                fliesToDelete.push(i);
            }
            else {
                fly.position.y += scale(2 * (Math.random() - .5))
                fly.calculateNewPosition();
            }
        }

        // - Droplets phase

        for (const [i, droplet] of droplets.entries()) {
            const dropletBoundaries = setBoundaries(droplet);

            // Check if droplet is within screen
            if (dropletBoundaries.bottom < 0 || dropletBoundaries.left < 0 || dropletBoundaries.right > canvas.width) {
                dropletsToDelete.push(i);
                continue;
            }

            // Check collisions
            for (const [j, fly] of flies.entries()) {
                const flyBoundaries = setBoundaries(fly);

                if (detectCollisions(dropletBoundaries, flyBoundaries)) {
                    dropletsToDelete.unshift(i);
                    fliesToDelete.unshift(j);

                    audio.play("catching-flies")
                    setScore(++killed);
                    killFly(fly);

                    break;
                }
            }

            if (droplet.position.x + droplet.size.width < 0) {
                droplets.splice(droplets.indexOf(droplet), 1);
            }
            else {
                droplet.calculateNewPosition();
            }
        }


        // Dead flies phase
        for (const [i, deadFly] of deadFlies.entries()) {
            deadFly.calculateNewPosition();
            deadFly.rotation += 0.1;

            if (deadFly.position.y > canvas.height) {
                deadFliesToDelete.unshift(i);
            }
        }

        drawBackgroundImage();

        //Deleting objects
        for (const i of cloudsToDelete) {
            toDraw.background.splice(toDraw.background.indexOf(clouds[i]), 1);
            clouds.splice(i, 1)
        }

        for (const i of fliesToDelete) {
            toDraw.midground.splice(toDraw.midground.indexOf(flies[i]), 1);
            flies.splice(i, 1)
        }

        for (const i of dropletsToDelete) {
            toDraw.midground.splice(toDraw.midground.indexOf(droplets[i]), 1);
            droplets.splice(i, 1)
        }

        for (const i of deadFliesToDelete) {
            toDraw.midground.splice(toDraw.midground.indexOf(deadFlies[i]), 1);
            deadFlies.splice(i, 1)
        }

        // Crosshair Phase 
        const desiredRotation = Math.atan2(background.touchPoint.y - fishPosition.y, background.touchPoint.x - fishPosition.x);

        // Limit the boundaries
        if (desiredRotation > - Math.PI / 4 && desiredRotation < Math.PI / 2) {
            stream.rotation = - Math.PI / 4
        }
        else if ((desiredRotation < - 3 * Math.PI / 4 && desiredRotation > -Math.PI) ||
            (desiredRotation > Math.PI / 2 && desiredRotation < Math.PI)) {
            stream.rotation = - 3 * Math.PI / 4
        }
        // Calculate angle between fish and touch point 
        else {
            const delta = (desiredRotation - stream.rotation) * 0.8
            stream.rotation += delta;
        }

        // Calculate crosshair position on circle from stream size
        crosshair.position = {
            x: fishPosition.x + Math.sin(stream.rotation + Math.PI / 2) * stream.size.height,
            y: fishPosition.y - Math.cos(stream.rotation + Math.PI / 2) * stream.size.height
        }

        crosshair.rotation = stream.rotation;
        fish.rotation = stream.rotation + Math.PI;

        // Drawing phase

        for (const obj of toDraw.background) {
            draw(obj);
        }

        for (const obj of toDraw.midground) {
            draw(obj);
        }

        for (const obj of toDraw.foreground) {
            draw(obj);
        }

        // Run next frame
        if (!finished)
            requestAnimationFrame(gameLoop);
    };

};

export default ArcherfishGameEngine;