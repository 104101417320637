import { createSelector } from 'reselect';
import {tutorialScreenData} from './tutorialScreenData'

import {
    REQUEST_GET_SCREENS_DATA,
    RESPONSE_GET_SCREENS_DATA,
    ERROR_GET_SCREENS_DATA,
    GO_TO_NEXT_SCREEN,
    ADD_POINTS_FOR_SCREEN,
    SET_TIMER_STATUS_FOR_SCREEN,
    SET_COMPLETED_STATUS_FOR_SCREEN,
    SET_SCORE_FOR_SCREEN,
    SET_WIN_LOSE_POPUP,
    SET_CURIOSITY_POPUP,
    SET_START_TIME_POPUP,
    SET_CHANGE_LANGUAGE_POPUP,
    SET_CHANGE_MAP_POPUP,
    SET_OFFLINE_POPUP,
    SET_SWIPE_AVAILABLE,
    SET_LANGUAGE,
    SET_PAUSE,
    RESET_STORE
} from '../actions/actionTypes.js';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const initialState = {
    globalConfig: {},
    currentScreenIndex: 0,
    screensData: [],
    winPopupData: {},
    losePopupData: {},
    isLoadingScreensData: false,
    errorLoadingScreensData: null,
    winLosePopup: null,
    curiosityPopup: false,
    startTimePopup: false,
    changeLanguagePopup: false,
    mapPopup: false,
    offlinePopup: false,
    swipeAvailable: true,
    pause: false
};

function findPoints(obj) {
    let sum = 0;
    for (let property in obj) {
        if (property === "points") {
            sum += obj[property]
        } else if (typeof obj[property] === "object") {
            sum += findPoints(obj[property])
        };
    }
    return sum
}

const mainReducer = (state = initialState, action) => {
    //console.log("nainReducer action.type: ", action.type);
    switch (action.type) {
        case GO_TO_NEXT_SCREEN:
            return {
                ...state,
                currentScreenIndex: state.currentScreenIndex + 1,
            };
        case REQUEST_GET_SCREENS_DATA:
            return {
                ...state,
                isLoadingScreensData: true,
                errorLoadingScreensData: null,
            };
        case RESPONSE_GET_SCREENS_DATA: {

            let screensData = action.payload.screensData.map(screenData => {
                let availablePoints = 0;
                for (let property in screenData) {
                    if (typeof screenData[property] === "object") {
                        availablePoints += findPoints(screenData[property]);
                    }
                }

                return { ...screenData, availablePoints }
            });
            const oldScreensData = state.screensData;
            if(oldScreensData.length>0&&!action.payload.resetScreensData){
                screensData = oldScreensData.map(oldScreenData => {
                    return {...oldScreenData}
                })
                screensData.shift();
            } else {
                //screensData.splice(2,0,tutorialScreenData);
                screensData.splice(2,0,tutorialScreenData);// only for tests
                //console.log("scerrns Data:", screensData);
            }

            return {
                ...state,
                screensData: [{}, ...screensData],
                globalConfig: action.payload.globalConfig,
                isLoadingScreensData: false,
                errorLoadingScreensData: null,
                winPopupData: action.payload.winPopupData,
                losePopupData: action.payload.losePopupData,
                availableLanguages: action.payload.availableLanguages,
                mapData: action.payload.mapData
            };
        }
        case ERROR_GET_SCREENS_DATA:
            return {
                ...state,
                isLoadingScreensData: false,
                errorLoadingScreensData: action.payload.error,
            };
        case ADD_POINTS_FOR_SCREEN: {
            const newScreensData = [...state.screensData];

            let earnedPoints = newScreensData[action.payload.currentScreenIndex].earnedPoints
            ? newScreensData[action.payload.currentScreenIndex].earnedPoints + action.payload.earnedPoints
            : action.payload.earnedPoints;

            const availablePoints = newScreensData[action.payload.currentScreenIndex]?.availablePoints;

            if(earnedPoints>availablePoints){
                earnedPoints = availablePoints;
            }

            newScreensData[action.payload.currentScreenIndex] = {
                ...newScreensData[action.payload.currentScreenIndex],
                earnedPoints,
            };

            return {
                ...state,
                screensData: newScreensData,
            };
        }
        case SET_TIMER_STATUS_FOR_SCREEN: {
            const newScreensData = [...state.screensData];
            newScreensData[action.payload.currentScreenIndex] = {
                ...newScreensData[action.payload.currentScreenIndex],
                timerStatus: action.payload.status,
            };
            return {
                ...state,
                screensData: newScreensData,
            };
        }
        case SET_COMPLETED_STATUS_FOR_SCREEN: {
            const newScreensData = [...state.screensData];
            newScreensData[action.payload.currentScreenIndex] = {
                ...newScreensData[action.payload.currentScreenIndex],
                completed: action.payload.completed,
            };
            return {
                ...state,
                screensData: newScreensData,
            };
        }
        case SET_SCORE_FOR_SCREEN: {
            const newScreensData = [...state.screensData];
            newScreensData[action.payload.currentScreenIndex] = {
                ...newScreensData[action.payload.currentScreenIndex],
                score: action.payload.score,
            };
            return {
                ...state,
                screensData: newScreensData,
            };
        }
        case SET_WIN_LOSE_POPUP: {
            return {
                ...state,
                winLosePopup: action.payload.winLosePopup
            }
        }
        case SET_CURIOSITY_POPUP: {
            return {
                ...state,
                curiosityPopup: action.payload.curiosityPopup
            }
        }
        case SET_START_TIME_POPUP: {
            return {
                ...state,
                startTimePopup: action.payload.startTimePopup
            }
        }
        case SET_CHANGE_LANGUAGE_POPUP: {
            return {
                ...state,
                changeLanguagePopup: action.payload.changeLanguagePopup
            }
        }
        case SET_CHANGE_MAP_POPUP: {
            return {
                ...state,
                mapPopup: action.payload.mapPopup
            }
        }
        case SET_OFFLINE_POPUP: {
            return {
                ...state,
                offlinePopup: action.payload.offlinePopup
            }
        }
        case SET_SWIPE_AVAILABLE: {
            return {
                ...state,
                swipeAvailable: action.payload.swipeAvailable
            }
        }
        case SET_LANGUAGE: {
            return {
                ...state,
                language: action.payload.language
            }
        }
        case SET_PAUSE: {
            return {
                ...state,
                pause: action.payload.pause
            }
        }
        case RESET_STORE: {
            storage.removeItem('persist:root');
            return {
                ...initialState
            }
        }
        default:
            return state;
    }
};

export const getScreensData = state => state.main.screensData;
export const getCurrentScreenIndex = state => state.main.currentScreenIndex;
export const getNumberOfScreens = state => state.main.screensData.length;
export const getGlobalConfig = state => state.main.globalConfig;
export const getWinLosePopup = state => state.main.winLosePopup;
export const getCuriosityPopup = state => state.main.curiosityPopup;
export const getStartTimePopup = state => state.main.startTimePopup;
export const getOfflinePopup = state => state.main.offlinePopup;
export const getCurrentScore = state => parseInt(state.main.screensData[state.main.currentScreenIndex].score);
export const getSwipeAvailable = state => state.main.swipeAvailable;
export const getWinPopupData = state => state.main.winPopupData;
export const getLosePopupData = state => state.main.losePopupData;
export const getChangeLanguagePopup = state => state.main.changeLanguagePopup;
export const getAvailableLanguages = state => state.main.availableLanguages;
export const getMapPopup = state => state.main.mapPopup;
export const getMapData = state => state.main.mapData;
export const getLanguage = state => state.main.language;
export const getPause = state => state.main.pause;

export const selectCurrentScreenData = createSelector(
    [getScreensData, getCurrentScreenIndex],
    (screensData, currentScreenIndex) => {
        if (screensData && screensData.length) {
            return screensData[currentScreenIndex];
        } else {
            return null;
        }
    },
);

export const selectNextScreenData = createSelector(
    [getScreensData, getCurrentScreenIndex],
    (screensData, currentScreenIndex) => {
        if (
            screensData &&
            screensData.length &&
            currentScreenIndex + 1 <= screensData.length
        ) {
            return screensData[currentScreenIndex + 1];
        } else {
            return null;
        }
    },
);

export const selectTotalEarnedPoints = createSelector(
    [getScreensData, getCurrentScreenIndex],
    (screensData, currentScreenIndex) => {
        let sum = 0;
        if (screensData && screensData.length) {
            screensData.forEach((screen, index) => {
                if (screen.earnedPoints && currentScreenIndex !== index) {
                    sum += screen.earnedPoints;
                }
            });
        }
        return parseInt(sum);
    },
);
export const selectTotalAvailablePoints = createSelector(
    [getScreensData, getCurrentScreenIndex],
    (screensData, currentScreenIndex) => screensData.reduce((p, n) => p + (n.availablePoints || 0), 0)
);

export default mainReducer;
