import React from 'react';
import { useDispatch } from "react-redux";
import { commonImages } from '../../../../config/images';
import { changeScreen } from '../../../../store/actions/mainActions';

const CloseButton = () => {
    const dispatch = useDispatch();

    return (
        <div
            className="faceFilterCloseButton"
            style={{
                backgroundImage: `url(${commonImages.backgroundRightTopCorner})`,
            }}>
            <img
                src={commonImages.iconClose}
                alt="Close"
                className="faceFilterCloseButton__image"
                onClick={() => dispatch(changeScreen)}
            />
        </div>
    );
};

export default CloseButton;
