import React from 'react'

import PointsCounter from "../elements/PointsCounter";
import Timer from "../elements/Timer";
import CloseButton from "../elements/CloseButton";

function Header({ active, index, data, points, winLosePopup, curiosityPopup }) {
    const { screen_type, limit_time } = data;

    const disabled = [
        "info",
        "points_summary",
        "animal_profile",
        "with_newsletter_end",
        "with_institutions_list",
        "tutorial"
    ];

    return (
        <div className="screenSection screenSection--header">
            {active && !disabled.includes(screen_type) &&
                !winLosePopup && !curiosityPopup ? (
                    <PointsCounter points={points} />
                ) : (
                    <div className="sectionElement sectionElement--left" />
                )}
            {limit_time > 0 && !winLosePopup && active ? (
                <div hidden={curiosityPopup}>
                    <Timer index={index} data={data} />
                </div>
            ) : null}
            {curiosityPopup || screen_type==="tutorial"  ? (
                <CloseButton screenType = {screen_type} />
            ) : null}
        </div>
    );
}

export default Header;
