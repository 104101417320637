export const commonImages = {
    backgroundLeftBottomCorner: require('../assets/images/bg_white_left_bottom_corner.png'),
    backgroundRightBottomCorner: require('../assets/images/bg_white_right_bottom_corner.png'),
    backgroundRightTopCorner: require('../assets/images/bg_white_right_top_corner.png'),
    backgroundRightTopCornerWide: require('../assets/images/bg_white_right_top_corner_wide.png'),
    backgroundLeftTopCornerWide: require('../assets/images/bg_white_left_top_corner_wide.png'),
    backgroundLeftBottomCornerWide: require('../assets/images/bg_white_left_bottom_corner_wide.png'),
    backgroundButtonPlay: require('../assets/images/btn_play_bg.png'),
    iconCoin: require('../assets/images/icn_coin.png'),
    iconClose: require('../assets/images/icn_close.png'),
    iconCuriosity: require('../assets/images/icn_curiosity.png'),
    iconMap: require('../assets/images/icn_map.png'),
    iconMap2D: require('../assets/images/icn_2D_map.png'),
    iconMap3D: require('../assets/images/icn_3D_map.png'),
    iconCheck: require("../assets/images/icn_check.svg"),
    elementCuriosity: require('../assets/images/icn_curiosity_big.png'),
    elementChat: require('../assets/images/bg_chat.png'),
    elementChatTop: require('../assets/images/bg_chat_top.png'),
    elementChest: require('../assets/images/img_chest.png'),
    elementSeahorse: require('../assets/images/img_horse_main.png'),
    elementHorseWinner: require('../assets/images/img_winner.gif'),
    elementHorseLoser: require('../assets/images/img_loser.gif'),
    elementStopwatch: require('../assets/images/stopwatch.png'),
    elementSeahorseChest: require('../assets/images/img_seahorse_chest.png'),
    elementPhoneRotate: require("../assets/images/img_phone_rotate.png"),
    elementChestSummary: require("../assets/images/img_chest_summary.png"),
    elementOffline: require("../assets/images/img_offline.svg"),

    // scroll_game
    elementBalloon: require('../assets/images/img_balloon.png'),
    elementLake: require('../assets/images/bg_lake.png'),
    // whale_game
    backgroundWhaleGame: require('../assets/images/bg_minigame_whale.png'),
    elementWhale: require('../assets/images/char_whale.png'),
    elementShrimp: require('../assets/images/char_shrimp.png'),
    elementShrimpIcon: require('../assets/images/icn_points_shrimp.png'),

    elementFlyIcon: require('../assets/images/icn_points_fly.png'),
    elementSnakeIcon: require('../assets/images/icn_points_snake.png'),

    swipeHand: require("../assets/images/img_swipe_hand.png")

};

export const exampleImages = {
    exampleBackground: require('../assets/images/bg_example.png'),
    exampleCuriosityBackground: require("../assets/images/bg_curiosity_screen.png"),
    exampleLightBackground: require('../assets/images/bg_light_example.png'),
    exampleMainScreenBackground: require('../assets/images/bg_main_screen.png'),
    exampleMainScreenAltBackground: require('../assets/images/bg_main_screen_tablet_2-3.png'),
    exampleNavigationScreenBackground: require('../assets/images/bg_navigation_screen.png'),
    exampleWinnerScreenBackground: require('../assets/images/bg_winner_screen.png'),
    exampleLoserScreenBackground: require('../assets/images/bg_loser_screen.png'),
    exampleStartTimeScreenBackground: require('../assets/images/bg_start_time.png'),
    exampleSummaryScreenBackground: require('../assets/images/bg_start_time.png'),
    exampleCompaniesScreenBackground: require('../assets/images/bg_gamefication_companies.png'),
    exampleLanguageIcon: require('../assets/images/icn_language_en.png'),
    exampleAgLogo: require('../assets/images/img_logo_ag.png'),
    exampleMirLogo: require('../assets/images/img_logo_mir.png'),
    exampleSeahorse: require('../assets/images/img_horse_main.png'),
    exampleStartButton: require('../assets/images/btn_play.png'),
    exampleCave: require('../assets/images/bg_cave.jpg')
};

export const archerfishImages = {
    closeCloud: require("../assets/images/minigames/archerfish/img_minigame_fish_cloud.png"),
    middleCloud: require("../assets/images/minigames/archerfish/img_minigame_fish_cloud_2.png"),
    farCloud: require("../assets/images/minigames/archerfish/img_minigame_fish_cloud_3.png"),
    crosshair: require("../assets/images/minigames/archerfish/img_minigame_fish_crosshair.svg"),
    droplet: require("../assets/images/minigames/archerfish/img_minigame_fish_droplet.png"),
    fly: require("../assets/images/minigames/archerfish/img_minigame_fish_fly.png"),
    flySprite: require("../assets/images/minigames/archerfish/spritesheets/fly_flying_spritesheet.png"),
    fish: require("../assets/images/minigames/archerfish/img_minigame_fish_character.png"),
    sky: require("../assets/images/minigames/archerfish/bg_minigame_fish_sky.png"),
    stream: require("../assets/images/minigames/archerfish/img_minigame_fish_stream.svg"),
    water: require("../assets/images/minigames/archerfish/bg_minigame_fish_water.png"),
    waterTransparent: require("../assets/images/minigames/archerfish/bg_minigame_fish_water_transparent.png"),
    furtherWave: require("../assets/images/minigames/archerfish/bg_minigame_fish_wave_1.png"),
    closerWave: require("../assets/images/minigames/archerfish/bg_minigame_fish_wave_2.png")
};

export const labyrinthImages = {
    arrowTop: require("../assets/images/minigames/labyrinth/img_arrow_top.svg"),
    arrowRight: require("../assets/images/minigames/labyrinth/img_arrow_right.svg"),
    arrowBottom: require("../assets/images/minigames/labyrinth/img_arrow_bottom.svg"),
    arrowLeft: require("../assets/images/minigames/labyrinth/img_arrow_left.svg"),

    background: require("../assets/images/minigames/labyrinth/bg_labyrinth.png"),
    border: require("../assets/images/minigames/labyrinth/bg_border.png"),
    circle: require("../assets/images/minigames/labyrinth/bg_circle.png"),
    grid: require("../assets/images/minigames/labyrinth/img_labyrinth_grid.png"),
    capybara: require("../assets/images/minigames/labyrinth/img_capybara.png"),

    snakeHeadTop: require("../assets/images/minigames/labyrinth/img_snake_head_top.svg"),
    snakeHeadRight: require("../assets/images/minigames/labyrinth/img_snake_head_right.svg"),
    snakeHeadBottom: require("../assets/images/minigames/labyrinth/img_snake_head_bottom.svg"),
    snakeHeadLeft: require("../assets/images/minigames/labyrinth/img_snake_head_left.svg"),

    snakeBodyVertical: require("../assets/images/minigames/labyrinth/img_snake_body_vertical.svg"),
    snakeBodyHorizontal: require("../assets/images/minigames/labyrinth/img_snake_body_horizontal.svg"),

    snakeFlexTopRight: require("../assets/images/minigames/labyrinth/img_snake_flex_top_right.svg"),
    snakeFlexBottomRight: require("../assets/images/minigames/labyrinth/img_snake_flex_bottom_right.svg"),
    snakeFlexBottomLeft: require("../assets/images/minigames/labyrinth/img_snake_flex_bottom_left.svg"),
    snakeFlexTopLeft: require("../assets/images/minigames/labyrinth/img_snake_flex_top_left.svg"),

    snakeEndTop: require("../assets/images/minigames/labyrinth/img_snake_end_top.svg"),
    snakeEndRight: require("../assets/images/minigames/labyrinth/img_snake_end_right.svg"),
    snakeEndBottom: require("../assets/images/minigames/labyrinth/img_snake_end_bottom.svg"),
    snakeEndLeft: require("../assets/images/minigames/labyrinth/img_snake_end_left.svg"),
};

export const sliderImages = {
    placeholder: require("../assets/images/minigames/slider/img_scroll_placeholder.png"),
    correctAnswer: require("../assets/images/minigames/slider/img_correct_answer.png"),
    wrongAnswer: require("../assets/images/minigames/slider/img_wrong_answer.png"),
    neutralAnswer: require("../assets/images/minigames/slider/img_neutral_answer.png"),
    correctAnswerGlow: require("../assets/images/minigames/slider/img_correct_answer_glow.png"),
    wrongAnswerGlow: require("../assets/images/minigames/slider/img_wrong_answer_glow.png")
}

export const swipeImages = {
    arrowsUpDown: require("../assets/images/minigames/swipe/img_arrows_up_down.png"),

    arrowUp: require("../assets/images/minigames/swipe/img_arrow_neutral_up.png"),
    arrowDown: require("../assets/images/minigames/swipe/img_arrow_neutral_down.png"),

    arrowUpCorrect: require("../assets/images/minigames/swipe/img_arrow_correct_up.png"),
    arrowUpWrong: require("../assets/images/minigames/swipe/img_arrow_wrong_up.png"),

    arrowDownCorrect: require("../assets/images/minigames/swipe/img_arrow_correct_down.png"),
    arrowDownWrong: require("../assets/images/minigames/swipe/img_arrow_wrong_down.png"),

    arrowUpCorrectGlow: require("../assets/images/minigames/swipe/img_arrow_correct_up_light.png"),
    arrowUpWrongGlow: require("../assets/images/minigames/swipe/img_arrow_wrong_up_light.png"),

    arrowDownCorrectGlow: require("../assets/images/minigames/swipe/img_arrow_correct_down_light.png"),
    arrowDownWrongGlow: require("../assets/images/minigames/swipe/img_arrow_wrong_down_light.png"),

    plank: require("../assets/images/minigames/swipe/bg_crocodile_game_wood.png"),
    reverse: require("../assets/images/minigames/swipe/img_card_reverse.png")
}

export const navigationImages = {
    porthole: require("../assets/images/img_porthole.png")
}

export const filterImages = {
    frame: require("../assets/images/photo_frame.png")
}

export const logotypeImages = {
    balticMuseum: require("../assets/images/img_logo_baltic_museums.png"),
    brand: require("../assets/images/img_logo_brand.png"),
    interreg: require("../assets/images/img_logo_interreg.png")
}