import React from 'react';
import { useDispatch } from "react-redux";

import '../main.scss';
import { commonImages } from '../../../config/images';
import { setCuriosityPopup, setMapPopup, changeScreen } from '../../../store/actions/mainActions';
import audio from '../../../config/audio';

const CloseButton = ({screenType}) => {
    const dispatch = useDispatch();

    const closeHandler = () =>{
        if(screenType==="tutorial"){
            dispatch(changeScreen)
        }
      dispatch(setCuriosityPopup(false));
      dispatch(setMapPopup(false));
  }

    return (
        <div
            className="sectionElement closeButton"
            style={{
                backgroundImage: `url(${commonImages.backgroundRightTopCorner})`,
            }}>
            <img
                src={commonImages.iconClose}
                alt="Close"
                className="sectionElement__image sectionElement__image--right-top sectionElement__image--close"
                onClick={() => {
                    audio.play("click");
                    closeHandler();
                }}
            />
        </div>
    );
};

export default CloseButton;
