import React, { useState, useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from "react-redux";

import AnswerTile from "./AnswerTile";

import '../../config/globalStyles.scss';
import './pairQuizScreen.scss';
import { addPointsForScreen } from "../../store/actions";
import Text from "../TextContainer/Text";
import audio from '../../config/audio';

const PairQuizScreenContent = ({
    data,
    index,
    endGame,
    leftAnswers,
    rightAnswers,
    isEnd = false
}) => {
    const dispatch = useDispatch();
    const { pairQuizScreen } = data;

    const {
        title,
        text
    } = pairQuizScreen;

    // Create screen state
    const [finished, setFinished] = useState(false);

    const [submitted, setSubmitted] = useState([]);
    const [selected, setSelected] = useState([-1, -1]);

    const [correctAnswers, setCorrectAnswers] = useState([[], []]);
    const [wrongAnswers, setWrongAnswers] = useState([[], []]);

    const [wrongVisible, setWrongVisible] = useState(true);
    const [correctVisible, setCorrectVisible] = useState(true);
    const [blinkedCount, setBlinkedCount] = useState(0);

    const canvasRef = useRef(null);
    const timeoutIdRef = useRef(null);
    const blinkingTimes = useMemo(() => 5, []);
    const correctAnswersCount = useMemo(() => leftAnswers.reduce((prev, el) => prev + !!el.points, 0), [leftAnswers])


    const clickHandler = (index, isRight) => () => {
        const side = Number(isRight);
        const oppositeSide = Number(!isRight);
        if (!finished) {
            audio.play("click");
            if (!correctAnswers[side].includes(index) && !wrongAnswers[side].includes(index)) {
                if (selected[oppositeSide] !== -1) {
                    if (!isRight) {
                        pairAnswers(index, selected[oppositeSide]);
                    } else {
                        pairAnswers(selected[oppositeSide], index);
                    }
                } else if (selected[side] !== index) {
                    if (!isRight) {
                        setSelected(([, right]) => [index, right]);
                    } else {
                        setSelected(([left]) => [left, index]);
                    }
                } else {
                    if (!isRight) {
                        setSelected(([, right]) => [-1, right]);
                    } else {
                        setSelected(([left]) => [left, -1]);
                    }
                }
            }
        }
    }

    // On component mount fix canvas blur
    useEffect(() => {
        const canvas = canvasRef.current;
        const dpi = window.devicePixelRatio;
        canvas.height = parseInt(window.getComputedStyle(canvas).getPropertyValue("height")) * dpi;
        canvas.width = parseInt(window.getComputedStyle(canvas).getPropertyValue("width")) * dpi;
        canvas.getContext("2d").scale(dpi, dpi)
    }, []);

    // Finish game on either timeout or win
    useEffect(() => {
        if (isEnd) {
            setFinished(true);
            cleanup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnd])

    // If there is 4 selected pairs, end game
    useEffect(() => {
        if (submitted.length === correctAnswersCount && correctAnswersCount > 0 && !finished) {
            endGame(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finished, submitted]);


    // Create blinking on end of the game
    useEffect(() => {
        // Blink for {blinkingTimes} times
        if (finished && blinkedCount < blinkingTimes) {
            timeoutIdRef.current = setTimeout(() => {
                setBlinkedCount(blinkedCount + 1);
                setWrongVisible(wrongVisible => !wrongVisible);
                setCorrectVisible(correctVisible => !correctVisible);
            }, 800)

            return () => clearTimeout(timeoutIdRef.current);
        }
        // Find and show the correct answers after blinking
        else if (blinkedCount === blinkingTimes) {
            const matched = leftAnswers.map((leftElement, index) => ({
                leftIndex: index,
                rightIndex: rightAnswers.findIndex(rightElement => rightElement.id === leftElement.id && leftElement.isPairable),
                isValid: true
            })).filter(object => object.rightIndex > -1);

            setSubmitted(matched);
            setBlinkedCount(blinkingTimes + 1);
            setWrongVisible(true)

            draw(canvasRef.current.getContext("2d"), submitted, leftAnswers.length, rightAnswers.length, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blinkedCount, index])

    // End game
    function cleanup() {
        setSelected([-1, -1]);

        //Check which answers were not selected
        const leftModelArray = [...Array(leftAnswers.length).keys()]; // create array based on pairs amount, eg [0, 1, 2, 3] for 4 pairs
        const rightModelArray = [...Array(rightAnswers.length).keys()]; // create array based on pairs amount, eg [0, 1, 2, 3] for 4 pairs
        const leftSideSelected = [...correctAnswers[0], ...wrongAnswers[0]]
        const rightSideSelected = [...correctAnswers[1], ...wrongAnswers[1]]

        //const leftSideNotSelected = leftModelArray.filter(el => !leftSideSelected.includes(el));
        //const rightSideNotSelected = rightModelArray.filter(el => !rightSideSelected.includes(el));

        // Set their color to red or green
        for (const leftIndex of leftSideSelected) {
            const answer = leftAnswers[leftIndex];

            if (!answer.isPairable) {
                setWrongAnswers(([left, right]) => [[...left, leftIndex], right]);
            }

        }

        for (const rightIndex of rightSideSelected) {
            const answer = rightAnswers[rightIndex];

            if (!answer.isPairable) {
                setWrongAnswers((([left, right]) => [left, [...right, rightIndex]]));
            }

        }

        // Set their color to red or green
        for (const leftIndex of leftModelArray) {
            const answer = leftAnswers[leftIndex];

            if (answer.isPairable) {
                setCorrectAnswers(([left, right]) => [[...left, leftIndex], right]);
            }

        }

        for (const rightIndex of rightModelArray) {
            const answer = rightAnswers[rightIndex];

            if (answer.isPairable) {
                setCorrectAnswers((([left, right]) => [left, [...right, rightIndex]]));
            }

        }

        setBlinkedCount(1)
    }

    useLayoutEffect(() => {
        if (canvasRef.current) {
            draw(canvasRef.current.getContext("2d"), submitted, leftAnswers.length, rightAnswers.length, wrongVisible, correctAnswers)
        }
    });

    const createState = (index, side) => {
        const state = {
            unselected: 0,
            selected: 1,
            correct: 2,
            wrong: 3
        };

        if (selected[side] === index) {
            return state.selected;
        } else if (correctAnswers[side].includes(index)) {
            return state.correct;
        } else if (wrongAnswers[side].includes(index)) {
            return state.wrong;
        }
    }

    const largeImages = leftAnswers.length !== 4 && rightAnswers.length !== 4;

    return (
        <div className="screenContentContainer pairQuizScreen">
            {!!title && <h2><Text>{title}</Text></h2>}
            {!!text && <h4 className="pairQuizHeader"><Text>{text}</Text></h4>}
            <div className="pairQuizAnswers">
                <div id="leftSide">
                    {leftAnswers.map((answer, index) => <AnswerTile key={"l_" + index} large={largeImages} state={createState(index, 0)} isRight={false} answer={answer} index={index} clickHandler={clickHandler} wrongVisible={wrongVisible} correctVisible={correctVisible} />)}
                </div>
                <canvas id="canvas" ref={canvasRef} />
                <div id="rightSide">
                    {rightAnswers.map((answer, index) => <AnswerTile key={"r_" + index} large={largeImages} state={createState(index, 1)} isRight={true} answer={answer} index={index} clickHandler={clickHandler} wrongVisible={wrongVisible} correctVisible={correctVisible} />)}
                </div>
            </div>
        </div>
    );

    // Set selected answers in state
    function pairAnswers(leftIndex, rightIndex) {

        const isValid = leftAnswers[leftIndex].id === rightAnswers[rightIndex].id && leftAnswers[leftIndex].isPairable;

        setSubmitted(a => [...a, {
            leftIndex,
            rightIndex,
            isValid
        }]);

        if (isValid) {
            dispatch(addPointsForScreen(leftAnswers[leftIndex].points, index));
            setCorrectAnswers(([left, right]) => [[...left, leftIndex], [...right, rightIndex]]);
        } else {
            dispatch(addPointsForScreen(0, index));
            setWrongAnswers(([left, right]) => [[...left, leftIndex], [...right, rightIndex]]);
        }

        setSelected([-1, -1]);
    }

};

function draw(ctx, selectedPairs, leftCount, rightCount, wrongVisible, correctVisible) {
    const dpi = window.devicePixelRatio;
    const height = ctx.canvas.height / dpi;
    const width = ctx.canvas.width / dpi;

    const lineWidth = 3;

    const leftTileSize = height / leftCount;
    const rightTileSize = height / rightCount;

    const leftHalfHeight = leftTileSize / 2 + lineWidth / 2;
    const rightHalfHeight = rightTileSize / 2 + lineWidth / 2;


    ctx.clearRect(0, 0, width, height);
    for (const pair of selectedPairs) {
        ctx.beginPath();
        if (pair.isValid) {
            ctx.strokeStyle = "#90d63f"
        } else if (wrongVisible) {
            ctx.strokeStyle = "#ff3e45";
        } else {
            ctx.strokeStyle = "rgba(0,0,0,0)"
        }
        ctx.lineWidth = lineWidth;
        ctx.moveTo(-lineWidth, leftTileSize * pair.leftIndex + leftHalfHeight);
        ctx.lineTo(width + lineWidth, rightTileSize * pair.rightIndex + rightHalfHeight);
        ctx.stroke();
        ctx.closePath();
    }
}

export default PairQuizScreenContent;
