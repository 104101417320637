import React from 'react';

import CanvasEnvironment from '../commonElements/CanvasEnvironment';
import WithTimerScreen from "../../WithTimerScreen/WithTimerScreen";
import LabyrinthGameEngine from './LabirynthGameEngine';

const LabyrinthGameScreen = ({ active, data, index }) => <WithTimerScreen
    active={active}
    data={data}
    index={index}
    gameContent={endGame => <CanvasEnvironment
        active={active}
        data={data}
        Engine={LabyrinthGameEngine}
        canvasWidthModifier={1}
        canvasHeightModifier={1}
        endGame={endGame}
    />
    }
/>


export default LabyrinthGameScreen;
