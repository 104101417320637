import React, { useState, useEffect } from 'react';

import ListElement from "./ListElement";

import '../../config/globalStyles.scss';
import './sliderQuizScreen.scss';
import { getImagePath } from '../../config/helpers';
import Text from '../TextContainer/Text';

const SliderQuizScreenContent = ({
    index,
    data,
    endGame,
    isEnd = false
}) => {
    const { slideQuizScreen } = data;
    const { title, text, graphicPath, slideQuizAnswers: answers } = slideQuizScreen;
    const [counter, setCounter] = useState(Array(answers.length).fill(0));
    const [finish, setFinish] = useState(false);

    // Count answers and finish if all options are selected
    function countAnswers(isChosen, index) {
        setCounter(array => {
            array[index] = isChosen ? 1 : 0;
            return array;
        })

        const count = counter.reduce((p, n) => p + n);
        if (count === answers.length) {
            endGame();
        }

    }

    useEffect(() => {
        if (isEnd) {
            setFinish(true);
        }
    }, [isEnd])

    return (
        <div className="screenContentContainer sliderQuizScreen">
            {!!title && <h2><Text>{title}</Text></h2>}
            {!!text && <h4><Text>{text}</Text></h4>}
            {graphicPath ? (
                <img className="sliderQuizImage" src={getImagePath(graphicPath)} alt="" />
            ) : null}
            <div className="sliderQuizContent">
                {answers.map((content, it) => <ListElement key={it} content={content} index={index} position={it} countAnswers={countAnswers} isFinished={finish} />)}
            </div>
        </div>
    );
};



export default SliderQuizScreenContent;
