import React, { useState, useEffect, useLayoutEffect } from 'react'
import { commonImages } from "../../../config/images";

import { useSelector } from "react-redux";
import { selectCurrentScreenData, getGlobalConfig } from '../../../store/reducers/mainReducer';
import Text from '../../TextContainer/Text';

function IdleOverlay({ hidden, timeout }) {
    const [idle, setIdle] = useState(false);
    const [visible, setVisible] = useState(false);

    const { completed, screen_type } = useSelector(selectCurrentScreenData);
    const { idle_text = "swipe" } = useSelector(getGlobalConfig) || {};


    useEffect(() => {
        const id = setTimeout(() => {
            if (idle) {
                setVisible(true);
            }
        }, timeout);

        return () => clearTimeout(id);
    }, [idle, visible, timeout]);

    useLayoutEffect(() => {
        const touchStart = () => setIdle(false);
        const touchEnd = () => {
            setIdle(true);
            setVisible(false);
        }

        document.addEventListener("touchstart", touchStart);
        document.addEventListener("touchend", touchEnd);

        return () => {
            document.removeEventListener("touchstart", touchStart);
            document.removeEventListener("touchend", touchEnd);
        }
    }, [])

    useLayoutEffect(() => {
        if (completed) {
            setIdle(true);
            setVisible(false);
        }
    }, [completed])

    const { swipeHand } = commonImages;
    return (
        <div hidden={!(completed || screen_type === "navigation") || !visible || hidden || window.top !== window.self} className="idleOverlay">
            <div className="idleOverlayHand">
                <div className="idleOverlayText"><Text>{idle_text}</Text></div>
                <img className="idleOverlayImage" src={swipeHand} alt="" />
            </div>
        </div>
    )
}

export default IdleOverlay
