import audio from "../../config/audio";
import React from 'react'
import Text from '../TextContainer/Text';


function TextAnswer({ answer, index, finished, handleTouch, selectedAnswers }) {
    let tileStyle = "quiz__answers__answer", textStyle = "quiz__answers__answer--text";

    const { points, text } = answer;

    if (finished && points) {
        tileStyle += " quiz__answers__answer--correct";
    }
    else if (!finished && selectedAnswers.includes(index)) {
        tileStyle += " quiz__answers__answer--selected";
    }
    else if (finished && selectedAnswers.includes(index)) {
        tileStyle += " quiz__answers__answer--wrong"
    }
    else {
        textStyle += " colorDark"
    }

    return (
        <div
            onClick={() => {
                if (!finished) {
                    handleTouch(index, points);
                    audio.play("click");
                }
            }}
            className={tileStyle}>
            <h3 className={textStyle}>
                <Text>{text}</Text>
            </h3>
        </div>
    )
}

export default TextAnswer
