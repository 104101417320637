import React from 'react'
import { useSelector } from "react-redux";

import './AnimalSummaryScreen.scss';
import { getImagePath, getPluralForm } from '../../../config/helpers';
import { selectTotalEarnedPoints, getGlobalConfig } from '../../../store/reducers/mainReducer';

import { commonImages } from "../../../config/images";

import Text from '../../TextContainer/Text';
import SwipeContainer from '../SwipeContainer';

function AnimalSummaryScreen({ data, animalIndex, setFacefilter }) {
    const globalConfig = useSelector(getGlobalConfig);
    const totalEarnedPoints = useSelector(selectTotalEarnedPoints);

    const { animalProfileScreen } = data;

    const {
        text_1,
        text_2,
        animalProfiles
    } = animalProfileScreen;

    const {
        points_1,
        points_2_4,
        points_5
    } = globalConfig;

    const { graphicPath, text_under_profile, title } = animalProfiles[animalIndex];

    const { iconCoin } = commonImages;

    return (
        <div className="screenContentContainer animalProfileScreen">
            <h2 className="pointsHeader"><Text>{text_1}</Text></h2>
            <div className="pointsImage">
                <img src={iconCoin} alt="" />
            </div>
            <h1 className="pointsAmount"><Text>{totalEarnedPoints}</Text></h1>
            <h3 className="pointsText"><Text>{getPluralForm(totalEarnedPoints, "pl", points_1, points_2_4, points_5)}</Text></h3>
            <h2 className="profileHeader"><Text>{text_2}</Text></h2>
            <div className="profileImage">
                <img src={getImagePath(graphicPath)} alt="animal" />
                <h2 className="profileText"><Text>{title}</Text></h2>
            </div>
            <p className="profileFooter"><Text>{text_under_profile}</Text></p>
            <SwipeContainer setFacefilter={setFacefilter} />
        </div>
    );
}

export default AnimalSummaryScreen
