import React, { useEffect, useRef } from 'react'
import Jeeliz from "facefilter";
import "facefilter/helpers/Canvas2DDisplay";
import JeelizResizer from "facefilter/helpers/JeelizResizer";
import NNC from "facefilter/dist/NNC.json";

import filters from "../../../../config/filters";
import { filterImages } from "../../../../config/images";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../store/reducers/mainReducer";

class FilterFragment {
    constructor({ ctx, position, expression, isStatic = false }) {
        this.isStatic = isStatic;
        this.loaded = false;
        this.partsLoaded = 0
        this.images = ctx.keys().map(el => {
            const img = new Image();
            img.onload = () => {
                this.partsLoaded++;
                if (this.partsLoaded === ctx.keys().length) {
                    this.loaded = true;
                }
            }
            img.src = ctx(el);
            return img;
        });
        this.position = position;
        this.frame = 0;
        this.expression = expression
    }

    isWithin(value) {
        const exp = this.expression;
        if (exp !== undefined) {
            return value >= exp[0] && value <= exp[1];
        }
        else {
            return true;
        }
    }

    drawFrame(ctx, posX, posY, w, h, side) {
        if (this.loaded) {
            const [x, y] = this.position(side, w, h);
            const img = this.images[this.frame];
            ctx.drawImage(img, posX + x, posY + y, w, h);
            this.frame++;
            if (this.frame >= this.images.length) {
                this.frame = 0;
            }
        }
    }
}

const cache = {
    global: [],
    list: []
};

function Canvas({ icons, initial, selected }) {
    const canvasRef = useRef(null);
    const filterRef = useRef(selected);
    const miniatureRef = useRef(new Image());
    const initialLanguage = useSelector(getLanguage);

    useEffect(() => {
        filterRef.current = selected;
        miniatureRef.current.src = icons[selected];
        if (!cache.list[selected]) {
            cache.list[selected] = filters.list[selected].parts.map(part => new FilterFragment(part));
        }
    }, [selected, icons])

    useEffect(() => {
        let display;
        const frameImage = new Image();
        frameImage.src = filterImages.frame;
        cache.global = filters.global.map(filter => filter.parts.map(part => new FilterFragment(part)))[0];
        JeelizResizer.size_canvas({
            overSamplingFactor: window.devicePixelRatio,
            canvas: canvasRef.current,
            callback: function (isError, { idealWidth }) {
                Jeeliz.init({
                    videoSettings: { idealWidth, idealHeight: idealWidth * (3 / 2) },
                    canvas: canvasRef.current,
                    followZRot: true,
                    NNC,
                    callbackReady(errCode, spec) {
                        if (errCode) {
                            alert(`Nie możesz korzystać z nakładki na Twoim urządzeniu: ${errCode}.`);
                        } else {
                            display = Jeeliz.Canvas2DDisplay(spec);
                            display.ctx.strokeStyle = 'yellow';
                            Jeeliz.resize();
                        }
                    },

                    callbackTrack(detectState) {
                        const coordinates = display.getCoordinates(detectState);
                        const { ctx } = display;
                        const { width, height } = display.canvas;
                        const { s, rz } = detectState;
                        const f = s * width;

                        const renderFilter = isDetected => {
                            ctx.rotate(Math.PI);
                            if (cache.list[filterRef.current].length) {
                                const fragments = cache.list[filterRef.current].concat(cache.global);
                                for (const fragment of fragments) {
                                    if (fragment.isWithin(detectState.expressions[0]) || fragment.expression === undefined) {
                                        if (fragment.isStatic) {
                                            ctx.rotate(-rz);
                                        }
                                        const modelImage = fragment.images[0];
                                        const size = [modelImage.width, modelImage.height];
                                        const width = (size[0] / 270) * display.canvas.width * s;
                                        const height = width * (size[1] / size[0]);
                                        const side = isDetected ? f : display.canvas.width / 3;
                                        fragment.drawFrame(ctx, -side / 2, -side / 2, width, height, side);
                                        if (fragment.isStatic) {
                                            ctx.rotate(rz);
                                        }
                                    }
                                }
                            }
                            ctx.rotate(-Math.PI);
                        }

                        ctx.clearRect(0, 0, width, height);

                        if (detectState.detected > 0.8) {
                            ctx.translate(coordinates.x + coordinates.w / 2, coordinates.y + coordinates.h / 2);
                            ctx.rotate(rz);
                            renderFilter(true);
                            ctx.rotate(-rz);
                            ctx.translate(-coordinates.x - coordinates.w / 2, -coordinates.y - coordinates.h / 2);
                        } else {
                            ctx.translate(width / 2, height / 2);
                            renderFilter(false);
                            ctx.translate(-width / 2, -height / 2);
                        }
                        ctx.translate(display.canvas.width / 2, display.canvas.height / 2)
                        ctx.rotate(Math.PI)
                        ctx.drawImage(frameImage, -display.canvas.width / 2, -display.canvas.height / 2 - 1, display.canvas.width, display.canvas.height + 2);
                        const miniatureSize = display.canvas.height * 0.07;
                        ctx.drawImage(miniatureRef.current, display.canvas.width * 0.35, display.canvas.height * 0.40, miniatureSize, miniatureSize);
                        ctx.rotate(-Math.PI)
                        ctx.translate(-display.canvas.width / 2, -display.canvas.height / 2)

                        function renderText(text, width) {
                            function joinLines(words) {
                                const final = [words[0]];

                                for (const [index, word] of words.entries()) {
                                    if (index === 0) continue;
                                    if (ctx.measureText(final[final.length - 1] + word).width < width) {
                                        final[final.length - 1] += ` ${word}`;
                                    } else {
                                        final.push(word);
                                    }
                                }
                                return final;
                            }

                            const list = text.trim().split(" ");
                            const greetings = list.shift();

                            const percent = 15;

                            ctx.translate(display.canvas.width, display.canvas.height);
                            ctx.translate(-display.canvas.width * percent / 100, -display.canvas.width * 7 * percent / 100);
                            ctx.rotate(Math.PI + 0.3)

                            const font = {
                                smaller: Math.round(display.canvas.width / 22),
                                bigger: Math.round(display.canvas.width / 16)
                            }

                            ctx.font = `${font.smaller}px "Paytone one"`;
                            ctx.fillStyle = "#3F90F5";
                            ctx.fillRect(-10, -font.smaller, ctx.measureText(greetings).width + 20, font.smaller * (3 / 2) + 1)//(font.smaller * 11/10) + 1)

                            ctx.fillStyle = "white";
                            ctx.fillText(greetings, 0, 0)

                            ctx.font = `${font.bigger}px "Paytone one"`

                            const lines = joinLines(list);

                            for (const [index, line] of lines.entries()) {
                                ctx.fillStyle = "#3FA9F5";
                                ctx.fillRect(-10, font.smaller / 2 + index * (font.bigger * (3 / 2)), ctx.measureText(line).width + 20, font.bigger * (3 / 2) + 1);
                                ctx.fillStyle = "white"
                                ctx.fillText(line, 0, font.smaller / 2 + index * (font.bigger * (3 / 2)) + font.bigger)
                            }

                            ctx.rotate(-Math.PI - 0.3);
                            ctx.translate(display.canvas.width * percent / 100, display.canvas.width * 7 * percent / 100);
                            ctx.translate(-display.canvas.width, -display.canvas.height);
                        }

                        if(initialLanguage==="en-US"){
                            renderText("Greetings from Gdynia Aquarium!", display.canvas.width / 2)
                        } else {
                            renderText("Pozdrowienia z Akwarium Gdyńskiego", display.canvas.width / 2)
                        }



                        display.update_canvasTexture();
                        display.draw();
                    }
                });
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <canvas className="facefilterCanvas" width="200" height="300" ref={canvasRef} />
    )
}

export default Canvas
