import React from 'react';

import '../../config/globalStyles.scss';
import './CrocodileQuizScreen.scss';
import { CrocodileQuizScreenContent } from './CrocodileQuizScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

const CrocodileQuizScreen = ({ active, data, index }) => <WithTimerScreen
    active={active}
    data={data}
    index={index}
    gameContent={endGame => <CrocodileQuizScreenContent data={data} index={index} endGame={endGame} />}
/>


export default CrocodileQuizScreen;