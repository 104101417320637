import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../config/globalStyles.scss';
import {
    setCompletedStatus,
    setTimerStatus,
    changeScreen
} from '../../store/actions';

import { setSwipeAvailable } from '../../store/actions/mainActions';
import { selectCurrentScreenData } from '../../store/reducers/mainReducer';

const WithTimerScreen = ({
    active,
    index,
    gameContent,
    timeOutContent,
    winContent,
    canSwipe = false
}) => {

    const dispatch = useDispatch();

    const currentScreenData = useSelector(selectCurrentScreenData);

    const { earnedPoints, completed } = currentScreenData;
    
    useEffect(() => {
        if (!canSwipe && active) {
            dispatch(setSwipeAvailable(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canSwipe, index, dispatch, active]);

    const endHandler = () => {
        if(active) {
            dispatch(setCompletedStatus(true, index));
            dispatch(setTimerStatus('end', index));
            dispatch(setSwipeAvailable(true));
        }
    }

    let content = gameContent(endHandler);
    if (completed && active) {
        if(earnedPoints > 0 && winContent) {
            content = winContent;
        } else if(!earnedPoints && timeOutContent) {
            content = timeOutContent;
        }
    }

    useEffect(() => {
        if (completed && active) {
            if(!(earnedPoints > 0 && winContent) && !(!earnedPoints && timeOutContent)) {
                dispatch(changeScreen);
            } 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [completed, dispatch, earnedPoints, timeOutContent, winContent])


    return <>{content}</>;
};
export default WithTimerScreen;
