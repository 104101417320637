import React, { useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addPointsForScreen } from "../../store/actions/";

import '../../config/globalStyles.scss';
import './quizScreen.scss';
import { getImagePath, select } from '../../config/helpers';
import TextAnswer from './TextAnswer';
import GraphicAnswer from './GraphicAnswer';
import { getCurrentScreenIndex, getGlobalConfig } from '../../store/reducers/mainReducer';
import Text from '../TextContainer/Text';

const QuizScreenContent = ({
    active,
    data,
    endGame,
    isEnd = false
}) => {
    const dispatch = useDispatch();

    const currentScreenIndex = useSelector(getCurrentScreenIndex);
    const globalConfig = useSelector(getGlobalConfig);
    const { correct_answers_count: correct_answers_count_text } = globalConfig || {};
    const { quizScreen } = data;

    const [finished, setFinished] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [points, setPoints] = useState(0);
    const [id, setId] = useState(currentScreenIndex);

    const {
        type,
        title,
        question,
        graphicPath,
        quizAnswers = [],
        display_graphic_next_to_text = 0
    } = quizScreen;

    const correctAnswersCount = useMemo(() => quizAnswers.reduce((p, c) => p + !!c.points, false), [quizAnswers]);
    const countClassName = useMemo(() => {
        if (type === "graphic_answers-select_area") {
            return "select-area";
        }
        if (quizAnswers.length === 1) {
            return "1";
        }
        else if (quizAnswers.length === 2) {
            return "2";
        }
        else if (quizAnswers.length === 3) {
            return "3";
        }
        else if (quizAnswers.length === 4) {
            return "4";
        }
        else {
            return "5-9";
        }
    }, [quizAnswers.length, type]);

    useEffect(() => {
        setId(currentScreenIndex);
        setPoints(0);
        setSelectedAnswers([]);
        setFinished(false);
    }, [currentScreenIndex])

    const handleAnswerChoise = (index, points) => {
        if (selectedAnswers.includes(index)) {
            setSelectedAnswers(array => array.filter(c => c !== index));
            setPoints(p => p - points);
        }
        else {
            setSelectedAnswers(array => [...array, index]);
            setPoints(p => p + points);
        }
    };

    useLayoutEffect(() => {
        if (selectedAnswers.length === correctAnswersCount && currentScreenIndex === id && !isEnd && endGame) {
            endGame();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAnswers, correctAnswersCount, currentScreenIndex, id, isEnd]);

    useEffect(() => {
        if (isEnd) {
            setFinished(true);
        }
    }, [isEnd]);

    useEffect(() => {
        if (finished && active) {
            dispatch(addPointsForScreen(points, currentScreenIndex));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finished, currentScreenIndex]);

    const renderQuizAnswer = (answer, index) => {
        return select(type, {
            text_answers: <TextAnswer key={answer.text} answer={answer} index={index} selectedAnswers={selectedAnswers} finished={finished} handleTouch={handleAnswerChoise} />,
            graphic_answers: <GraphicAnswer key={answer.graphicPath} answer={answer} index={index} selectedAnswers={selectedAnswers} finished={finished} handleTouch={handleAnswerChoise} />,
            "graphic_answers-select_area": <GraphicAnswer key={answer.graphicPath} answer={answer} index={index} selectedAnswers={selectedAnswers} finished={finished} handleTouch={handleAnswerChoise} />,
            default: null
        });
    };

    const answerClassName = select(type, {
        graphic_answers: "image-answers",
        text_answers: "text-answers",
        default: "select-area"
    })

    const ClassName = function (initial) {
        let className = initial;

        this.add = function (newClassName) {
            const classes = className.split(" ");
            const last = classes[classes.length - 1];

            className += " " + (last + "--" + newClassName);
        }

        this.get = () => className;
    }


    let quizClassName = new ClassName("quiz");
    if (type === "graphic_answers-select_area") {
        quizClassName.add("select-area");
    }
    else {
        if (display_graphic_next_to_text === 0) {
            quizClassName.add("column")
            quizClassName.add(answerClassName);
            if (graphicPath || correctAnswersCount !== 1) {
                quizClassName.add("image");
            }
            else {
                quizClassName.add("no-image");
            }
        }
        else {
            quizClassName.add("row");
            if (graphicPath) {
                quizClassName.add("image");
            }
            else {
                quizClassName.add("no-image");
            }

            if (correctAnswersCount === 1) {
                quizClassName.add("single");
            } else {
                quizClassName.add("multiple");
            }
        }
    }

    return (
        <div className={`screenContentContainer ${quizClassName.get()}`}>
            <div className="quiz__header">
                {title ? <h2 className="quiz__header__title"><Text>{title}</Text></h2> : null}
                {question ? <h4 className="quiz__header__question"><Text>{question}</Text></h4> : null}
                {correctAnswersCount !== 1 ? (
                    <p className="quiz__question__count">
                        <Text>{correct_answers_count_text.length>4?correct_answers_count_text.substring(0, correct_answers_count_text.length - 4):correct_answers_count_text} {correctAnswersCount}{correct_answers_count_text.length>4?"</p>":""}</Text>
                    </p>
                ) : null}
            </div>
            {graphicPath ? (
                <img className={`quiz__question__image quiz__question__image--${display_graphic_next_to_text === 0 ? "above" : "next"}`} src={getImagePath(graphicPath)} alt="" />
            ) : null}
            <div className={`quiz__answers quiz__answers--${display_graphic_next_to_text === 0 ? "under" : "next"} quiz__answers--${countClassName} quiz__answers--${answerClassName} quiz__answers--${answerClassName}--${countClassName}`}>
                {quizAnswers.map((quizAnswer, index) => renderQuizAnswer(quizAnswer, index))}
            </div>
        </div>
    );
};

export default QuizScreenContent;
