import { Howl, Howler } from "howler";
import { getImagePath } from "./helpers";

class Audio {
    initialize(sources = []) {
        this.howls = [];

        for (const source of sources) {
            this.howls[source.code] = {
                isActive: source.is_active,
                howl: new Howl({
                    src: [getImagePath(source.soundPath)]
                })
            };
        }
    }

    play(code) {
        if (this.howls&&this.howls[code].isActive) {
            this.howls[code].howl.play();
        }
        else {
            console.warn(`Sound (${code}) is inactive`);
        }
    }

    stop(code) {
        if (this.howls[code].isActive) {
            this.howls[code].howl.stop();
        }
        else {
            console.warn(`Sound (${code}) is inactive`);
        }
    }

    isPlaying(code) {
        if (this.howls[code].isActive) {
            return this.howls[code].howl.playing();
        }
        else {
            console.warn(`Sound (${code}) is inactive`);
            return false;
        }
    }
}

Howler.autoUnlock = true;

export default new Audio();
