import React, {useState, useEffect, useRef} from 'react'
import "./MapPopup.scss";

import {useSelector} from 'react-redux';
import {
  getMapData,
} from '../../../store/reducers/mainReducer';
import {commonImages} from "../../../config/images";
import {getImagePath} from "../../../config/helpers";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import MapButton from "./MapButton";
import CloseButton from "./CloseButton";

function MapPopup() {

  const ref = useRef(null);
  const mapData = useSelector(getMapData) || {};

  const [perspectiveMapMode, setPerspectiveMapMode] = useState(false);

  useEffect(() => {
    const div = ref.current;
    if (div) {
      if (div.classList.length === 0) {
        div.classList.add("current-screen-exit-done")
      }
    }
    return () => {
      if (div) {
        div.classList.remove("current-screen-exit-done")
      }
    };
  });

  return (
    <div ref={ref}>
      <div
        className="map__overlay screenSection">
        <div className="map__closeButton">
          <CloseButton/>
        </div>
        <div hidden={!perspectiveMapMode}>
          <TransformWrapper
            enablePadding={false}
            options={{maxScale: 3}}
            scalePadding={{/*size:0,*/ disabled: true}}
            pan={{paddingSize: 0}}
          ><TransformComponent>
            <div className="map__transformable">
              <img
                src={getImagePath(mapData.perspectiveGraphicPath)}
                alt="Mapa"
                className="map__transformable__image"
              />
            </div>
          </TransformComponent>
          </TransformWrapper>
        </div>
        <div hidden={perspectiveMapMode}>
          <TransformWrapper
            enablePadding={false}
            options={{maxScale: 3}}
            scalePadding={{/*size:0,*/ disabled: true}}
            pan={{paddingSize: 0}}
          ><TransformComponent>
            <div className="map__transformable">
              <img
                src={getImagePath(mapData.graphicPath)}
                alt="Mapa"
                className="map__transformable__image"
              />
            </div>
          </TransformComponent>
          </TransformWrapper>
        </div>
        <MapButton iconMapSrc={perspectiveMapMode ? commonImages.iconMap2D : commonImages.iconMap3D}
                   onClick={() => setPerspectiveMapMode(!perspectiveMapMode)}/>
      </div>
    </div>
  )
}

export default MapPopup
