import React from 'react';

import '../../config/globalStyles.scss';

import StartScreen from './StartScreen/StartScreen';
import CheckboxScreen from './CheckboxScreen/CheckboxScreen';
import { select } from '../../config/helpers';

const InfoScreen = ({active, data}) => {
    const { type } = data.infoScreen;
    const content = select(type, {
        start: <StartScreen active={active} data={data}/>,
        withCheckbox: <CheckboxScreen active={active} data={data}/>,
        default: <p>Error. Info screen type is not correct (infoScreen.type:{' '}{type}).</p>
    });

    return <>{content}</>
};

export default InfoScreen;
