import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { useDispatch } from "react-redux";
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { addPointsForScreen } from "../../store/actions";
import { getImagePath, select } from '../../config/helpers';
import { swipeImages } from "../../config/images";
import Text from '../TextContainer/Text';

// Spring data, values that are later being interpolated into css
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const transform = s => `scale(${s})`

const CurrentAnswer = {
    none: null,
    correct: 1,
    wrong: 2
}

const lowerBoundary = 45;
const step = 15;

export const CrocodileQuizScreenContent = ({ data, index, endGame }) => {
    const dispatch = useDispatch();

    const { swipeQuizScreen } = data;
    const { title, text, swipeQuizAnswers: items } = swipeQuizScreen;

    const { arrowsUpDown, plank } = swipeImages;

    // Save memorized arrays
    const cards = useMemo(() => items.map(el => getImagePath(el.graphicPath)), [items]);
    const points = useMemo(() => items.map(el => el.points).reverse(), [items]);
    const correctAnswers = useMemo(() => items.map(el => el.good_direction), [items]);
    const initialPosition = i => ({ x: (window.innerWidth * 0.25) * (cards.length - i - 1), y: 0, scale: 1, opacity: 1, zIndex: 5, rot: 0, delay: i * 100 })

    // Set up spring variables 
    const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
    const [props, set] = useSprings(cards.length, i => ({ ...initialPosition(i), from: initialPosition(i) })) // Create a bunch of springs using the helpers above

    // Set up comopnent states
    const [goneCount, setGoneCount] = useState(0);
    const [submittedAnswers, setSubmittedAnswers] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState(CurrentAnswer.none);


    // Create gesture handler
    const bind = useGesture(({ args, initial, delta, down, xy, currentTarget }) => {
        const [index] = args;

        if (gone.has(index)) return;

        const cardHeight = parseFloat(window.getComputedStyle(currentTarget).height);

        // Check if card is higher than  half of it's height
        const trigger = Math.abs(delta[1]) > cardHeight / 2;

        // Check in what direction card is gonna fly away
        let direction = 0;
        if (delta[1] > 0) {
            direction = 1;
        } else if (delta[1] < 0) {
            direction = -1;
        }

        // Set shadow on lift
        setGoneCount(gone.size + (down ? 1 : 0));

        if (!down && trigger) {
            // If finger is up and trigger is fired make it dissapear from the screen
            gone.add(index)
            setGoneCount(gone.size);
            setSubmittedAnswers(answers => [...answers, {
                correct: (direction === 1 && correctAnswers[index] === 1) || (direction === -1 && correctAnswers[index] === 0)
            }]);

            setTimeout(() => {
                set(i => {
                    const isGone = gone.has(i)
                    const goneNumber = gone.size;
                    const x = isGone ? 0 : (goneNumber + i - cards.length + 1) * -window.innerWidth / 4;

                    return { x };
                })
            }, 1000)
        }
        set(i => {
            // Look for current spring and check if it's on the table

            if (index !== i) {

            } else {
                const isGone = gone.has(index)

                // Move it around y-axis 
                const y = isGone ? (window.innerHeight / 2 - 0.17 * window.innerHeight) * direction : down ? xy[1] - initial[1] : 0;

                // On lift scale it up a bit
                const scale = down ? 1.2 : 1;

                return { y, iy: window.innerHeight * 0.23625 - y, opacity: isGone ? 0 : 100, zIndex: isGone ? -1 : 6, scale, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
            }
        })

    })

    // Set arrow colors

    const content = select(currentAnswer, {
        [CurrentAnswer.correct]: points[submittedAnswers.length - 1],
        [CurrentAnswer.wrong]: 0,
        default: "L"
    });

    const color = select(currentAnswer, {
        [CurrentAnswer.correct]: "#90d63f",
        [CurrentAnswer.wrong]: "#ff3e45",
        default: "#000"
    });

    useEffect(() => {
        dispatch(addPointsForScreen(0, index))
    }, [dispatch, index])

    useEffect(() => {
        if (submittedAnswers.length === cards.length) {
            setTimeout(() => {
                endGame();
            }, 1500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedAnswers.length])

    useLayoutEffect(() => {
        const last = submittedAnswers[submittedAnswers.length - 1] || {};
        const currentPoints = points[submittedAnswers.length - 1];

        let answer = CurrentAnswer.none;

        if (last.correct === true) {
            answer = CurrentAnswer.correct
            dispatch(addPointsForScreen(currentPoints, index))
        }
        else if (last.correct === false) {
            answer = CurrentAnswer.wrong
        }

        setCurrentAnswer(answer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedAnswers]);

    return <div className="screenContentContainer crocodileQuizScreen">
        {!!title && <h2><Text>{title}</Text></h2>}
        {!!text && <h4><Text>{text}</Text></h4>}
        
        <img className="plankImage" src={plank} alt="" />
        <img className="trashImage" src={getImagePath(swipeQuizScreen.topGraphicPath)} alt="trash" />


        <div className="crocodileQuizGame">
            <div className="crocodileQuizControls">
                <img className="crocodileQuizArrows" src={arrowsUpDown} alt="" />
            </div>
            {props.map(({ x, y, iy, opacity, scale, zIndex }, i) => (
                <animated.div key={i} style={{ left: x, top: y, opacity, zIndex }} className="crocodileQuizAnimatable">
                    <animated.div style={{ top: iy, opacity, backgroundColor: cards.length - i < gone.size + 1 ? color : "white" }} className="crocodileQuizCircle">
                        <div className="crocodileQuizPoints"><Text>{cards.length - i < gone.size + 1 ? content : ""}</Text></div>
                    </animated.div>
                    <animated.div {...bind(i)} className="crocodileQuizCard" style={{
                        transform: interpolate([scale], transform),
                        backgroundImage: `url(${cards[i]})`,
                        borderColor: `hsl(200, 50%, ${lowerBoundary + step * (i + goneCount + 1)}%`
                    }} />
                </animated.div>
            ))}
        </div>
        <img className="crocodileImage" src={getImagePath(swipeQuizScreen.bottomGraphicPath)} alt="crocodile" />
    </div>
};