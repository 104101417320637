import React from 'react';

import ScrollGameEngine from './ScrollGameEngine';
import WithTimerScreen from '../../WithTimerScreen/WithTimerScreen';

const ScrollGameScreen = ({ active, data, index }) => <WithTimerScreen
    active={active}
    data={data}
    index={index}
    gameContent={endGame => <ScrollGameEngine active={active} data={data} index={index} isWon={false} endGame={endGame} />}
    winContent={<ScrollGameEngine active={active} data={data} index={index} isWon={true} />}
/>



export default ScrollGameScreen;
