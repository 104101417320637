import React from 'react'
import CloseButton from './CloseButton'

function HeaderBar() {
    return (
        <div className="facefilterHeader">
            <CloseButton />
        </div>
    )
}

export default HeaderBar
