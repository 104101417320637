import React from 'react';

import SliderQuizScreenContent from './SliderQuizScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

const SliderQuizScreen = ({ active, data, index }) => <WithTimerScreen
    index={index}
    active={active}
    data={data}
    gameContent={endGame => <SliderQuizScreenContent index={index} data={data} endGame={endGame} />}
    timeOutContent={<SliderQuizScreenContent index={index} data={data} isEnd={true} />}
    winContent={<SliderQuizScreenContent index={index} data={data} isEnd={true} />}
/>

export default SliderQuizScreen;
