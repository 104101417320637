import React from 'react'
import GameScore from '../elements/GameScore';
import CuriosityButton from '../elements/CuriosityButton';
import LanguageButton from '../elements/LanguageButton';
import ProgressBar from '../elements/ProgressBar';
import MapButton from '../elements/MapButton';
import { useSelector } from 'react-redux';
import { selectCurrentScreenData } from '../../../store/reducers/mainReducer';

function Footer({ active, data, winLosePopup, curiosityPopup, timeStartPopup }) {
    const currentScreenData = useSelector(selectCurrentScreenData);

    const {
        curiosityPopup: curiosityContent,
        screen_type,
        gameScreen = {},
        infoScreen = {}
    } = data;

    const { type: gameType = "" } = gameScreen;
    const { type: infoType = "" } = infoScreen;

    return (
        !winLosePopup && !curiosityPopup && (
            <div className="screenSection screenSection--footer">
                {!timeStartPopup && active && screen_type === "game" && gameType !== "scroll_game" && gameType !== "puzzle_game" ? (
                    <GameScore data={currentScreenData} />
                ) : infoType === "start" ? (
                    <LanguageButton />
                ) : curiosityContent ? (
                    <CuriosityButton />
                ) : (
                                <div className="sectionElement sectionElement--left-bottom" />
                            )}
                {
                    ["quiz", "pair_quiz", "slide_quiz", "swipe_quiz", "complete_word"].includes(screen_type) || gameType === "puzzle_game" ? (
                        <ProgressBar data={data} />
                    ) : null
                }
                {screen_type !== "animal_profile" ? <MapButton /> : null}
            </div >
        )
    );
}

export default Footer;