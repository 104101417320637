/**
 |--------------------------------------------------
 | SCREENS ACTIONS
 |--------------------------------------------------
 */
export const REQUEST_GET_SCREENS_DATA = 'REQUEST_GET_SCREEN_DATA';
export const RESPONSE_GET_SCREENS_DATA = 'RESPONSE_GET_SCREENS_DATA';
export const ERROR_GET_SCREENS_DATA = 'ERROR_GET_SCREENS_DATA';
export const GO_TO_NEXT_SCREEN = 'GO_TO_NEXT_SCREEN';
export const ADD_POINTS_FOR_SCREEN = 'ADD_POINTS_FOR_SCREEN';
export const SET_TIMER_STATUS_FOR_SCREEN = 'SET_TIMER_STATUS_FOR_SCREEN';
export const SET_COMPLETED_STATUS_FOR_SCREEN = 'SET_COMPLETED_STATUS_FOR_SCREEN';
export const SET_SCORE_FOR_SCREEN = 'SET_SCORE_FOR_SCREEN';
export const SET_WIN_LOSE_POPUP = 'SET_WIN_LOSE_POPUP';
export const SET_CURIOSITY_POPUP = 'SET_CURIOSITY_POPUP';
export const SET_START_TIME_POPUP = 'SET_START_TIME_POPUP';
export const SET_CHANGE_LANGUAGE_POPUP = 'SET_CHANGE_LANGUAGE_POPUP';
export const SET_CHANGE_MAP_POPUP = 'SET_CHANGE_MAP_POPUP';
export const SET_OFFLINE_POPUP = 'SET_OFFLINE_POPUP';
export const SET_SWIPE_AVAILABLE = 'SET_SWIPE_AVAILABLE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_PAUSE = 'SET_PAUSE';
export const RESET_STORE = 'RESET_STORE';
