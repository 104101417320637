import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getCurrentScore } from '../../../store/reducers/mainReducer';
import { commonImages } from '../../../config/images';

import "./StatisticsPopup.scss";
import { changeScreen } from '../../../store/actions';
import { select } from '../../../config/helpers';
import Text from '../../TextContainer/Text';


function StatisticsPopup({ gameType, visible }) {
    const dispatch = useDispatch();
    const score = useSelector(getCurrentScore);

    useEffect(() => {
        if(visible) {
            setTimeout(() => dispatch(changeScreen), 2000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const { elementShrimpIcon, elementFlyIcon, elementSnakeIcon, elementChest } = commonImages;

    const scoreImage = select(gameType, {
        whale_game: elementShrimpIcon,
        archerfish_game: elementFlyIcon,
        labyrinth_game: elementSnakeIcon,
        default: elementChest
    }) 

    return (
        <div className="statisticsOverlay" style={{ display: visible ? "flex" : "none"}}>
            <div className="statisticsPopup">
                <img className="statisticsIcon" src={scoreImage} alt="" />
                <p className="colorDark statisticsDivider"><Text>x</Text></p>
                <h2 className="colorSecondary statisticsPoints"><Text>{score || 0}</Text></h2>
            </div>
        </div>
    )
}

export default StatisticsPopup
