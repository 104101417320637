import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch } from "react-redux";
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import { addPointsForScreen } from "../../store/actions";
import { getImagePath, select } from '../../config/helpers';
import { swipeImages } from "../../config/images";
import Text from '../TextContainer/Text';

// Spring data, values that are later being interpolated into css
const initialPosition = i => ({ x: 0, y: 0, scale: 1, rot: 0, delay: i * 100 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const transform = s => `scale(${s})`

const CurrentAnswer = {
    none: null,
    topCorrect: 1,
    topWrong: 2,
    bottomCorrect: 3,
    bottomWrong: 4
}

const lowerBoundary = 45;
const step = 15;

export const SwipeQuizScreenContent = ({ data, index, endGame }) => {
    const dispatch = useDispatch();

    const { swipeQuizScreen } = data;
    const { title, text, bottom_text, top_text, swipeQuizAnswers: items = [] } = swipeQuizScreen;

    const {
        arrowUpCorrectGlow, arrowUpCorrect,
        arrowUpWrongGlow, arrowUpWrong,
        arrowUp, arrowDown,
        arrowDownWrongGlow, arrowDownWrong,
        arrowDownCorrectGlow, arrowDownCorrect
    } = swipeImages;

    // Save memorized arrays
    const cards = useMemo(() => [swipeImages.reverse, ...items.map(el => getImagePath(el.graphicPath))], [items]);
    const correctAnswers = useMemo(() => items.map(el => el.good_direction), [items]);
    const points = useMemo(() => items.map(el => el.points).reverse(), [items]);

    // Set up spring variables 
    const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
    const [props, set] = useSprings(cards.length, i => ({ ...initialPosition(i), from: initialPosition(i) })) // Create a bunch of springs using the helpers above

    // Set up comopnent states
    const [goneCount, setGoneCount] = useState(0);
    const [submittedAnswers, setSubmittedAnswers] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState(CurrentAnswer.none);

    // Create gesture handler
    const bind = useGesture(obj => {
        const { args, initial, delta, down, xy, currentTarget } = obj;
        const [index] = args;

        if (index === 0) return;

        const cardHeight = parseFloat(window.getComputedStyle(currentTarget).height);

        // Check if card is higher than  half of it's height
        const trigger = Math.abs(delta[1]) > cardHeight / 2;

        // Check in what direction card is gonna fly away
        let direction = 0;
        if (delta[1] > 0) {
            direction = 1;
        } else if (delta[1] < 0) {
            direction = -1;
        }

        // Set shadow on lift
        setGoneCount(gone.size + (down ? 1 : 0));

        if (!down && trigger) {
            // If finger is up and trigger is fired make it dissapear from the screen
            if(!gone.has(index)) {
                gone.add(index);
                setGoneCount(gone.size);
                setSubmittedAnswers(answers => [...answers, {
                    correct: (direction === 1 && correctAnswers[index - 1] === 1) || (direction === -1 && correctAnswers[index - 1] === 0),
                    direction
                }]);
            };
        }
        set(i => {
            // Look for current spring and check if it's on the table

            if (index !== i) return;
            const isGone = gone.has(index)

            // Move it around y-axis 
            const y = isGone ? (window.innerHeight + 200) * direction : down ? xy[1] - initial[1] : 0

            // On lift scale it up a bit
            const scale = down ? 1.05 : 1;

            return { y, scale, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
        })

    })

    const arrowUpImage = select(currentAnswer, {
        [CurrentAnswer.topCorrect]: arrowUpCorrect,
        [CurrentAnswer.topWrong]: arrowUpWrong,
        default: arrowUp
    });

    const arrowDownImage = select(currentAnswer, {
        [CurrentAnswer.bottomCorrect]: arrowDownCorrect,
        [CurrentAnswer.bottomWrong]: arrowDownWrong,
        default: arrowDown
    });

    const arrowUpGlowImage = select(currentAnswer, {
        [CurrentAnswer.topCorrect]: arrowUpCorrectGlow,
        [CurrentAnswer.topWrong]: arrowUpWrongGlow,
        default: null
    });
    const arrowDownGlowImage = select(currentAnswer, {
        [CurrentAnswer.bottomCorrect]: arrowDownCorrectGlow,
        [CurrentAnswer.bottomWrong]: arrowDownWrongGlow,
        default: null
    });

    // Set arrow colors

    useEffect(() => {
        dispatch(addPointsForScreen(0, index))
    }, [dispatch, index])

    useEffect(() => {
        if (submittedAnswers.length === cards.length - 1 && currentAnswer === CurrentAnswer.none) {
            endGame();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAnswer])

    useEffect(() => {
        const last = submittedAnswers[submittedAnswers.length - 1] || {};
        const currentPoints = points[submittedAnswers.length - 1];

        let answer = CurrentAnswer.none;

        if (last.correct && last.direction === -1) {
            answer = CurrentAnswer.topCorrect
            dispatch(addPointsForScreen(currentPoints, index))
        }
        else if (last.correct && last.direction === 1) {
            answer = CurrentAnswer.bottomCorrect
            dispatch(addPointsForScreen(currentPoints, index))
        }
        else if (!last.correct && last.direction === -1) {
            answer = CurrentAnswer.topWrong;
            dispatch(addPointsForScreen(0, index));
        }
        else if (!last.correct && last.direction === 1) {
            answer = CurrentAnswer.bottomWrong;
            dispatch(addPointsForScreen(0, index));
        }


        setCurrentAnswer(answer);

        const id = setTimeout(() => {
            setCurrentAnswer(CurrentAnswer.none);
        }, 500)

        return () => clearTimeout(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedAnswers])

    return <div className="screenContentContainer swipeQuizScreen">
        {!!title && <h2><Text>{title}</Text></h2>}
        {!!text && <h4><Text>{text}</Text></h4>}
        <div className="swipeQuizGame">
            <div className="swipeQuizControls">
                <div className="arrowContainer">
                    <img src={arrowUpImage} className="arrow" alt="" />
                    <img src={arrowUpGlowImage} hidden={arrowUpGlowImage === null} className="arrowGlow arrowGlow--up" alt="" />

                    <div className="topContent">
                        <div className="text">
                            <Text>{top_text}</Text>
                        </div>
                    </div>
                </div>
                <div className="arrowContainer">
                    <img src={arrowDownImage} className="arrow" alt="" />
                    <img src={arrowDownGlowImage} hidden={arrowDownGlowImage === null} className="arrowGlow arrowGlow--down" alt="" />

                    <div className="bottomContent">
                        <div className="text">
                            <Text>{bottom_text}</Text>
                        </div>
                    </div>
                </div>
            </div>
            {props.map(({ x, y, scale }, i) => (
                <animated.div key={i} style={{ left: x, top: y }} className="animatable">
                    <animated.div {...bind(i)} className="card" style={{
                        transform: interpolate([scale], transform),
                        backgroundImage: `url(${cards[i]})`,
                        borderColor: `hsl(200, 50%, ${lowerBoundary + step * (i + goneCount + 1)}%`
                    }} />
                </animated.div>
            ))}
        </div>

    </div>
};