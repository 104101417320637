import React from 'react';

import CompleteWordScreenContent from './CompleteWordScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

const CompleteWordScreen = ({active, index, data}) => <WithTimerScreen
    active={active}
    index={index}
    data={data}
    gameContent={endGame => (
        <CompleteWordScreenContent
            active={active}
            data={data}
            endGame={endGame}
            index={index}
        />
    )}
    timeOutContent={
        <CompleteWordScreenContent
            active={active}
            data={data}
            isEnd={true}
            index={index}
        />
    }
    winContent={
        <CompleteWordScreenContent
            active={active}
            data={data}
            isEnd={true}
            index={index}
        />}
/>

export default CompleteWordScreen;
