import React from 'react';
import "./Text.scss";

function Text({ html = true, children = "" }) {
    let string = children;
    if (Array.isArray(children)) {
        string = children.join("");
    }
    const content = string.toString().replace(/(\s)([\S])[\s]+/g, "$1$2&nbsp;");
    let component;

    if (html) {
        component = <span className="screenText" dangerouslySetInnerHTML={{ __html: content }} />
    }
    else {
        component = <span className="screenText" >{content}</span>
    }
    return component;
}

export default Text
