import React from 'react'
import { useSelector } from "react-redux";

import { commonImages } from "../../../config/images";
import { getGlobalConfig } from '../../../store/reducers/mainReducer';
import Text from '../../TextContainer/Text';

function RotationOverlay({ hidden }) {
    const globalConfig = useSelector(getGlobalConfig) || {};
    const { rotation_overlay_text = "Proszę obrócić ekran" } = globalConfig;
    const { elementPhoneRotate } = commonImages;

    return (
        <div className="rotationOverlay" style={{ display: hidden ? "none" : "flex" }}>
            <h3><Text>{rotation_overlay_text}</Text></h3>
            <img src={elementPhoneRotate} alt="" />
        </div>
    )
}

export default RotationOverlay;
