import React from 'react';

import '../../config/globalStyles.scss';

import WhaleGameScreen from '../CanvasGamesScreens/WhaleGameScreen/WhaleGameScreen';
import ScrollGameScreen from '../CanvasGamesScreens/ScrollGameScreen/ScrollGameScreen';
import PuzzleScreen from '../PuzzleScreen/PuzzleScreen';
import ArcherfishGameScreen from '../CanvasGamesScreens/ArcherfishGameScreen/ArcherfishGameScreen';
import LabyrinthGameScreen from "../CanvasGamesScreens/LabyrinthGameScreen/LabirynthGameScreen";
import { select } from '../../config/helpers';
import Text from '../TextContainer/Text';

const GamesScreen = ({active, data, index}) => {
    const { gameScreen } = data;
    const { type } = gameScreen;

    const content = select(type, {
        whale_game: <WhaleGameScreen active={active} data={data}/>,
        scroll_game: <ScrollGameScreen active={active} data={data} index={index}/>,
        puzzle_game: <PuzzleScreen active={active} data={data} index={index} />,
        archerfish_game: <ArcherfishGameScreen active={active} data={data} />,
        labyrinth_game: <LabyrinthGameScreen active={active} data={data} index={index}/>,
        default: <p><Text>Error. Info screen type is not correct (infoScreen.type:{type}).</Text></p>
    });

    return content;
};

export default GamesScreen;
