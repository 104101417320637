import React from 'react'

import InfoScreen from '../../InfoScreen/InfoScreen.js';
import NavigationScreen from '../../NavigationScreen/NavigationScreen';
import CompleteWordScreen from '../../CompleteWordScreen/CompleteWordScreen';
import QuizScreen from '../../QuizScreen/QuizScreen.js';
import GamesScreen from '../../GamesScreen/GamesScreen.js';
import WinScreen from '../../WinLoseScreens/WinScreen.js';
import LoseScreen from '../../WinLoseScreens/LoseScreen.js';
import StartTimeScreen from '../../StartTimeScreen/StartTimeScreen';
import PointsSummaryScreen from '../../PointsSummaryScreen/PointsSummaryScreen';
import AnimalProfileScreen from '../../AnimalProfileScreen/AnimalProfileScreen';
import WithNewsletterEndScreen from '../../WithNewsletterEndScreen/WithNewsletterEndScreen';
import WithInstitutionsListScreen from '../../WithInstitutionsListScreen/WithInstitutionsListScreen';
import SliderQuizScreen from '../../SliderQuizScreen/SliderQuizScreen';
import PairQuizScreen from '../../PairQuizScreen/PairQuizScreen';
import SwipeQuizScreen from '../../SwipeQuizScreen/SwipeQuizScreen';
import CuriosityScreen from '../../CuriosityScreen/CuriosityScreen.js';
import { select } from '../../../config/helpers.js';
import Text from '../../TextContainer/Text.js';
import CrocodileQuizScreen from '../../CrocodileQuizScreen/CrocodileQuizScreen.js';
import TutorialScreen from "../../TutorialScreen/TutorialScreen";

function Content({ active, index, data, timeStartPopup, curiosityPopup, winLosePopup }) {
    const { screen_type } = data;
    let content = null;

    if (curiosityPopup) {
        content = <CuriosityScreen data={data} />
    }
    else if (timeStartPopup) {
        content = <StartTimeScreen data={data} />
    }
    else if (!winLosePopup) {
        const {swipeQuizScreen = {}} = data;
        const { type = "default" } = swipeQuizScreen;

        content = select(screen_type, {
            info: <InfoScreen active={active} index={index} data={data} />,
            navigation: <NavigationScreen active={active} index={index} data={data} />,
            quiz: <QuizScreen active={active} index={index} data={data} />,
            complete_word: <CompleteWordScreen active={active} index={index} data={data} />,
            slide_quiz: <SliderQuizScreen active={active} index={index} data={data} />,
            swipe_quiz: type === "with_crocodile" ?
                <CrocodileQuizScreen active={active} index={index} data={data} /> :
                <SwipeQuizScreen active={active} index={index} data={data}/>,
            pair_quiz: <PairQuizScreen active={active} index={index} data={data} />,
            game: <GamesScreen active={active} index={index} data={data} />,
            points_summary: <PointsSummaryScreen active={active} index={index} data={data} />,
            animal_profile: <AnimalProfileScreen active={active} index={index} data={data} />,
            with_newsletter_end: <WithNewsletterEndScreen active={active} index={index} data={data} />,
            with_institutions_list: <WithInstitutionsListScreen active={active} index={index} data={data} />,
            tutorial: <TutorialScreen active={active} index={index} data={data} />,
            default: <p><Text>Default screen</Text></p>,
        });
    }
    else {
        content = select(winLosePopup, {
            win: <WinScreen active={active} />,
            lose: <LoseScreen active={active} />,
            default: <p><Text>Default screen</Text></p>,
        })
    }

    return <div className="screenContent">{content}</div>
}

export default Content
