import React from 'react';

import './CustomCheckbox.scss';

const CustomCheckbox = ({ isChecked }) => {
  return (
    <div
      className={
        isChecked ? 'customCheckbox customCheckbox--checked' : 'customCheckbox'
      }
    />
  );
};

export default CustomCheckbox;
