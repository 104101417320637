import React from 'react';
import { useDispatch } from "react-redux";
import { commonImages } from '../../../config/images';
import { setCuriosityPopup } from '../../../store/actions/mainActions';
import '../main.scss';
import audio from '../../../config/audio';

const CuriosityButton = () => {
    const dispatch = useDispatch();

    return (
        <div
            className="sectionElement sectionElement--left-bottom"
            style={{
                backgroundImage: `url(${commonImages.backgroundLeftBottomCorner})`,
            }}>
            <img
                src={commonImages.iconCuriosity}
                alt="Curiosity"
                className="sectionElement__image sectionElement__image--left-bottom sectionElement__image--curiosity"
                onClick={() => {
                    audio.play("click");
                    dispatch(setCuriosityPopup(true))
                }}
            />
        </div>
    );
};

export default CuriosityButton;
