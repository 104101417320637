import React from 'react';

import QuizScreenContent from './QuizScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

const QuizScreen = ({active, index, data}) => <WithTimerScreen
    active={active}
    index={index}
    data={data}
    gameContent={endGame => (
        <QuizScreenContent
            active={active}
            data={data}
            endGame={endGame}
        />
    )}
    timeOutContent={
        <QuizScreenContent
            active={active}
            data={data}
            isEnd={true}
        />
    }
    winContent={
        <QuizScreenContent
            active={active}
            data={data}
            isEnd={true}
        />}
/>

export default QuizScreen;
