import React, { useRef, useEffect, useState } from 'react'
import { useGesture } from "react-use-gesture";
import { useSprings, a } from 'react-spring'

import Ring from './Ring'

const lock = (value, min, max) => Math.max(Math.min(value, max), min)

function ChoiceBar({ icons, selected, setSelected }) {
    const offset = useRef(selected);
    const distance = window.innerWidth / 5;

    const [refresh, forceRefresh] = useState({});

    const [props, set] = useSprings(icons.length, i => ({
        x: window.innerWidth / 2 + distance * i,
        scale: 1
    }));
    const bind = useGesture({
        onDrag(state) {
            const x = state.delta[0] - distance * selected;

            offset.current = x;

            set(i => {
                if (state.down) {
                    const x = window.innerWidth / 2 + distance * i + offset.current
                    return {
                        x,
                        immediate: true
                    }
                } else {
                    setSelected(-lock(Math.round(offset.current / distance), -icons.length + 1, 0));
                    forceRefresh({});
                }
            })
        }
    })

    useEffect(() => {
        set(i => {
            const x = window.innerWidth / 2 + (i - selected) * distance;
            offset.current = (-selected) * distance
            return {
                x,
                immediate: true
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, refresh])

    return (
        <div className="facefilterChoiceBar" {...bind()} >
            {props.map(({ x }, i) => (
                <a.div key={i} onClick={e => {
                    setSelected(i);
                }} className="choice" style={{ left: x }}>
                    <img src={icons[i]} style={{ width: "100%", height: "100%" }} alt="choice" />
                </a.div>
            ))}
            <Ring />
        </div >
    )
}

export default ChoiceBar
