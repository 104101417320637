import React, { useEffect, useState, useReducer } from 'react';

import '../../config/globalStyles.scss';
import './CompleteWordScreen.scss';
//import Text from '../TextContainer/Text';
import Gap from './Gap';
import { useDispatch } from 'react-redux';
import { addPointsForScreen } from '../../store/actions';
import Text from '../TextContainer/Text';
import { getImagePath } from '../../config/helpers';

const replace = (string, replacement, position) => string.substr(0, position) + replacement + string.substr(position + replacement.length);

function reducer(state, action) {
    const { letter, position } = action.payload || {};

    return replace(state, letter, position);
}

const CompleteWordScreenContent = ({
    active,
    index,
    data,
    endGame,
    isEnd = false
}) => {
    const dispatch = useDispatch();
    // eslint-disable-next-line no-unused-vars
    let { title, text, completed_word = "", start_word = "", points, graphicPath } = data.completeWordScreen;
    completed_word = completed_word.toUpperCase().replace(/<[^>]*>?/gm, '');
    start_word = start_word.toUpperCase().replace(/<[^>]*>?/gm, '');

    const [differences, setDifferences] = useReducer(reducer, start_word);
    const [selected, setSelected] = useState({ value: null });
    const [visible, setVisible] = useState(false);
    const [finished, setFinished] = useState(false);

    const blinkingTimes = 6;
    const [, setBlinked] = useState(0);

    const setNext = () => {
        setSelected(({ value }) => {
            do {
                value++;

                if (start_word[value] === undefined) {
                    return { value: null };
                }
            } while (start_word[value] !== "_");
            return { value };
        });
    }

    const blinking = () => {
        let current = 0;

        
        setVisible(visible => !visible);
        setBlinked(blinked => current = blinked + 1);

        if(current <= blinkingTimes) {
            setTimeout(blinking, 600);
        } else {
            setFinished(true);
        }
    }

    useEffect(() => {
        if (!differences.includes("_") && !isEnd) {
            endGame();
        }
    }, [differences, endGame, isEnd])

    useEffect(() => {
        if (isEnd) {
            if (differences === completed_word) {
                dispatch(addPointsForScreen(points, index));
            }
            setSelected({ value: null });
            
            setTimeout(blinking, 600)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnd])


    return (
        <div className="screenContentContainer completeWordScreen">
            {!!title && <h2><Text>{title}</Text></h2>}
            {!!text && <h4><Text>{text}</Text></h4>}
            {graphicPath ? (
                <img className="completeWordImage" src={getImagePath(graphicPath)} alt="" />
            ) : null}
            <div className="completeWordContent">
                {start_word.split("").map((el, it) => el !== " " ? <Gap
                    key={it}
                    correct={completed_word[it]}
                    id={it}
                    initial={el}
                    isFinishing={isEnd}
                    isFinished={finished}
                    isVisible={visible}
                    isSelected={it === selected.value}
                    current={differences[it]}
                    setNext={setNext}
                    setDifferences={setDifferences}
                    changeSelected={setSelected}
                /> : <div className="completeWordBreak" />)}
            </div>
        </div>
    );
};

export default CompleteWordScreenContent;
