import React from 'react';

import NavigationScreenContent from './NavigationScreenContent';
import WithTimerScreen from '../WithTimerScreen/WithTimerScreen';

const NavigationScreen = ({active, data}) => <WithTimerScreen
    active={active}
    data={data}
    canSwipe={true}
    gameContent={endHandler => <NavigationScreenContent active={active} endGame={endHandler} data={data}/>}
/>


export default NavigationScreen;
