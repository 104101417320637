import React, { useState } from 'react';
import '../../../config/globalStyles.scss';
import './startScreen.scss';
import { commonImages } from "../../../config/images";
import { getImagePath } from "../../../config/helpers";
import PlayButton from '../elements/PlayButton';
import Text from '../../TextContainer/Text';
import ChangeLanguagePopup from "../elements/ChangeLanguagePopup";
import { useSelector } from 'react-redux';
import { getChangeLanguagePopup } from '../../../store/reducers/mainReducer';
import HeadphonesPopup from '../elements/HeadphonesPopup';

const StartScreen = ({ data }) => {
    const changeLanguagePopup = useSelector(getChangeLanguagePopup);

    const { infoScreen, screenLogotypesData: logotypes = [] } = data;
    const { title, text_1 } = infoScreen;

    const [headphonesPopupVisible, setHeadphonesPopupVisible] = useState(true);

    return (
        <div className="screenContentContainer startScreen">
            <div className="logotypesContainer">
                {logotypes.map(logotype => <img
                    src={getImagePath(logotype.graphicPath)}
                    alt={logotype.name}
                    className="logotype"
                    key={logotype.name}
                />
                )}
            </div>
            <div className="startScreenTexts">
                <h1 className="startScreenTitle"><Text>{title}</Text></h1>
                <h4 className="startScreenHeader"><Text>{text_1}</Text></h4>
            </div>
            <div className="startButton">
                <PlayButton />
            </div>
            <img src={commonImages.elementHorseWinner} alt="" className="seahorse" />
            {changeLanguagePopup && <ChangeLanguagePopup />}
            {headphonesPopupVisible && <HeadphonesPopup setVisible={setHeadphonesPopupVisible} />}
        </div>
    );
};

export default StartScreen;
