import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from "react-transition-group";

import '../../config/globalStyles.scss';
import './main.scss';
import {
    getScreensData,
    setSwipeAvailable,
    setPause,
    setOfflinePopup
} from '../../store/actions/mainActions';
import { getViewportSize } from '../../config/helpers';
import {
    selectCurrentScreenData,
    getNumberOfScreens,
    getWinLosePopup,
    getCuriosityPopup,
    getStartTimePopup,
    getSwipeAvailable,
    getCurrentScreenIndex,
    selectTotalEarnedPoints,
    selectNextScreenData,
    getMapPopup,
    getOfflinePopup,
    getLanguage
} from '../../store/reducers/mainReducer';

import SwipeContainer from './elements/SwipeContainer';
import RotationOverlay from './elements/RotationOverlay';
import Screen from './Screen/Screen';
import IdleOverlay from './elements/IdleOverlay';
import audio from '../../config/audio';
import MapPopup from "./elements/MapPopup";
import o9n from "o9n";
import OfflineOverlay from './elements/OfflineOverlay';

const MainContainer = () => {
    const dispatch = useDispatch();

    const currentScreenIndex = useSelector(getCurrentScreenIndex);
    const currentScreenData = useSelector(selectCurrentScreenData);
    const nextScreenData = useSelector(selectNextScreenData);

    const [screensData, setScreensData] = useState([currentScreenData||{}, {}]);

    const numberOfScreens = useSelector(getNumberOfScreens);
    const winLosePopup = useSelector(getWinLosePopup);
    const curiosityPopup = useSelector(getCuriosityPopup);
    const timeStartPopup = useSelector(getStartTimePopup);
    const offlinePopup = useSelector(getOfflinePopup);
    const swipeAvailable = useSelector(getSwipeAvailable);
    const mapPopup = useSelector(getMapPopup)
    const initialLanguage = useSelector(getLanguage);

    const totalEarnedPoints = useSelector(selectTotalEarnedPoints);
    const [portrait, setPortrait] = useState(window.self !== window.top || o9n.orientation.type.includes("portrait"));
    const [videoFullscreen] = useState(false);

    useEffect(() => {
        window.onblur = () => {
            dispatch(setPause(true));
        }
        window.onfocus = () => {
            if(!mapPopup && !curiosityPopup) {
                dispatch(setPause(false));
            }
        }
    },[mapPopup, curiosityPopup, dispatch])

    useEffect(() => {
        window.ononline = () => {
            dispatch(setOfflinePopup(false))
        };
        window.onoffline = () => {
            dispatch(setOfflinePopup(true))
        };
    }, [dispatch]);

    useEffect(() => {
        if (mapPopup || curiosityPopup) {
            dispatch(setPause(true))
        } else {
            dispatch(setPause(false))
        }
    }, [mapPopup, curiosityPopup, dispatch])

    useEffect(() => {
        const id = setTimeout(() => {
            if (screensData[0]&&Object.entries(screensData[0]).length > 0) {
                setScreensData(!!(currentScreenIndex % 2) ? [currentScreenData, nextScreenData] : [nextScreenData, currentScreenData]);
            }
        }, 400)

        return () => clearTimeout(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreenIndex])

    useEffect(() => {
        setScreensData(!!(currentScreenIndex % 2) ? [currentScreenData, nextScreenData] : [nextScreenData, currentScreenData]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreenData])

    useEffect(() => {
        if (currentScreenData &&
            nextScreenData &&
            Object.entries(currentScreenData).length > 0 &&
            Object.entries(screensData[0]).length === 0) {
            setScreensData([currentScreenData, nextScreenData]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreenData]);

    useEffect(() => {
        if (currentScreenIndex === numberOfScreens - 1) {
            dispatch(setSwipeAvailable(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScreenIndex, numberOfScreens])


    useEffect(() => {
        const { orientation } = o9n;

        orientation.lock("portrait").catch(e => console.log(e));
        orientation.addEventListener("change", e => {
            if (window.top === window.self)
                setPortrait(orientation.type.includes("portrait"));
            else {
                setPortrait(true);
            }
        })

        const resizeEvent = e => {
            const { height } = getViewportSize();
            document.documentElement.style.setProperty('--vh', `${height / 100}px`);
        };
        dispatch(getScreensData(initialLanguage));

        resizeEvent();
        window.addEventListener('resize', resizeEvent);
        return () => window.removeEventListener("resize", resizeEvent);
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (winLosePopup === "win") {
            audio.play("win");
        }
        else if (winLosePopup === "lose") {
            audio.play("loss");
        }
    }, [currentScreenIndex, winLosePopup])

    return currentScreenData && currentScreenData.constructor === Object && Object.entries(currentScreenData).length > 0 ? (
        <>
            <RotationOverlay hidden={portrait || videoFullscreen} />

            <IdleOverlay hidden={curiosityPopup || mapPopup} timeout={8000} />

            <CSSTransition in={!!(currentScreenIndex % 2) && !curiosityPopup && !winLosePopup && !timeStartPopup && !mapPopup && !offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <Screen active={!!(currentScreenIndex % 2) && !timeStartPopup} index={currentScreenIndex} points={totalEarnedPoints} data={screensData[0]} timeStartPopup={false} curiosityPopup={false} winLosePopup={false} />
            </CSSTransition>

            <CSSTransition in={!!((currentScreenIndex + 1) % 2) && !curiosityPopup && !winLosePopup && !timeStartPopup && !mapPopup && !offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <Screen active={!!((currentScreenIndex + 1) % 2) && !timeStartPopup} index={currentScreenIndex} points={totalEarnedPoints} data={screensData[1]} timeStartPopup={false} curiosityPopup={false} winLosePopup={false} />
            </CSSTransition>

            <CSSTransition in={timeStartPopup && !curiosityPopup && !mapPopup && !offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <Screen active={timeStartPopup} index={currentScreenIndex} points={totalEarnedPoints} data={screensData[(currentScreenIndex + 1) % 2]} timeStartPopup={true} curiosityPopup={false} winLosePopup={false} />
            </CSSTransition>

            <CSSTransition in={curiosityPopup && !offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <Screen active={curiosityPopup} index={currentScreenIndex} points={totalEarnedPoints} data={screensData[(currentScreenIndex + 1) % 2]} timeStartPopup={false} curiosityPopup={true} winLosePopup={false} />
            </CSSTransition>

            <CSSTransition in={winLosePopup === "win" && !curiosityPopup && !offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <Screen active={winLosePopup === "win"} index={currentScreenIndex} points={totalEarnedPoints} data={screensData[(currentScreenIndex + 1) % 2]} timeStartPopup={false} curiosityPopup={false} winLosePopup={"win"} />
            </CSSTransition>

            <CSSTransition in={winLosePopup === "lose" && !curiosityPopup && !offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <Screen active={winLosePopup === "lose"} index={currentScreenIndex} points={totalEarnedPoints} data={screensData[(currentScreenIndex + 1) % 2]} timeStartPopup={false} curiosityPopup={false} winLosePopup={"lose"} />
            </CSSTransition>

            <CSSTransition in={offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <OfflineOverlay/>
            </CSSTransition>

            <CSSTransition in={mapPopup && !offlinePopup} timeout={200} appear={true} classNames="current-screen">
                <MapPopup key={"map_popup" + currentScreenIndex} />
            </CSSTransition>

            <SwipeContainer enabled={(swipeAvailable || timeStartPopup || winLosePopup || currentScreenData.completed ) && !curiosityPopup} />
        </>
    ) : null;
}

export default MainContainer;
