import React from 'react';

import CanvasEnvironment from '../commonElements/CanvasEnvironment';
import WithTimerScreen from "../../WithTimerScreen/WithTimerScreen";
import ArcherfishGameEngine from './ArcherfishGameEngine';

const ArcherfishGameScreen = ({ active, data }) => <WithTimerScreen
    active={active}
    data={data}
    gameContent={endGame => <CanvasEnvironment
        active={active}
        data={data}
        Engine={ArcherfishGameEngine}
        canvasWidthModifier={1}
        canvasHeightModifier={1}
        endGame={endGame}
        isTimeout={false}
    />
    }
    timeOutContent={<CanvasEnvironment
        active={active}
        data={data}
        Engine={ArcherfishGameEngine}
        canvasWidthModifier={1}
        canvasHeightModifier={1}
        isTimeout={true}
    />}

    winContent={<CanvasEnvironment
        active={active}
        data={data}
        Engine={ArcherfishGameEngine}
        canvasWidthModifier={1}
        canvasHeightModifier={1}
        isTimeout={true}
    />}
/>



export default ArcherfishGameScreen;
