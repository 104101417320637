// import { store } from '../store';
import API, { mainServerPath } from '../../config/API';

import {
    REQUEST_GET_SCREENS_DATA,
    RESPONSE_GET_SCREENS_DATA,
    GO_TO_NEXT_SCREEN,
    ADD_POINTS_FOR_SCREEN,
    SET_TIMER_STATUS_FOR_SCREEN,
    SET_COMPLETED_STATUS_FOR_SCREEN,
    SET_SCORE_FOR_SCREEN,
    SET_WIN_LOSE_POPUP,
    SET_CURIOSITY_POPUP,
    SET_START_TIME_POPUP,
    SET_SWIPE_AVAILABLE,
    SET_CHANGE_LANGUAGE_POPUP,
    SET_CHANGE_MAP_POPUP,
    SET_LANGUAGE,
    SET_PAUSE,
    RESET_STORE,
    SET_OFFLINE_POPUP
} from './actionTypes';
import { requestFullScreen } from '../../config/helpers';
import audio from "../../config/audio";

/**
 |--------------------------------------------------
 | GET SCREENS DATA
 |--------------------------------------------------
 */

export const getScreensData = (language = "pl-PL") => (dispatch, getState) => {
    dispatch(requestGetScreensData());
    dispatch(setLanguage(language));

    function startGame(data) {
        const currentState = getState().main;
        let resetScreensData = false;
        audio.initialize(data.sounds);
        if (currentState.currentScreenIndex === 1) {
            resetScreensData = true;
        }
        dispatch(responseGetScreensData(data, resetScreensData));
        if (currentState.currentScreenIndex === 0) {
            dispatch(changeScreen);
        }
    };

    // api call
    let data = API.testScreenData;

    const search = window.location.search;
    const params = new URLSearchParams(search);
    if(!params.has("lang")) {
        params.set("lang", language);
    } else {
        dispatch(setLanguage(params.get("lang")));
    }

    if(params.has('screenId')){
        dispatch(resetStore());
    }

    //const base64QueryData = params.get('data');
    //if(base64QueryData){
    //  const queryData = [JSON.parse(Base64.decode(base64QueryData))];
    //  if(typeof(queryData)==="object"){
    //    //uncomment this for assign data from base64_encode "data" query param
    //    data = queryData;
    //  }
    //}

    fetch(`${mainServerPath}/api/v1/screen?${params.toString()}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            }
        }
    )
        .then(res => res.json())
        .then(data2 => {
            data = data2;
            // set state for success
            startGame(data);
        })
        .catch(error => {
            // set state for error
            startGame(data);
        })

};

const requestGetScreensData = () => ({ type: REQUEST_GET_SCREENS_DATA });

const responseGetScreensData = (data, resetScreensData) => ({
    type: RESPONSE_GET_SCREENS_DATA,
    payload: {
        screensData: data.screensData,
        winPopupData: data.winPopup,
        losePopupData: data.losePopup,
        availableLanguages: data.languages,
        globalConfig: data.globalConfig,
        mapData:data.map,
        resetScreensData: resetScreensData
    }
});

/**
 |--------------------------------------------------
 | GO TO NEXT SCREEN
 |--------------------------------------------------
 */

export const changeScreen = (dispatch, getState) => {
    const { startTimePopup, winLosePopup, currentScreenIndex, screensData } = getState().main;
    const { earnedPoints, availablePoints } = screensData[currentScreenIndex];
    const nextScreenData = screensData[currentScreenIndex + 1];
    const { limit_time } = nextScreenData || {};

    // With timer screen
    if (startTimePopup) {
        dispatch({
            type: SET_START_TIME_POPUP,
            payload: { startTimePopup: false }
        })
        dispatch(setTimerStatus('started', currentScreenIndex));

        // Content screen
    } else if (!winLosePopup) {
        if (earnedPoints > 0) {
            dispatch({
                type: SET_WIN_LOSE_POPUP,
                payload: { winLosePopup: "win" }
            })
        } else if (earnedPoints === 0 && availablePoints > 0) {
            dispatch({
                type: SET_WIN_LOSE_POPUP,
                payload: { winLosePopup: "lose" }
            })
        } else {
            requestFullScreen();

            if (limit_time > 0) {
                dispatch({
                    type: SET_START_TIME_POPUP,
                    payload: { startTimePopup: true }
                })
            }
            dispatch({ type: GO_TO_NEXT_SCREEN })
        }
        // Win or lose screen
    } else {
        requestFullScreen();

        dispatch({
            type: SET_WIN_LOSE_POPUP,
            payload: { winLosePopup: null }
        })
        if (limit_time > 0) {
            dispatch({
                type: SET_START_TIME_POPUP,
                payload: { startTimePopup: true }
            })
        }
        dispatch({ type: GO_TO_NEXT_SCREEN })
    }
}

/**
 |--------------------------------------------------
 | ADD POINTS FOR SCREEN
 |--------------------------------------------------
 */

export const addPointsForScreen = (points, currentScreenIndex) => ({
    type: ADD_POINTS_FOR_SCREEN,
    payload: { earnedPoints: points, currentScreenIndex },
});
/**
 |--------------------------------------------------
 | SET TIMER STATUS FOR SCREEN
 |--------------------------------------------------
 */

export const setTimerStatus = (status, currentScreenIndex) => ({
    type: SET_TIMER_STATUS_FOR_SCREEN,
    payload: { status: status, currentScreenIndex },
});
/**
 |--------------------------------------------------
 | SET TIMER COMPLETED FOR SCREEN
 |--------------------------------------------------
 */

export const setCompletedStatus = (status, currentScreenIndex) => ({
    type: SET_COMPLETED_STATUS_FOR_SCREEN,
    payload: { completed: status, currentScreenIndex },
});

/**
 |--------------------------------------------------
 | SET SCORE FOR SCREEN
 |--------------------------------------------------
 */

export const setScoreForScreen = (score, currentScreenIndex) => ({
    type: SET_SCORE_FOR_SCREEN,
    payload: { score, currentScreenIndex },
});

/**
 |--------------------------------------------------
 | SET CURIOSITY POPUP
 |--------------------------------------------------
 */

export const setCuriosityPopup = curiosityPopup => ({
    type: SET_CURIOSITY_POPUP,
    payload: { curiosityPopup },
});

/**
 |--------------------------------------------------
 | SET TIME START POPUP
 |--------------------------------------------------
 */

export const setStartTimePopup = startTimePopup => ({
    type: SET_START_TIME_POPUP,
    payload: { startTimePopup },
});

/**
 |--------------------------------------------------
 | SET CHANGE LANGUAGE POPUP
 |--------------------------------------------------
 */

export const setChangeLanguagePopup = changeLanguagePopup => ({
    type: SET_CHANGE_LANGUAGE_POPUP,
    payload: { changeLanguagePopup },
});

/**
 |--------------------------------------------------
 | SET CHANGE MAP POPUP
 |--------------------------------------------------
 */

export const setMapPopup = mapPopup => ({
    type: SET_CHANGE_MAP_POPUP,
    payload: { mapPopup },
});

/**
 |--------------------------------------------------
 | SET PAUSE
 |--------------------------------------------------
 */

export const setOfflinePopup = offlinePopup =>  ({
    type: SET_OFFLINE_POPUP,
    payload: { offlinePopup },
});

/**
 |--------------------------------------------------
 | SET SWIPE AVAILABLE
 |--------------------------------------------------
 */

export const setSwipeAvailable = swipeAvailable => ({
    type: SET_SWIPE_AVAILABLE,
    payload: { swipeAvailable },
});

/**
 |--------------------------------------------------
 | SET LANGUAGE
 |--------------------------------------------------
 */

export const setLanguage = language => ({
    type: SET_LANGUAGE,
    payload: { language },
});

/**
 |--------------------------------------------------
 | SET PAUSE
 |--------------------------------------------------
 */

export const setPause = pause => ({
    type: SET_PAUSE,
    payload: { pause },
});

export const resetStore = () => ({
    type: RESET_STORE,
});
